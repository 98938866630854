<div class="root">
  <div class="sticky">
    <app-header-template
      translationKey="DeviceList.DeviceList"
      [showBackButton]="false"
    >
      <div data-cy="new-device-section" class="buttons">
        <button
          data-cy="btn-create-vhpc"
          mat-flat-button
          class="create-simulated-btn"
          [attr.aria-label]="'DeviceList.CreateVhpc' | translate"
          (click)="openCreateDeviceDialog(DeviceType.VHPC)"
        >
          {{ "DeviceList.CreateVHPC" | translate }}
        </button>
        <button
          data-cy="btn-create-simulated"
          mat-flat-button
          class="create-simulated-btn"
          [attr.aria-label]="'DeviceList.CreateSim' | translate"
          (click)="openCreateDeviceDialog(DeviceType.SIMULATED)"
        >
          {{ "DeviceList.CreateSim" | translate }}</button
        ><button
          data-cy="btn-create-real"
          mat-flat-button
          color="primary"
          class="header-btn"
          [attr.aria-label]="'DeviceList.RequestReal' | translate"
          (click)="openCreateDeviceDialog(DeviceType.REAL)"
        >
          {{ "DeviceList.RequestReal" | translate }}
        </button>
      </div>
    </app-header-template>
    <app-function-bar
      [searchPlaceholder]="'DeviceList.Search'"
      [searchFilterControl]="searchFilterControl"
      (resetFilters)="handleResetFilters()"
      [showFiltersBtn]="checkActiveFilters()"
    >
      <div class="actions-container" filters>
        <div class="select-container">
          <label for="device-type-select"
            >{{ "DeviceList.FilterOptions.DeviceType" | translate }}:</label
          >
          <app-select-menu
            data-cy="device-type-menu"
            id="device-type-select"
            *ngIf="deviceTypeData$ | async as deviceTypeData"
            [options]="deviceTypeData.options"
            [selectedOption]="deviceTypeData.selectedOption"
            (optionSelected)="selectDeviceTypeOption($event)"
          ></app-select-menu>
        </div>
        <div class="select-container">
          <label for="env-select"
            >{{ "DeviceList.FilterOptions.Environment" | translate }}:</label
          >
          <app-select-menu
            id="env-select"
            data-cy="environment-menu"
            *ngIf="deviceEnvData$ | async as deviceEnvData"
            [options]="deviceEnvData.options"
            [selectedOption]="deviceEnvData.selectedOption"
            (optionSelected)="selectDeviceEnvironmentOption($event)"
          ></app-select-menu>
        </div>
        <div class="select-container">
          <label for="connection-status-select"
            >{{
              "DeviceList.FilterOptions.ConnectionStatus" | translate
            }}:</label
          >
          <app-select-menu
            data-cy="connection-status-menu"
            id="connection-status-select"
            *ngIf="
              deviceConnectionStatusData$ | async as deviceConnectionStatusData
            "
            [options]="deviceConnectionStatusData.options"
            [selectedOption]="deviceConnectionStatusData.selectedOption"
            (optionSelected)="selectDeviceConnectionStatusOption($event)"
          ></app-select-menu>
        </div>
        <div class="select-container">
          <label for="sort-select">{{ "General.SortBy" | translate }}</label>
          <app-select-menu
            data-cy="sort-by-menu"
            id="sort-select"
            *ngIf="sortByData$ | async as sortByData"
            [options]="sortByData.options"
            [selectedOption]="sortByData.selectedOption"
            (optionSelected)="selectSortOption($event)"
          ></app-select-menu>
        </div>
      </div>
      <div others></div>
    </app-function-bar>
  </div>
  <ng-container *ngIf="devicesData$ | async as devicesData">
    <app-content-wrapper
      data-cy="device-list-container"
      [isError]="devicesData.hasError"
      [isLoading]="devicesData.isLoading"
      [errorMessage]="'DeviceList.DeviceListError' | translate"
    >
      <app-device-list-content
        [devices]="devicesData.devices"
      ></app-device-list-content>
    </app-content-wrapper>
  </ng-container>
</div>
