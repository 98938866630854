import { Injectable } from '@angular/core';
import { Option } from 'src/app/core/models/interfaces/option';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SORT_KEYS, SortKey } from 'src/app/shared/utils/sortKeys';
import { Project } from 'src/app/shared/stores/projects/models/project';
import { AddProjectDialogComponent } from '../../components/add-project-dialog/add-project-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class HomeService {

  defaultFilterValues: Option<SortKey> = {
    id: SORT_KEYS[0],
    label: this.translate.instant(SORT_KEYS[0]),
  };

  selectedSortBySource: BehaviorSubject<Option<SortKey>> = new BehaviorSubject<
    Option<SortKey>
  >(this.defaultFilterValues);
  constructor(private translate: TranslateService, public dialog: MatDialog) {}

  projectMap = ([projects, searchFilter, sortByOption]: [
    Project[],
    string,
    Option<SortKey>
  ]) => {
    return projects
      .filter((project) => this.projectFilter(project, searchFilter))
      .sort(this.projectSort(sortByOption.id));
  };

  projectFilter = (project: Project, searchFilter: string) => {
    return (
      project.label.toLowerCase().includes(searchFilter.toLowerCase()) ||
      project.description.toLowerCase().includes(searchFilter.toLowerCase())
    );
  };

  projectSort = (sortBy: SortKey): ((a: Project, b: Project) => number) => {
    switch (sortBy) {
      case 'SortKeys.Alphabetical':
        return (a: Project, b: Project) => a.label.localeCompare(b.label, 'en');
      case 'SortKeys.Date':
        return (a: Project, b: Project) => a.createdDate - b.createdDate;
    }
  };

  get sortByOptions$(): Observable<Option<SortKey>[]> {
    return this.translate.stream([...SORT_KEYS]).pipe(
      map((translations: Record<SortKey, string>) =>
        this.mapTranslations(translations)
      ),
      tap((options) => {
        const selectedSortByOption = this.selectedSortBySource.value;
        this.setSelectedSortBy(
          options.find((option) => option.id === selectedSortByOption.id)!
        );
      })
    );
  }

  mapTranslations = (translations: Record<SortKey, string>) => {
    return Object.entries(translations).map(
      ([sortKey, translation]: [string, string]): Option<SortKey> => ({
        id: sortKey as SortKey,
        label: translation,
      })
    );
  };

  get selectedSortBy$(): Observable<Option<SortKey>> {
    return this.selectedSortBySource.asObservable();
  }

  setSelectedSortBy(option: Option<SortKey>) {
    this.selectedSortBySource.next(option);
  }

  clearActiveFilters() {
    this.selectedSortBySource.next(this.defaultFilterValues);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '811px';
    dialogConfig.height = '600px';
    this.dialog.open(AddProjectDialogComponent, dialogConfig);
  }
}
