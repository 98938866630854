import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { CreateProjectRequestBody } from '../../components/add-project-dialog/models/createProjectRequestBody';
import { Observable } from 'rxjs';

/**
 * Service for handling api calls for projects.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectApiService {
  constructor(private apiService: ApiService) {}

  createProject(
    createProjectApiRecord: ApiRecord,
    requestBody: CreateProjectRequestBody
  ): Observable<{ message: string }> {
    //ToDo: Real API Endpooint is not working at the moment
    return this.apiService.request<{ message: string }>({
      apiRecord: createProjectApiRecord,
      body: requestBody
    });
  }
}
