import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { TranslateService } from '@ngx-translate/core';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

enum countryCode {
  en = 'en-US',
  de = 'de-DE',
}

@Pipe({
  name: 'customFormatDate',
  pure: false,
  standalone: true,
})
export class CustomFormatDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    date: Date | string | number,
    format: string = 'MMMM d, y'
  ): string {
    try {
      const countryCode = this.countryCode(this.translateService.currentLang);
      date = new Date(date);
      return this.transformDate(date, countryCode, format);
    } catch {
      return '';
    }
  }

  transformDate(
    date: Date | null,
    countryCode: string,
    format: string
  ): string {
    const newDate = new DatePipe(countryCode).transform(date, format);
    return newDate ? newDate : '';
  }

  countryCode(language: string): string {
    switch (language) {
      case 'en':
        return countryCode.en;
      case 'de':
        return countryCode.de;
      default:
        return countryCode.en;
    }
  }
}
