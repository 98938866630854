<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="instance; else noInstance">
    <app-display-details
      [data]="instance"
      translationKey="VDI"
    ></app-display-details>
  </ng-container>
  <ng-template #noInstance>
    <div class="content no-instance">
      <mat-icon>cancel_presentation</mat-icon>
      <span>{{ "VDI.Instance.NotAvailable1" | translate }}</span>
      <span>{{ "VDI.Instance.NotAvailable2" | translate }}</span>
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="content loading">
    <mat-spinner diameter="42"></mat-spinner>
    <span>{{ "General.Loading" | translate }}</span>
    <span>{{ "VDI.Instance.Loading" | translate }}</span>
  </div>
</ng-template>
