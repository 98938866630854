import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PackageType } from './models/packageType';
import { SourceType } from './models/sourceType';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Option } from 'src/app/core/models/interfaces/option';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { CreateAppRequestBody } from './models/createAppRequestBody';
import { ApplicationListApiService } from '../application-list/services/application-list-api.service';
import { GenericStateMatcher } from '../../../../shared/utils/genericStateMatcher';
import { ApplicationTypeLabels } from './models/applicationTypeLabels';
import { forbidCaedgeNaming } from 'src/app/shared/utils/caedge-name-validator/caedge-name-validator';

export interface AddApplicationData {
  createApplicationApiRecord: ApiRecord;
  getApplicationsApiRecord: ApiRecord;
  unsubscribe$: Observable<void>;
}

@Component({
  standalone: true,
  selector: 'app-add-application-dialog',
  templateUrl: './add-application-dialog.component.html',
  styleUrls: ['./add-application-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    CommonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AddApplicationDialogComponent implements OnInit, OnDestroy {
  private readonly fieldChangeWatcher$: Subject<void> = new Subject();
  sourceLinkPlaceholder = this.translate.instant(
    'Application.AddApplicationDialog.SourceLink'
  );
  matcher = new GenericStateMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddApplicationData,
    private applicationListApiService: ApplicationListApiService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {}

  appTypes: Option<string>[] = [
    {
      id: ApplicationType.CUSTOM,
      label: ApplicationTypeLabels.ADAPTIVE_AUTOSAR,
    },
    {
      id: ApplicationType.CLASSIC_AUTOSAR_IMAGE,
      label: ApplicationTypeLabels.CLASSIC_AUTOSAR,
    },
    {
      id: ApplicationType.ANDROID,
      label: ApplicationTypeLabels.ANDROID,
    },
    {
      id: ApplicationType.SINGLE_SERVICE,
      label: ApplicationTypeLabels.LINUX_CONTAINER,
    },
    {
      id: ApplicationType.QNX,
      label: ApplicationTypeLabels.QNX,
    },
  ];

  sourceTypes: Option<string>[] = [
    {
      id: SourceType.ARTIFACTORY,
      label: 'Artifactory',
    },
    {
      id: SourceType.GITHUB,
      label: 'GitHub',
    },
  ];

  packageTypes: Option<string>[] = [
    {
      id: PackageType.SOURCE_CODE,
      label: 'Source Code',
    },
    {
      id: PackageType.CONAN,
      label: 'Conan',
    },
    {
      id: PackageType.FIRMWARE_IMAGE,
      label: 'Firmware image',
    },
  ];

  inputFormGroup: FormGroup = this.fb.group({
    applicationNameControl: [
      '',
      [
        Validators.required, 
        Validators.pattern('[a-z0-9_]*'),
        forbidCaedgeNaming,
      ],
    ],
    applicationTypeControl: ['', Validators.required],
    sourceTypeControl: [
      {
        value: '',
        disabled: true,
      },
      Validators.required,
    ],
    packageTypeControl: [
      {
        value: '',
        disabled: true,
      },
      Validators.required,
    ],
    sourceLinkControl: ['', [Validators.required, Validators.pattern('\\S+')]],
  });

  onChangedApplicationType() {
    this.applicationTypeControl.valueChanges
      .pipe(takeUntil(this.fieldChangeWatcher$))
      .subscribe((result) => {
        if (
          result === ApplicationType.CUSTOM ||
          result === ApplicationType.SINGLE_SERVICE
        ) {
          this.sourceTypeControl.setValue(SourceType.GITHUB);
          this.sourceLinkPlaceholder = 'GitHub Repository Name';
        } else {
          this.sourceTypeControl.setValue(SourceType.ARTIFACTORY);
          this.sourceLinkPlaceholder = 'Artifactory Package Name';
        }
        if (result === ApplicationType.CLASSIC_AUTOSAR_IMAGE) {
          this.packageTypeControl.setValue(PackageType.FIRMWARE_IMAGE);
        }
        this.sourceTypeControl.disable();
      });
  }

  onChangedSourceType() {
    this.sourceTypeControl.valueChanges
      .pipe(takeUntil(this.fieldChangeWatcher$))
      .subscribe((result) => {
        if (
          this.applicationTypeControl.value !==
          ApplicationType.CLASSIC_AUTOSAR_IMAGE
        ) {
          if (result === SourceType.GITHUB) {
            this.packageTypeControl.setValue(PackageType.SOURCE_CODE);
          } else {
            this.packageTypeControl.setValue(PackageType.CONAN);
          }
        }
        this.packageTypeControl.disable();
      });
  }

  getErrorMessage(formControl: FormControl | null): string {
    if(formControl) {
      if (formControl.hasError('required')) {
        return this.translate.instant('Application.AddApplicationDialog.ErrorMessage.Required');
      } 
      if (formControl.hasError('pattern')) {
        return this.translate.instant('Application.AddApplicationDialog.ErrorMessage.Pattern');
      }
      if(formControl.hasError('startsWithCaedge')) {
        return this.translate.instant('General.CaedgeNotPermitted');
      }
    }
    return '';
  }

  submitForm(): void {
    const requestBody: CreateAppRequestBody = {
      app_name: this.applicationNameControl.value,
      app_type: this.applicationTypeControl.value,
      code_repo_name: this.sourceLinkControl.value,
    };

    this.applicationListApiService
      .createApplication(this.data.createApplicationApiRecord, requestBody)
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo('Creating a new application...');
          this.refreshApplications();
        },
        error: () => {
          this.snackbarService.notifyError("Couldn't create the application");
        },
      });
  }

  refreshApplications() {
    this.applicationListApiService
      .getApplications(this.data.getApplicationsApiRecord)
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe();
  }

  get applicationNameControl() {
    return this.inputFormGroup.get('applicationNameControl') as FormControl;
  }

  get applicationTypeControl() {
    return this.inputFormGroup.get('applicationTypeControl') as FormControl;
  }

  get sourceTypeControl() {
    return this.inputFormGroup.get('sourceTypeControl') as FormControl;
  }

  get packageTypeControl() {
    return this.inputFormGroup.get('packageTypeControl') as FormControl;
  }

  get sourceLinkControl() {
    return this.inputFormGroup.get('sourceLinkControl') as FormControl;
  }

  ngOnInit(): void {
    this.onChangedApplicationType();
    this.onChangedSourceType();
  }

  ngOnDestroy(): void {
    this.fieldChangeWatcher$.next();
    this.fieldChangeWatcher$.complete();
  }
}
