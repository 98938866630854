import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';

export const DEVICE_SORT_KEYS = [
  'SortKeys.Alphabetical',
  'SortKeys.Date',
] as const;
export const DEVICE_TYPE_KEYS = [
  'DeviceList.FilterKeys.Type.All',
  'DeviceList.FilterKeys.Type.Real',
  'DeviceList.FilterKeys.Type.Simulated',
] as const;
export const DEVICE_CONNECTION_KEYS = [
  'DeviceList.FilterKeys.ConnectionStatus.All',
  'DeviceList.FilterKeys.ConnectionStatus.Connected',
  'DeviceList.FilterKeys.ConnectionStatus.Disconnected',
] as const;
export const DEVICE_CAPACITY_KEYS = [
  'DeviceList.FilterKeys.Capacity.All',
  'DeviceList.FilterKeys.Capacity.Free',
] as const;
export const DEVICE_ENVIRONMENT_KEYS = [
  'DeviceList.FilterKeys.Environment.All',
  'DeviceList.FilterKeys.Environment.Development',
  'DeviceList.FilterKeys.Environment.QA',
  'DeviceList.FilterKeys.Environment.Production',
] as const;

export type DeviceSortKey = (typeof DEVICE_SORT_KEYS)[number];
export type DeviceTypeKey = (typeof DEVICE_TYPE_KEYS)[number];
export type DeviceConnectionKey = (typeof DEVICE_CONNECTION_KEYS)[number];
export type DeviceCapacityKey = (typeof DEVICE_CAPACITY_KEYS)[number];
export type DeviceEnvironmentKey = (typeof DEVICE_ENVIRONMENT_KEYS)[number];

export const filterKeyToDevicePropertyMap = {
  'DeviceList.FilterKeys.Type.Real': DeviceType.REAL,
  'DeviceList.FilterKeys.Type.Simulated': DeviceType.SIMULATED,
  'DeviceList.FilterKeys.ConnectionStatus.Connected':
    DeviceConnectionStatus.CONNECTED,
  'DeviceList.FilterKeys.ConnectionStatus.Disconnected':
    DeviceConnectionStatus.DISCONNECTED,
  'DeviceList.FilterKeys.Capacity.Free': 'Free',
  'DeviceList.FilterKeys.Environment.Development': HdkEnvironment.DEVELOPMENT,
  'DeviceList.FilterKeys.Environment.QA': HdkEnvironment.QA,
  'DeviceList.FilterKeys.Environment.Production': HdkEnvironment.PRODUCTION,
};
