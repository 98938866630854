<app-empty-section *ngIf="devices.length === 0; else deviceList">
  {{ "DeviceList.NoDevices" | translate }}
</app-empty-section>
<ng-template #deviceList>
  <div data-cy="device-list-container" class="device-list-container">
    <app-device-list-item
      *ngFor="let device of devices; trackBy: trackByDeviceName"
      [ngClass]="{
        'connected-device': device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED,
        'in-action-device': 
          isConnecting(device) ||
          device.instanceStatus === DeviceInstanceStatus.CREATE_IN_PROGRESS ||
          device.instanceState === DeviceInstanceState.STOPPING ||
          device.instanceStatus === DeviceInstanceStatus.DELETE_IN_PROGRESS,
        'inactive-device': 
          isInactive(device) ||
          (device.instanceConnectionStatus === DeviceConnectionStatus.DISCONNECTED && !(isConnecting(device) || isStopping(device)))
          && device.instanceStatus !== DeviceInstanceStatus.DELETE_IN_PROGRESS,
        'failed-device': 
          device.instanceStatus === DeviceInstanceStatus.CREATION_FAILED ||
          device.instanceStatus === DeviceInstanceStatus.CREATE_FAILED
      }"
      [device]="device"
      [isInactive]="isInactive(device)"
      class="device-list-item"
    ></app-device-list-item>
  </div>
</ng-template>
