import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  Job,
  Run,
} from '../../../features/simulation-job-monitoring/models/job';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import { CustomFormatDatePipe } from '../../../core/pipes/custom-format-date.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-job-table',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    CustomFormatDatePipe,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './job-table.component.html',
  styleUrls: ['./job-table.component.scss'],
})
export class JobTableComponent implements OnChanges {
  @Input() job: Job | null = {} as Job;
  @Input() tab = 0;
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  displayedColumns: string[] = [
    'select',
    'RunID',
    'WorkflowName',
    'Caller',
    'SubmissionDateTime',
    'StartDateTime',
    'Status',
    'link',
  ];
  dataSource = new MatTableDataSource<Run>([]);
  selection = new SelectionModel<Run>(true, []);
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;

  constructor(private router: Router, private route: ActivatedRoute) {}

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Run): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  ngOnChanges(): void {
    if (!this.job) return;
    this.dataSource.data = this.job?.runs;
  }

  navigateToDetailsPage(jobId: string) {
    this.router.navigate(['./', jobId],{ queryParams: {tab: this.tab}, relativeTo: this.route});
  }
}
