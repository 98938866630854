import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingTypes } from './models/loading-type';

@Component({
  selector: 'app-content-wrapper[isLoading][isError]',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule, TranslateModule],
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent {
  @Input() loadingType: LoadingTypes = LoadingTypes.BAR;
  @Input() isLoading: boolean = true;
  @Input() isError: boolean = false;
  @Input() errorMessage?: string;
  @Input() spinnerDiameter: number = 40;
  
  LoadingTypes = LoadingTypes;
}
