import { Injectable } from '@angular/core';
import {
  combineLatest,
  interval,
  map,
  Observable,
  startWith,
  switchMap,
} from 'rxjs';
import {
  IDataVersion,
  Version,
} from '../../../shared/stores/deployment/models/version';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { ApiService } from '../api/api.service';
import { generatePath } from '../../../shared/utils/generatePath';
import {
  Deployment,
  IDataDeployment,
} from '../../../shared/stores/deployment/models/deployment';
import { IAllEnvironment } from '../../../features/deployment-list/components/deployment-list-expansion-panel/deployment-list-expansion-panel.component';

@Injectable({
  providedIn: 'root',
})
export class DeploymentService {
  interval = 5000;
  constructor(private apiService: ApiService) {}

  getAppVersions(appName: string, apiRecord: ApiRecord): Observable<Version[]> {
    return this.apiService
      .request<IDataVersion[]>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { appName: appName }),
        },
      })
      .pipe(map((versions) => versions.map(Version.Factory)));
  }

  getAllAppVersions(
    appName: string,
    apiRecord: ApiRecord
  ): Observable<Version[]> {
    return interval(this.interval).pipe(
      startWith(this.getAppVersions(appName, apiRecord)),
      switchMap(() => this.getAppVersions(appName, apiRecord))
    );
  }

  getDeployments(
    appName: string,
    versionId: string,
    apiRecord: ApiRecord
  ): Observable<Deployment[]> {
    return this.apiService
      .request<IDataDeployment[]>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, {
            appName: appName,
            versionId: versionId,
          }),
        },
      })
      .pipe(
        map((deployments) =>
          deployments.map((deployment) =>
            Deployment.Factory(deployment, versionId)
          )
        )
      );
  }

  getAllDeployments(
    appName: string,
    versionId: string,
    apiRecordDev: ApiRecord,
    apiRecordQa: ApiRecord
  ): Observable<IAllEnvironment<Deployment[]>> {
    return combineLatest([
      this.getDeployments(appName, versionId, apiRecordDev),
      this.getDeployments(appName, versionId, apiRecordQa),
    ]).pipe(map(([dev, qa]) => ({ dev, qa })));
  }

  addVersion(
    appName: string,
    versionId: string,
    source: string,
    apiRecord: ApiRecord
  ): Observable<{ message: string }> {
    return this.apiService.request({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName }),
      },
      body: {
        version_identifier: versionId,
        branch_or_tag: {
          element_name: source,
          element_type: 'git_branch',
        },
      },
    });
  }

  pipelineBuildRequest(
    appName: string,
    versionId: string,
    buildRequest: 'true' | 'false',
    apiRecord: ApiRecord
  ): Observable<{ version_identifier: string }> {
    return this.apiService.request<{ version_identifier: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
      body: { request_execution: buildRequest },
    });
  }

  deleteVersion(
    appName: string,
    versionId: string,
    apiRecord: ApiRecord
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
    });
  }

  deleteOrStartDeployment(
    appName: string,
    versionId: string,
    deviceName: string,
    apiRecord: ApiRecord
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
      body: { hw_target: deviceName },
    });
  }

  startQNXDeployment(
    appName: string,
    versionId: string,
    deviceName: string,
    apiRecord: ApiRecord
  ) {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
      body: { hw_target: deviceName, command: 'start' },
    });
  }

  stopQNXDeployment(
    appName: string,
    versionId: string,
    deviceName: string,
    apiRecord: ApiRecord
  ) {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
      body: { hw_target: deviceName, command: 'stop' },
    });
  }
}
