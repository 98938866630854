import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CaeButtonComponent } from '../../shared/components/cae-button/cae-button.component';
import { SimulationJobMonitoringService } from './services/simulation-job-monitoring.service';
import { Job, Run } from './models/job';
import { JobTableComponent } from '../../shared/components/job-table/job-table.component';
import { BehaviorSubject } from 'rxjs';
import { FeatureComponent } from '../../core/models/classes/feature.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export type JobMonitoringApi =
  | 'jobMonitoringSimulation'
  | 'jobMonitoringReporting';

@Component({
  selector: 'app-simulation-job-monitoring',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    MatTabsModule,
    TranslateModule,
    CaeButtonComponent,
    JobTableComponent,
    MatProgressBarModule,
  ],
  templateUrl: './simulation-job-monitoring.component.html',
  styleUrls: ['./simulation-job-monitoring.component.scss'],
})
export class SimulationJobMonitoringComponent extends FeatureComponent<JobMonitoringApi> {
  countJobs = 0;
  loading = false;
  runs: Run[] | null = [];
  job$: BehaviorSubject<Job | null> = new BehaviorSubject<Job | null>(null);
  tab = 0;
  constructor(
    private simulationJobMonitoringService: SimulationJobMonitoringService
  ) {
    super();
    this.getJobs(CurrentTab.SIMULATION);
  }

  async getJobs(tab: CurrentTab) {
    try {
      if (!this.API) return;
      let response = null;
      this.loading = true;
      if (tab === CurrentTab.SIMULATION) {
        response = await this.simulationJobMonitoringService.getJobs(
          this.API.jobMonitoringSimulation
        );
      }

      if (tab === CurrentTab.REPORTING) {
        response = await this.simulationJobMonitoringService.getJobs(
          this.API.jobMonitoringReporting
        );
      }
      this.job$.next(response);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  cancelJobs() {}

  createJob() {}

  async onTabChange(index: number) {
    this.tab = index;
    await this.getJobs(index);
  }
}

export enum CurrentTab {
  SIMULATION = 0,
  REPORTING = 1,
}
