import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormGroup, FormsModule, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { take, tap } from 'rxjs';

/**
 * `StepComponent` implementation for the general information step of the project creation process.
 * @export
 * @class GeneralInformationComponent
 */
@Component({
  selector: 'app-general-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit, StepComponent {
  @Input() numCharsTenant = 0;

  generalInfoForm: FormGroup;

  projectNameMaxLength = 0;

  readonly minCharsWB = 8;
  private readonly charsLeft = 24;

  constructor(private fb: FormBuilder, private translate: TranslateService) {
    this.generalInfoForm = this.fb.group({
      projectName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9-]*')]],
      projectDescription: ['']
    });
  }

  get projectNameControl() {
    return this.generalInfoForm.get('projectName') as FormControl;
  }

  get projectDescriptionControl() {
    return this.generalInfoForm.get('projectDescription') as FormControl;
  }

  get dataToUpdate() {
    const formValue = this.generalInfoForm.getRawValue();
    return {
      Description: formValue.projectDescription,
      Name: formValue.projectName
    };
  }

  get stepValid(): boolean {
    return this.generalInfoForm.valid;
  }

  ngOnInit(): void {
    this.projectNameMaxLength = this.charsLeft - this.numCharsTenant;
    this.projectNameControl.addValidators(Validators.maxLength(this.projectNameMaxLength - this.minCharsWB));
    this.generalInfoForm.updateValueAndValidity();
    this.initProjectNameControl();
  }

  private initProjectNameControl() {
    this.projectNameControl.valueChanges
      .pipe(
        take(1),
        tap(() => {
          this.projectNameControl.markAsTouched();
        })
      )
      .subscribe();
  }

  /**
   * Checks if the given FormControl is invalid and returns the corresponding error message.
   *
   * @param formControl the FormControl to validate
   * @return the corresponding error message or an empty string in case there is no error
   */
  getErrorMessage(formControl: FormControl | null): string {
    if (formControl) {
      if (formControl.hasError('required')) {
        return this.translate.instant('Project.AddProjectDialog.ErrorMessage.Required');
      }
      if (formControl.hasError('pattern')){
        return this.translate.instant('Project.AddProjectDialog.ErrorMessage.Pattern');
      }
      if (formControl.hasError('maxlength')) {
        return this.translate.instant('Project.AddProjectDialog.ErrorMessage.MaxLengthProjectName');
      }
    }
    return '';
  }
}
