import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationSettingsComponent } from '../../../project-dashboard/components/application-settings/application-settings.component';
import { CustomFormatDatePipe } from '../../../../core/pipes/custom-format-date.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-display-details',
  standalone: true,
  imports: [
    CommonModule,
    ApplicationSettingsComponent,
    CustomFormatDatePipe,
    TranslateModule,
  ],
  templateUrl: './display-detail.component.html',
  styleUrls: ['./display-detail.component.scss'],
})
export class DisplayDetailComponent implements OnChanges {
  @Input({ required: true }) data: any;
  @Input() translationKey: string = '';
  entries: { key: string; value: any }[] = [];

  convertToKeyValues(obj: any): { key: string; value: any }[] {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  ngOnChanges(): void {
    if (this.data) {
      this.entries = this.convertToKeyValues(this.data);
    }
  }
}
