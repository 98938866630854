<form class="form" [formGroup]="billingInfoForm">
  <div class="content" mat-dialog-content>
    <table class="billing-info-table">
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>monetization_on</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.ControllingContact" | translate
            }}</mat-label>
            <input
              formControlName="controllingContact"
              matInput
              [matAutocomplete]="controllingAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #controllingAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of controllingContact$ | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="controllingContactControl?.invalid">
              {{ getErrorMessage(controllingContactControl) }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.CostCenter" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.CostCenter' | translate
              }}"
              formControlName="costCenter"
              matInput
            />
            <mat-error *ngIf="costCenterControl?.invalid">
              {{ getErrorMessage(costCenterControl) }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>monetization_on</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.BillingContact" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.BillingContact' | translate
              }}"
              formControlName="billingContact"
              matInput
              [matAutocomplete]="billingAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #billingAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of billingContacts$ | async"
                [value]="option.Email"
              >
                {{ option.Email }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="billingContactControl?.invalid">
              {{ getErrorMessage(billingContactControl) }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.CostCenterOwner" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.CostCenterOwner' | translate
              }}"
              formControlName="costCenterOwner"
              matInput
              [matAutocomplete]="costCenterAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #costCenterAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of costCenterOwners$ | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="costCenterOwnerControl?.invalid">
              {{ getErrorMessage(costCenterOwnerControl) }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.LegalEntity" | translate
            }}</mat-label>
            <mat-select
              [formControl]="legalEntityControl"
              placeholder="{{
                'Project.AddProjectDialog.LegalEntity' | translate
              }}"
              class="caedge-form-field"
            >
              <mat-option
                *ngFor="let legalEntity of legalEntities"
                [value]="legalEntity.id"
              >
                {{ legalEntity.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="legalEntityControl?.invalid">
              {{ getErrorMessage(legalEntityControl) }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.WBSElement" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.WBSElement' | translate
              }}"
              formControlName="wbsElement"
              matInput
            />
            <mat-error *ngIf="wbsElementControl?.invalid">
              {{ getErrorMessage(wbsElementControl) }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="billing-info"></td>
      </tr>
    </table>
  </div>
</form>
