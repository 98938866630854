import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  combineLatest,
  interval,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { DeviceStore } from 'src/app/shared/stores/devices/devices.store';
import {
  Device,
  IDataDevice,
} from 'src/app/shared/stores/devices/models/device/device';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { FirmwareVersion } from 'src/app/shared/stores/fwVersions/models/fwVersion';
import { generatePath } from 'src/app/shared/utils/generatePath';

export enum SimulatedDeviceAction {
  START = 'START',
  STOP = 'STOP',
  REBOOT = 'REBOOT',
}

interface IDownload64EncodedZipFile {
  body: string;
  headers: {
    'Content-Disposition': string;
    'Content-Type': 'application/zip';
  };
  isBase64Encoded: boolean;
  statusCode: HttpStatusCode;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceListApiService {
  constructor(
    private deviceStore: DeviceStore,
    private apiService: ApiService
  ) {}

  interval: number = 5000;

  getDevices(devApiRecord: ApiRecord, qaApiRecord: ApiRecord) {
    return combineLatest([
      this.getDeviceList(devApiRecord),
      this.getDeviceList(qaApiRecord),
    ]).pipe(
      map(([devDevices, qaDevices]) => {
        const qaDevicesInfo = qaDevices.map((device) =>
          Device.Factory(device, HdkEnvironment.QA)
        );
        const realConnectedQaDevices = qaDevicesInfo.filter(
          ({ instanceType, environments }) =>
            instanceType === DeviceType.REAL && environments.length !== 0
        );

        const deviceList: Device[] = [
          ...devDevices.map((device) => {
            return Device.Factory(device, HdkEnvironment.DEVELOPMENT);
          }),
          ...qaDevicesInfo.filter(
            (deviceInfo) => deviceInfo.instanceType === DeviceType.SIMULATED
          ),
        ];

        if (realConnectedQaDevices.length > 0) {
          const realConnectedQaDevicesNames: string[] =
            realConnectedQaDevices.map((device) => device.hwName);

          return deviceList.map((deviceInfo) => {
            if (realConnectedQaDevicesNames.includes(deviceInfo.hwName)) {
              const updatedDevice = new Device();
              updatedDevice.hwName = deviceInfo.hwName;
              updatedDevice.environments = [
                ...deviceInfo.environments,
                HdkEnvironment.QA,
              ];
              updatedDevice.connectedEnvironments = deviceInfo.connectedEnvironments;
              updatedDevice.instanceConnectionStatus = deviceInfo.instanceConnectionStatus;
              updatedDevice.creationDate = deviceInfo.creationDate;
              updatedDevice.instanceType = deviceInfo.instanceType;
              updatedDevice.instanceStatus = deviceInfo.instanceStatus;
              updatedDevice.instanceState = deviceInfo.instanceState;
              updatedDevice.disconnectedReason = deviceInfo.disconnectedReason;
              updatedDevice.fwVersion = deviceInfo.fwVersion;
              updatedDevice.fwUpdate = deviceInfo.fwUpdate;
              updatedDevice.lastFwUpdate = deviceInfo.lastFwUpdate;
              updatedDevice.numOfDeployments = deviceInfo.numOfDeployments;
              return updatedDevice;
            }
            return deviceInfo;
          });
        }
        return deviceList;
      }),
      tap((devices) => {
        this.deviceStore.setState({
          ...this.deviceStore.state,
          devices,
          isLoading: false,
          hasError: false,
        });
      }
      ),
      catchError(() => {
        this.deviceStore.setState({
          ...this.deviceStore.state,
          devices: [],
          isLoading: false,
          hasError: true,
        });

        return of([]);
      })
    );
  }

  getDevicesForAppVersion(
    devApiRecord: ApiRecord,
    qaApiRecord: ApiRecord
  ): Observable<{ devDevices: IDataDevice[]; qaDevices: IDataDevice[] }> {
    return combineLatest([
      this.getDeviceList(devApiRecord),
      this.getDeviceList(qaApiRecord),
    ]).pipe(map(([devDevices, qaDevices]) => ({ devDevices, qaDevices })));
  }

  getDeviceList(apiRecord: ApiRecord): Observable<IDataDevice[]> {
    return interval(this.interval).pipe(
      startWith(this.apiService.request<IDataDevice[]>({ apiRecord })),
      switchMap(() => this.apiService.request<IDataDevice[]>({ apiRecord }))
    );
  }

  getData<T>(apiRecord: ApiRecord): Observable<T> {
    return interval(this.interval).pipe(
      startWith(this.apiService.request<T>({ apiRecord })),
      switchMap(() => this.apiService.request<T>({ apiRecord }))
    );
  }

  triggerDeviceAction(
    deviceName: string,
    action: SimulatedDeviceAction,
    actionApiRecord: ApiRecord
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...actionApiRecord,
        url: generatePath(actionApiRecord.url, { hwName: deviceName }),
      },
      body: { action },
    });
  }

  deleteDevice(
    deviceName: string,
    apiRecord: ApiRecord
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  createDevice(
    deviceName: string,
    deviceType: DeviceType,
    apiRecord: ApiRecord
  ): Observable<{ message: string }> {
    
    let requestBody = undefined;

    if(deviceType === DeviceType.VHPC) {
      requestBody = {
        vhpcName: deviceName
      };
    } else {
      requestBody = {
        hwName: deviceName
      };
    }

    return this.apiService.request<{ message: string }>({
      apiRecord,
      body: requestBody,
    });
  }

  getConnectionPackage(
    deviceName: string,
    apiRecord: ApiRecord
  ): Observable<IDownload64EncodedZipFile> {
    return this.apiService.request<IDownload64EncodedZipFile>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  getDeviceScripts(
    deviceName: string,
    apiRecord: ApiRecord
  ): Observable<IDownload64EncodedZipFile> {
    return this.apiService.request<IDownload64EncodedZipFile>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName })
      }
    });
  }

  getFwUpdates(
    deviceName: string,
    apiRecord: ApiRecord
  ): Observable<FirmwareVersion[]> {
    return this.apiService.request<FirmwareVersion[]>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  updateFwVersion(
    deviceName: string,
    targetFwVersion: string,
    apiRecord: ApiRecord
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
      body: {
        targetFwVersion,
      },
    });
  }
}
