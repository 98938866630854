import { ApplicationType } from '../../applications/models/applicationType';
import { PackageType } from '../../../../features/project-dashboard/components/add-application-dialog/models/packageType';
import { SourceType } from '../../../../features/project-dashboard/components/add-application-dialog/models/sourceType';

export enum DeploymentStatus {
  DEPLOYMENT_NOT_POSSIBLE = 'DEPLOYMENT_NOT_POSSIBLE',
  DEPLOYMENT_IN_CREATION = 'DEPLOYMENT_IN_CREATION',
  DEPLOYMENT_EXECUTION_SUCCEEDED = 'DEPLOYMENT_EXECUTION_SUCCEEDED',
  DEPLOYMENT_EXECUTION_FAILED = 'DEPLOYMENT_EXECUTION_FAILED',
  DEPLOYMENT_EXECUTION_REJECTED = 'DEPLOYMENT_EXECUTION_REJECTED',
  DEPLOYMENT_EXECUTION_CANCELED = 'DEPLOYMENT_EXECUTION_CANCELED',
  DEPLOYMENT_EXECUTION_REMOVED = 'DEPLOYMENT_EXECUTION_REMOVED',
  DEPLOYMENT_EXECUTION_TIMED_OUT = 'DEPLOYMENT_EXECUTION_TIMED_OUT',
  DELETION_NOT_POSSIBLE = 'DELETION_NOT_POSSIBLE',
  DELETION_IN_PROGRESS = 'DELETION_IN_PROGRESS',
  DELETION_FAILED = 'DELETION_FAILED',
}

export interface IDataApplication {
  application: string;
  app_type: ApplicationType;
  code_repo_name: string;
  creation_date: string;
}
export interface IApplication {
  appName: string;
  appType: ApplicationType;
  codeRepoName: string;
  creationDate: number;
  appSource: SourceType;
  appPackage: PackageType;
}

export class Application implements IApplication {
  appName!: string;
  appType!: ApplicationType;
  appSource!: SourceType;
  appPackage!: PackageType;
  codeRepoName!: string;
  creationDate!: number;

  public static Factory(appData: IDataApplication) {
    const application = new Application();
    const date: number = parseInt(appData.creation_date);
    application.appName = appData.application;
    application.appType = appData.app_type;
    application.codeRepoName = appData.code_repo_name;

    switch (application.appType) {
      case ApplicationType.SINGLE_SERVICE:
      case ApplicationType.CUSTOM:
        application.appSource = SourceType.GITHUB;
        application.appPackage = PackageType.SOURCE_CODE;
        break;
      case ApplicationType.QNX:
      case ApplicationType.ANDROID:
        application.appSource = SourceType.ARTIFACTORY;
        application.appPackage = PackageType.CONAN;
        break;
      case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
        application.appSource = SourceType.ARTIFACTORY;
        application.appPackage = PackageType.FIRMWARE_IMAGE; // FIRMWARE_IMAGE
        break;
    }
    application.creationDate = isNaN(date) ? -1 : date;

    return application;
  }
}
