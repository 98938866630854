import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceType } from '../../../../shared/stores/devices/models/deviceType';
import { ChipComponent, ChipVariant } from 'src/app/shared/components/chip/chip.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DeviceListContentComponent } from '../../../device-list/components/device-list-content/device-list-content.component';
import { ContentWrapperComponent } from '../../../../shared/components/content-wrapper/content-wrapper.component';
import { Device } from '../../../../shared/stores/devices/models/device/device';
import {
  DeploymentStatus,
  DeploymentStatusMap,
  Deployment
} from '../../../../shared/stores/deployment/models/deployment';
import { DeviceConnectionStatus } from '../../../../shared/stores/devices/models/deviceConnectionStatus';
import { IAllEnvironment } from '../deployment-list-expansion-panel/deployment-list-expansion-panel.component';
import { HdkEnvironment } from '../../../../core/models/interfaces/hdkEnvironment';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { DeploymentListApi } from '../../deployment-list.component';
import { DeploymentService } from 'src/app/core/services/deployment-list/deployment.service';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { Version } from 'src/app/shared/stores/deployment/models/version';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Method } from 'src/app/shared/stores/config/models/method';
import { CustomFormatDatePipe } from '../../../../core/pipes/custom-format-date.pipe';
import { HttpClientModule } from '@angular/common/http';
import { getDeviceTypeTooltipKey } from 'src/app/features/device-list/utils/helpers';
import { ApplicationType } from '../../../../shared/stores/applications/models/applicationType';
import { DeploymentErrorStatusCheckPipe } from 'src/app/features/deployment-list/utils/pipes/deployment-error-status.pipe';

@Component({
  selector: 'app-list-item',
  standalone: true,
  imports: [
    CommonModule,
    ChipComponent,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    DeviceListContentComponent,
    ContentWrapperComponent,
    CustomFormatDatePipe,
    DeploymentErrorStatusCheckPipe,
    HttpClientModule
  ],
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent extends FeatureComponent<DeploymentListApi> implements OnInit, OnChanges, OnDestroy {
  @Input() device: Device = {} as Device;
  @Input() deployments: IAllEnvironment<Deployment[]> = {
    dev: [],
    qa: []
  };
  @Input() version!: Version;
  @Input() appName: string = '';
  protected readonly deviceConnectionStatus = DeviceConnectionStatus;
  readonly DeploymentStatus = DeploymentStatus;

  dateFormat: string = 'dd-MM-yy, hh:MM';
  deploymentStatusTooltip: string = '';
  chipVariant = ChipVariant;
  deploymentStatus: DeploymentStatus = DeploymentStatus.DELETION_IN_PROGRESS;
  private readonly emptyDeployment: Deployment = {
    hwTarget: '',
    deploymentStatus: DeploymentStatus.UNKNOWN,
    deploymentDetail: '',
    commitId: '',
    versionId: ''
  };
  currentDeployment = this.emptyDeployment;
  private readonly unsubscribe$ = new Subject<void>();
  deleteDeploymentApiRecords: Record<HdkEnvironment, ApiRecord> = {
    [HdkEnvironment.DEVELOPMENT]: this.API?.deleteDevDeployment!,
    [HdkEnvironment.QA]: this.API?.deleteQaDeployment!,
    [HdkEnvironment.PRODUCTION]: {
      url: '',
      method: Method.DELETE
    }
  };

  startQNXDeploymentApiRecord: Record<HdkEnvironment, ApiRecord> = {
    [HdkEnvironment.DEVELOPMENT]: this.API?.startDevQNXDeployment!,
    [HdkEnvironment.QA]: this.API?.startQaQNXDeployment!,
    [HdkEnvironment.PRODUCTION]: {
      url: '',
      method: Method.PUT
    }
  };

  stopQNXDeploymentApiRecord: Record<HdkEnvironment, ApiRecord> = {
    [HdkEnvironment.DEVELOPMENT]: this.API?.stopDevQNXDeployment!,
    [HdkEnvironment.QA]: this.API?.stopQaQNXDeployment!,
    [HdkEnvironment.PRODUCTION]: {
      url: '',
      method: Method.PUT
    }
  };

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
    private deploymentService: DeploymentService,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  private getCurrentDeployment(): Deployment {
    const deployments = {
      [HdkEnvironment.DEVELOPMENT]: this.deployments.dev,
      [HdkEnvironment.QA]: this.deployments.qa,
      [HdkEnvironment.PRODUCTION]: []
    };

    const env = this.device.environments[0];

    const deployment = deployments[env].find((deployment) => deployment.hwTarget === this.device.hwName);
    return deployment ? deployment : this.emptyDeployment;
  }

  get deviceTypeTooltipKey() {
    return getDeviceTypeTooltipKey(this.device);
  }

  getDeviceEnvLabel(device: Device): string {
    return device.environments ? `DeviceList.FilterKeys.EnvironmentShort.${device.environments}` : 'N/A';
  }

  isDeviceConnected(device: Device): boolean {
    return device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED;
  }

  getDeploymentStatus(hardwareDevice: Device) {
    const dev = this.deployments.dev.length > 0;
    const qa = this.deployments.qa.length > 0;
    const hardware = hardwareDevice.environments.length > 0;

    if ((!dev || !qa) && !hardware) {
      return DeploymentStatusMap[DeploymentStatus.UNKNOWN];
    }

    const deployments = {
      [HdkEnvironment.DEVELOPMENT]: this.deployments.dev,
      [HdkEnvironment.QA]: this.deployments.qa,
      [HdkEnvironment.PRODUCTION]: []
    };
    const env = hardwareDevice.environments[0];
    const deployment = deployments[env].find((deployment) => deployment.hwTarget === hardwareDevice.hwName);
    const status = deployment ? deployment.deploymentStatus : DeploymentStatus.DEPLOYMENT_IN_CREATION;

    const translationKey = `Deployments.DeploymentStatus.${status}`;
    const translation = this.translate.instant(translationKey);

    this.deploymentStatus = status;
    return translation;
  }

  getDeviceTypeLabel(device: Device): string {
    switch (device.instanceType) {
      case DeviceType.REAL:
        return 'REAL';
      case DeviceType.SIMULATED:
        return 'SIM';
      case DeviceType.VHPC:
        return 'vHPC';
    }
  }

  deleteDeploymentClick(): void {
    if (this.API) {
      this.deploymentService
        .deleteOrStartDeployment(
          this.appName,
          this.version.versionId,
          this.device.hwName,
          this.deleteDeploymentApiRecords[this.device.environments[0]]
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo('Deployment deletion initiated');
          },
          error: () => {
            this.snackbarService.notifyError('Deployment deletion failed');
          }
        });
    }
  }

  openDeleteDeploymentDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('Deployments.DeleteDeploymentDialog.Title'),
      message: this.translate.instant('Deployments.DeleteDeploymentDialog.Text'),
      confirmText: this.translate.instant('Deployments.DeleteDeploymentDialog.Confirm'),
      width: '400px',
      onConfirm: () => this.deleteDeploymentClick()
    });
  }

  startQNXDeployment() {
    if (this.API) {
      this.deploymentService
        .startQNXDeployment(
          this.appName,
          this.version.versionId,
          this.device.hwName,
          this.startQNXDeploymentApiRecord[this.device.environments[0]]
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo('Start application');
          },
          error: () => {
            this.snackbarService.notifyError('Failed to start');
          }
        });
    }
  }

  stopQNXDeployment() {
    if (this.API) {
      this.deploymentService
        .stopQNXDeployment(
          this.appName,
          this.version.versionId,
          this.device.hwName,
          this.stopQNXDeploymentApiRecord[this.device.environments[0]]
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo('Stop application');
          },
          error: () => {
            this.snackbarService.notifyError('Failed to stop');
          }
        });
    }
  }

  isQnxApplication() {
    const applicationType = localStorage.getItem('selectedApplicationType');
    return applicationType === ApplicationType.QNX;
  }

  ngOnInit(): void {
    this.deploymentStatusTooltip = this.getDeploymentStatus(this.device);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device'] && changes['device'].currentValue) {
      this.currentDeployment = this.getCurrentDeployment();
    }

    if (changes['deployments'] && changes['deployments'].currentValue) {
      this.getDeploymentStatus(this.device);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
