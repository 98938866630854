import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { MatIconModule } from '@angular/material/icon';
import {
  DeviceListApiService,
  SimulatedDeviceAction,
} from 'src/app/features/device-list/services/device-list-api/device-list-api.service';
import { Subject, takeUntil } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-simulated-action-buttons',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './simulated-action-buttons.component.html',
  styleUrls: ['./simulated-action-buttons.component.scss'],
})
export class SimulatedActionButtonsComponent {
  @Input() device!: Device;
  @Input() isConnecting!: boolean;
  @Input() isStopping!: boolean;
  @Input() isBeingCreated!: boolean;
  @Input() deviceActionApiConfigMap?: Record<HdkEnvironment, ApiRecord>;

  private readonly unsubscribe$: Subject<void> = new Subject();
  Action = SimulatedDeviceAction;

  constructor(
    private dialogService: DialogService,
    private deviceListApiService: DeviceListApiService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  deviceActionClick(device: Device, action: SimulatedDeviceAction) {
    if (!this.deviceActionApiConfigMap) {
      return;
    }
    this.deviceListApiService
      .triggerDeviceAction(
        device.hwName,
        action,
        this.deviceActionApiConfigMap[device.environments[0]]
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo(`${action} request sent`);
        },
        error: () => {
          this.snackbarService.notifyError(`${action} request failed`);
        },
      });
  }

  openInfoDialog() {
    this.dialogService.openDialog({
      type: DialogType.INFO,
      title: this.translate.instant('DeviceList.ErrorInformation'),
      message:
        'Unfortunately the device could not be created.\nError code: #123456\nError Description: No network connected.',
      closeText: 'Okay',
    });
  }
}
