import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeviceListApiService } from 'src/app/features/device-list/services/device-list-api/device-list-api.service';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { FwVersionsStore } from 'src/app/shared/stores/fwVersions/fwVersions.store';
import { combineLatest, map } from 'rxjs';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeviceFwStatus } from 'src/app/shared/stores/devices/models/deviceFwStatus';
import { FirmwareVersion } from 'src/app/shared/stores/fwVersions/models/fwVersion';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { LoadingTypes } from 'src/app/shared/components/content-wrapper/models/loading-type';

@Component({
  selector: 'app-fw-updates-actions',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    ContentWrapperComponent,
  ],
  templateUrl: './fw-updates-actions.component.html',
  styleUrls: ['./fw-updates-actions.component.scss'],
})
export class FwUpdatesActionsComponent {
  @Input() device!: Device;
  @Input() getVersionsApiRecordsMap?: Record<HdkEnvironment, ApiRecord>;
  @Input() updateFwVersionsApiRecordsMap?: Record<HdkEnvironment, ApiRecord>;

  selectedVersion: string = '';
  LoadingTypes = LoadingTypes;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private devicesApiService: DeviceListApiService,
    private fwVersionsStore: FwVersionsStore,
    private snackbarService: SnackbarService
  ) {}

  fwUpdatesData$ = combineLatest([
    this.fwVersionsStore.fwVersions$,
    this.fwVersionsStore.isLoading$,
    this.fwVersionsStore.hasError$,
  ]).pipe(
    map(([versions, isLoading, hasError]) => {
      const nonCurrentVersions = versions.filter(
        (version) => version.fwVersion !== this.device.fwVersion
      );
      return { nonCurrentVersions, isLoading, hasError };
    })
  );

  get isUpdating() {
    return this.device.lastFwUpdate?.status === DeviceFwStatus.IN_PROGRESS;
  }

  get hasUpdateFailed() {
    return this.device.lastFwUpdate?.status === DeviceFwStatus.FAILED;
  }

  openUpdateDeviceDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('DeviceList.FwUpdates.Dialog.Title'),
      message: this.translate.instant('DeviceList.FwUpdates.Dialog.Text'),
      secondaryMessage: this.translate.instant(
        'DeviceList.FwUpdates.Dialog.Note'
      ),
      confirmText: this.translate.instant(
        'DeviceList.FwUpdates.Dialog.Confirm'
      ),
      width: '400px',
      onConfirm: () => this.updateDeviceClick(),
    });
  }

  updateDeviceClick() {
    if (!this.updateFwVersionsApiRecordsMap) {
      return;
    }
    this.devicesApiService
      .updateFwVersion(
        this.device.hwName,
        this.selectedVersion,
        this.updateFwVersionsApiRecordsMap[this.device.environments[0]]
      )
      .subscribe({
        next: () => this.snackbarService.notifyInfo('Update requested'),
        error: () => this.snackbarService.notifyError('Update failed!'),
      });
  }

  fetchFwVersions() {
    if (!this.getVersionsApiRecordsMap) {
      return;
    }
    this.devicesApiService
      .getFwUpdates(
        this.device.hwName,
        this.getVersionsApiRecordsMap[this.device.environments[0]]
      )
      .subscribe({
        next: (versions) =>
          this.fwVersionsStore.setState({
            fwVersions: versions.reverse(),
            isLoading: false,
            hasError: false,
          }),
        error: () =>
          this.fwVersionsStore.setState({
            fwVersions: [],
            isLoading: false,
            hasError: true,
          }),
      });
  }

  setSelectedVersion(version: FirmwareVersion) {
    this.selectedVersion = version.fwVersion;
  }

  /**
   * The material menu emits the "close" event when the menu is about to be closed instead of after.
   * One possible workaround is to add a timeout to achieve better UX.
   * The timeout can be removed if Angular material add something like "afterClosed" event.
   */
  onMenuClose() {
    setTimeout(() => {
      this.fwVersionsStore.setState({
        fwVersions: [],
        isLoading: true,
        hasError: false,
      });
    }, 500);
  }
}
