<mat-toolbar class="header" *ngIf="theme$ | async as theme">
  <app-language-picker 
    data-cy="language-picker" 
    [matTooltip]="'Header.SelectLanguage' | translate"
    [matTooltipDisabled]="!isTooltip"
  >
  </app-language-picker>
  <button
    mat-icon-button data-cy="light-dark-mode"
    [attr.aria-label]="'Header.Theme' | translate"
    (click)="toggleTheme(theme)"
    [matTooltip]="(theme === 'dark' ? 'Header.DarkMode' : 'Header.LightMode') | translate"
  >
    <mat-icon data-cy="mat-icon-light-dark-mode"
      [svgIcon]="theme === 'dark' ? 'dark-mode-toggle' : 'light-mode-toggle'"
    />
  </button>
  <button
    mat-icon-button data-cy="support-button"
    [attr.aria-label]="'Header.HelpMenu' | translate"
    (click)="openHelpMenu()"
    [matTooltip]="'Header.HelpMenu' | translate"
    [matTooltipDisabled]="!isTooltip"
  >
    <mat-icon data-cy="help-menu-icon">help_outline</mat-icon>
  </button>
  <button
    mat-icon-button
    class="profile-button"
    data-cy="profile-button"
    [attr.aria-label]="'Header.ProfileView' | translate"
    [matTooltip]="'Header.Profile' | translate"
    [matTooltipDisabled]="!isTooltip"
    #profile
    (click)="openProfileView()"
  >
    <mat-icon [svgIcon]="theme === 'dark' ? 'dark-profile' : 'light-profile'" />
  </button>
</mat-toolbar>
