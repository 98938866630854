import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { NewDeploymentComponent } from 'src/app/features/deployment-list/components/new-deployment/new-deployment.component';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { HdkEnvironment } from '../../models/interfaces/hdkEnvironment';

export type StartDeploymentApi = {
  createDevDeployment: ApiRecord;
  createQaDeployment: ApiRecord;
  getApplications?: ApiRecord;
  getVersions?: ApiRecord;
  getDevDevices?: ApiRecord;
  getQaDevices?: ApiRecord;
};

@Injectable({
  providedIn: 'root',
})
export class StartDeploymentService {
  constructor(private dialog: MatDialog) {}

  openStartDeploymentDialog(
    appName: string,
    apiRecords: StartDeploymentApi,
    predefinedValues: {
      versionId?: string;
      deviceName?: string;
      environment?: HdkEnvironment;
    },
    unsubscribe$: Subject<void>
  ) {
    this.dialog.open(NewDeploymentComponent, {
      data: {
        appName,
        apiRecords,
        predefinedValues,
        unsubscribe$,
      },
      width: '400px',
      autoFocus: false,
      disableClose: true,
    });
  }
}
