<div class="child-job-header">
  <div class="title">{{'Jobs.Child.Title' | translate}}</div>
  <div class="subtitle">{{'Jobs.Child.Subtitle' | translate}}</div>
</div>

<mat-form-field class="caedge-form-field-fill">
  <div class="search">
    <mat-icon
      (click)="
        $event.stopPropagation(); dataSource.filter = ''; input.value = ''
      "
      data-cy="clear-search-icon"
      aria-label="search and clear"
    >
      {{ input.value.length > 0 ? "close" : "search" }}
    </mat-icon>
    <input
      matInput
      data-cy="search-input"
      (keyup)="applyFilter($event)"
      placeholder="Search..."
      #input
    />
  </div>
</mat-form-field>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <!-- Index Column -->
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>#Index</th>
    <td mat-cell *matCellDef="let element">{{ element.ChildJobIndex}}</td>
  </ng-container>

  <!-- RecId Column -->
  <ng-container matColumnDef="recId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>RecId</th>
    <td mat-cell *matCellDef="let element">{{ element.RECID}}</td>
  </ng-container>

  <!-- Input Field Name Column -->
  <ng-container matColumnDef="InputFileName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Input file name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.InputFileName }}
    </td>
  </ng-container>

  <!-- Section Start Column -->
  <ng-container matColumnDef="SectionStart">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Section start</th>
    <td mat-cell *matCellDef="let element">
      {{ element.SectionStart}}
    </td>
  </ng-container>

  <!-- Section End Column -->
  <ng-container matColumnDef="SectionEnd">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Section end</th>
    <td mat-cell *matCellDef="let element">{{ element.SectionEnd}}
    </td>
  </ng-container>

  <!-- Job Exit Code Column -->
  <ng-container matColumnDef="JobExitCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Job exit code</th>
    <td mat-cell *matCellDef="let element">{{ element.JobExitCode ? element.JobExitCode : '-' }}</td>
  </ng-container>

  <!-- Steps Column -->
  <ng-container matColumnDef="Steps">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Steps</th>
    <td mat-cell *matCellDef="let element">{{element.StepNames === [] ? element.StepNames + '...' : '-' }}</td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element">
      <app-chip
        class="type-chip"
        [label]="element.isFailed? 'Failed' : 'Succeeded' "
        [variant]="element.isFailed ? ChipVariant.ERROR : ChipVariant.SUCCESS"
      />
    </td>
  </ng-container>

  <!-- Steps Button Column -->
  <ng-container matColumnDef="link">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let element">
      <mat-icon (click)="openStepsDialog(element.ChildJobIndex)">navigate_next</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data found</td>
  </tr>
</table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  aria-label="Select page of periodic elements"
>
</mat-paginator>
