import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DialogProps } from './models/dialogProps';
import { DialogType } from './models/dialogType';

@Component({
  standalone: true,
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    CommonModule,
  ],
})
export class DialogComponent {
  DialogType = DialogType;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogProps) {}
}
