import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceListItemComponent } from '../device-list-item/device-list-item.component';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { deviceIsInactive } from '../../utils/helpers';
import { DeviceInstanceStatus } from 'src/app/shared/stores/devices/models/deviceInstanceStatus';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { DeviceInstanceState } from 'src/app/shared/stores/devices/models/deviceInstanceState';

@Component({
  selector: 'app-device-list-content',
  standalone: true,
  imports: [
    CommonModule,
    DeviceListItemComponent,
    EmptySectionComponent,
    TranslateModule,
  ],
  templateUrl: './device-list-content.component.html',
  styleUrls: ['./device-list-content.component.scss'],
})
export class DeviceListContentComponent {
  @Input() devices: Device[] = [];

  DeviceInstanceStatus = DeviceInstanceStatus;
  DeviceConnectionStatus = DeviceConnectionStatus;
  DeviceInstanceState = DeviceInstanceState;

  trackByDeviceName(index: number, device: Device): string {
    return device.hwName;
  }

  isInactive(device: Device) {
    return deviceIsInactive(device);
  }

  isConnecting(device: Device) {
    return device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTING;
  }

  isStopping(device: Device) {
    return device.instanceState === DeviceInstanceState.STOPPING;
  }
}
