import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '../../models/classes/abstract.store';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService extends Store<Theme> {
  constructor() {
    const savedColorMode = localStorage.getItem('savedColorMode') as Theme;
    if (savedColorMode === Theme.DARK) {
      document.body.classList.add('dark-mode');
    }
    super(savedColorMode ?? Theme.LIGHT);
  }

  setTheme(theme: Theme) {
    this.setState(theme);
    if (theme === Theme.DARK) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('savedColorMode', theme);
  }
  getTheme(): Observable<Theme> {
    return this.state$;
  }
}
