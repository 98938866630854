export enum FeatureId {
  PROJECT_DASHBOARD_PAGE = 'PROJECT_DASHBOARD_PAGE',
  PROJECT_DASHBOARD_DETAILS_PAGE = 'PROJECT_DASHBOARD_DETAILS_PAGE',
  APPLICATION_DASHBOARD_PAGE = 'APPLICATION_DASHBOARD_PAGE',
  DEPLOYMENTS_LIST_PAGE = 'DEPLOYMENTS_LIST_PAGE',
  DEVICE_LIST_PAGE = 'DEVICE_LIST_PAGE',
  DEFINE_DATA_DRIVEN_JOBS_PAGE = 'DEFINE_DATA_DRIVEN_JOBS_PAGE',
  SIMULATION_JOB_MONITORING = 'SIMULATION_JOB_MONITORING',
  SIMULATION_JOB_MONITORING_DETAILS = 'SIMULATION_JOB_MONITORING_DETAILS',
  VIRTUAL_DEVELOPMENT_INFRASTRUCTURE = 'VIRTUAL_DEVELOPMENT_INFRASTRUCTURE',
}
