import { Branch, IBranchOrTag, IElement } from './branch';
import { PipelineStatus } from './pipeline';
export interface IVersion {
  versionId: string;
  branchOrTag: IBranchOrTag;
  lastArtifactId: string;
  executionStatus: PipelineStatus;
  lastExecutionDate: string;
}

export interface IDataVersion {
  version_identifier: string;
  branch_or_tag: IElement;
  id_of_last_artifact: string;
  execution_status: PipelineStatus | '';
  last_execution_date: string;
}

export class Version implements IVersion {
  versionId!: string;
  branchOrTag!: IBranchOrTag;
  lastArtifactId!: string;
  executionStatus!: PipelineStatus;
  lastExecutionDate!: string;
  public static Factory(data: IDataVersion): Version {
    const version = new Version();
    version.versionId = data.version_identifier;
    version.branchOrTag = Branch.Factory(data.branch_or_tag);
    version.lastArtifactId = data.id_of_last_artifact;
    version.executionStatus = data.execution_status || PipelineStatus.NOT_FOUND;
    version.lastExecutionDate = data.last_execution_date;
    return version;
  }
}
