<form class="form" [formGroup]="generalInfoForm">
  <div class="content" mat-dialog-content>
    <mat-form-field
      class="caedge-form-field-outline project-name"
      ngDefaultControl
    >
      <mat-label>{{
        "Project.AddProjectDialog.ProjectName" | translate
      }}</mat-label>
      <input
        formControlName="projectName"
        matInput
        maxlength="projectNameMaxLength - this.minCharsWB"
      />
      <mat-hint align="end"
        >{{ projectNameControl.value.length }}/{{
          projectNameMaxLength - this.minCharsWB
        }}</mat-hint
      >
      <mat-hint
        *ngIf="
          !projectNameControl?.invalid &&
          projectNameMaxLength - projectNameControl.value.length >= 0
        "
        align="start"
        >{{ projectNameMaxLength - projectNameControl.value.length }} characters
        left for the workbench name. Please consider this!
      </mat-hint>
      <mat-error *ngIf="projectNameControl?.invalid">
        {{ getErrorMessage(projectNameControl) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Project.AddProjectDialog.ProjectDescription" | translate
      }}</mat-label>
      <textarea
        matInput
        formControlName="projectDescription"
        rows="5"
        maxlength="100"
      >
      </textarea>
      <mat-hint align="end"
        >{{ projectDescriptionControl.value.length }}/100</mat-hint
      >
      <mat-error *ngIf="projectDescriptionControl?.invalid">
        {{ getErrorMessage(projectDescriptionControl) }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
