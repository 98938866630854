import { AwsStage } from '../app/core/models/enums/AwsStage';
import { cognitoConfig } from '../app/core/services/auth/cognitoConfig';
import { AwsOrg } from '../app/core/models/enums/AwsOrg';

export const environment = {
  production: false,
  AWS_STAGE: AwsStage.E2E,
  COGNITO_CONFIG: cognitoConfig[AwsOrg.PROD],
  enableGuard: false,
  TEST_EMAIL: 'uif97034@contiwan.com',
  TEST_USER_NAME: 'uif97034'
};
