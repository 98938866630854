import { ApplicationDashboardComponent } from 'src/app/features/application-dashboard/application-dashboard.component';
import { DeploymentListComponent } from 'src/app/features/deployment-list/deployment-list.component';
import { DeviceListComponent } from 'src/app/features/device-list/device-list.component';
import { ProjectDashboardComponent } from 'src/app/features/project-dashboard/project-dashboard.component';
import { FeatureId } from 'src/app/shared/stores/config/models/featureId';
import { ProjectDashboardDetailsComponent } from '../../../../features/project-dashboard-details/project-dashboard-details.component';
import { VirtualDevelopmentInfrastructureComponent } from '../../../../features/virtual-development-infrastructure/virtual-development-infrastructure.component';
import {
  SimulationJobMonitoringComponent
} from '../../../../features/simulation-job-monitoring/simulation-job-monitoring.component';
import {
  JobDetailsComponent
} from '../../../../features/simulation-job-monitoring/components/job-details/job-details.component';

export const featureComponentMap: Partial<Record<FeatureId, any>> = {
  [FeatureId.PROJECT_DASHBOARD_PAGE]: ProjectDashboardComponent,
  [FeatureId.PROJECT_DASHBOARD_DETAILS_PAGE]: ProjectDashboardDetailsComponent,
  [FeatureId.APPLICATION_DASHBOARD_PAGE]: ApplicationDashboardComponent,
  [FeatureId.DEVICE_LIST_PAGE]: DeviceListComponent,
  [FeatureId.DEPLOYMENTS_LIST_PAGE]: DeploymentListComponent,
  [FeatureId.SIMULATION_JOB_MONITORING]:
  SimulationJobMonitoringComponent,
  [FeatureId.SIMULATION_JOB_MONITORING_DETAILS]: JobDetailsComponent,
  [FeatureId.VIRTUAL_DEVELOPMENT_INFRASTRUCTURE]:
    VirtualDevelopmentInfrastructureComponent,
};
