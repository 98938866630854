<ng-container *ngIf="isLoading; else contentLoaded">
  <div class="container" *ngIf="loadingType === LoadingTypes.BAR">
    <p>&lt;CAEdge&gt;ing some data for you...</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="container" *ngIf="loadingType === LoadingTypes.SPINNER">
    <mat-progress-spinner mode="indeterminate" [diameter]="spinnerDiameter"></mat-progress-spinner>
  </div>
</ng-container>
<ng-template #contentLoaded>
  <ng-container *ngIf="isError; else content">
    <div class="container">
      {{ errorMessage || "Http.Errors.Unknown" | translate }}
    </div>
  </ng-container>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</ng-template>
