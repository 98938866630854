import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  Observable,
  Subject,
  combineLatest,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import {
  DeviceConnectionKey,
  DeviceEnvironmentKey,
  DeviceSortKey,
  DeviceTypeKey,
} from './utils/selectionKeys';
import { DeviceListService } from './services/device-list/device-list.service';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DeviceListItemComponent } from './components/device-list-item/device-list-item.component';
import { DeviceStore } from 'src/app/shared/stores/devices/devices.store';
import { DeviceListApiService } from './services/device-list-api/device-list-api.service';
import { DevicesState } from 'src/app/shared/stores/devices/models/devicesState';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeviceListContentComponent } from './components/device-list-content/device-list-content.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { Option } from 'src/app/core/models/interfaces/option';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { HeaderTemplateComponent } from 'src/app/shared/components/header/header-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { CreateDeviceDialogComponent } from './components/create-device-dialog/create-device-dialog.component';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';

export type DeviceListApi =
  | 'getDevDevices'
  | 'getQaDevices'
  | 'devDeviceActionRequest'
  | 'qaDeviceActionRequest'
  | 'createRealDevice'
  | 'createSimulatedDeviceDev'
  | 'createSimulatedDeviceQa'
  | 'deleteRealDevice'
  | 'deleteSimulatedDeviceDev'
  | 'deleteSimulatedDeviceQa'
  | 'getDevConnectionPackage'
  | 'getQaConnectionPackage'
  | 'getDevFwUpdates'
  | 'getQaFwUpdates'
  | 'updateFwVersionDev'
  | 'updateFwVersionsQa'
  | 'getApplications'
  | 'getAppVersions'
  | 'createDevDeployment'
  | 'createQaDeployment'
  | 'downloadDeviceScriptsDev'
  | 'downloadDeviceScriptsQa'
  | 'createVhpcDevice'
  | 'deleteVhpcDevice';

@Component({
  standalone: true,
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
  imports: [
    TranslateModule,
    SelectMenuComponent,
    FunctionBarComponent,
    CommonModule,
    DeviceListItemComponent,
    ContentWrapperComponent,
    DeviceListContentComponent,
    HeaderTemplateComponent,
    MatButtonModule,
  ],
})
export class DeviceListComponent
  extends FeatureComponent<DeviceListApi>
  implements OnInit, OnDestroy
{
  DeviceType = DeviceType;
  private readonly unsubscribe$: Subject<void> = new Subject();

  createDeviceApiRecords = {
    toolchain: this.API?.createRealDevice,
    vHPC: this.API?.createVhpcDevice,
    [HdkEnvironment.DEVELOPMENT]: this.API?.createSimulatedDeviceDev,
    [HdkEnvironment.QA]: this.API?.createSimulatedDeviceQa,
  };

  searchFilterControl: FormControl = new FormControl('');
  searchFilter$: Observable<string> =
    this.searchFilterControl.valueChanges.pipe(startWith(''));

  filteredDevices$ = combineLatest([
    this.deviceStore.devices$,
    this.searchFilter$,
    this.deviceListService.selectedDeviceType$,
    this.deviceListService.selectedDeviceEnvironment$,
    this.deviceListService.selectedDeviceConnectionStatus$,
    this.deviceListService.selectedSortBy$,
  ]).pipe(map(this.deviceListService.deviceMap));

  devicesData$: Observable<DevicesState>;

  deviceTypeData$ = combineLatest([
    this.deviceListService.deviceTypeOptions$,
    this.deviceListService.selectedDeviceType$,
  ]).pipe(map(([options, selectedOption]) => ({ options, selectedOption })));

  deviceEnvData$ = combineLatest([
    this.deviceListService.deviceEnvironmentOptions$,
    this.deviceListService.selectedDeviceEnvironment$,
  ]).pipe(map(([options, selectedOption]) => ({ options, selectedOption })));

  deviceConnectionStatusData$ = combineLatest([
    this.deviceListService.deviceConnectionStatusOptions$,
    this.deviceListService.selectedDeviceConnectionStatus$,
  ]).pipe(map(([options, selectedOption]) => ({ options, selectedOption })));

  sortByData$ = combineLatest([
    this.deviceListService.sortByOptions$,
    this.deviceListService.selectedSortBy$,
  ]).pipe(map(([options, selectedOption]) => ({ options, selectedOption })));

  constructor(
    private deviceListService: DeviceListService,
    private deviceStore: DeviceStore,
    private deviceListApiService: DeviceListApiService,
    private dialog: MatDialog
  ) {
    super();
    this.devicesData$ = this.combineDevicesData$(
      this.filteredDevices$,
      this.deviceStore.isLoading$,
      this.deviceStore.hasError$
    );
  }
  selectDeviceTypeOption(deviceTypeOption: Option<DeviceTypeKey>): void {
    this.deviceListService.selectedDeviceType(deviceTypeOption);
  }

  selectDeviceEnvironmentOption(
    deviceEnvOption: Option<DeviceEnvironmentKey>
  ): void {
    this.deviceListService.setSelectedDeviceEnvironment(deviceEnvOption);
  }

  selectDeviceConnectionStatusOption(
    deviceConnectionStatus: Option<DeviceConnectionKey>
  ): void {
    this.deviceListService.setSelectedDeviceConnectionStatus(
      deviceConnectionStatus
    );
  }

  selectSortOption(option: Option<DeviceSortKey>): void {
    this.deviceListService.setSelectedSortBy(option);
  }

  combineDevicesData$(
    devices: Observable<Device[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>
  ): Observable<DevicesState> {
    return combineLatest([devices, isLoading, hasError]).pipe(
      map(([devices, isLoading, hasError]) => {
        return { devices, isLoading, hasError };
      })
    );
  }

  fetchDeviceList() {
    if (this.API) {
      this.deviceListApiService
        .getDevices(this.API.getDevDevices, this.API.getQaDevices)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    }
  }

  openCreateDeviceDialog(deviceType: DeviceType) {
    this.dialog.open(CreateDeviceDialogComponent, {
      data: {
        deviceType: deviceType,
        apiRecords: this.createDeviceApiRecords,
        unsubscribe$: this.unsubscribe$,
      },
      width: '400px',
      autoFocus: false,
      disableClose: true,
    });
  }

  checkActiveFilters() {
    return this.deviceListService.checkActiveFilters();
  }

  handleResetFilters() {
    this.deviceListService.clearActiveFilters();
  }

  ngOnInit(): void {
    this.fetchDeviceList();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
