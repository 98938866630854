import { AwsStage } from '../app/core/models/enums/AwsStage';
import { cognitoConfig } from '../app/core/services/auth/cognitoConfig';
import { AwsOrg } from '../app/core/models/enums/AwsOrg';

export const environment = {
  production: false,
  AWS_STAGE: AwsStage.DEMO,
  COGNITO_CONFIG: cognitoConfig[AwsOrg.PROD],
  enableGuard: true,
};
