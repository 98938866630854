<mat-expansion-panel
  class="deployment mat-elevation-z0"
  (opened)="loadDeviceData()"
  [expanded]="expandListItem(deployments.dev, deployments.qa)"
  *ngIf="version"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="version-id-col col">
        <p>VERSION: {{ version.branchOrTag.elementName }}</p>
        <p>
          ID:
          {{ version.versionId }}
        </p>
      </div>
      <ng-container>
        <div class="pipeline-status-col col">
          <app-chip
            [label]="getPipelineStatus(version.executionStatus)"
            [variant]="getEnvironmentStatus(version.executionStatus)"
          ></app-chip>
          <div
            class="pipeline-actions"
            *ngIf="version.executionStatus !== pipelineStatus.NOT_FOUND"
          >
            <button
              *ngIf="
                version.executionStatus === pipelineStatus.PIPELINE_BUILDING
              "
              mat-icon-button
              [matTooltip]="'Deployments.StopBuild' | translate"
              [attr.aria-label]="'Deployments.StopBuild' | translate"
              (click)="pipelineBuildRequest($event, 'false')"
            >
              <mat-icon>stop</mat-icon>
            </button>
            <button
              *ngIf="
                version.executionStatus ===
                  pipelineStatus.PIPELINE_BUILD_SUCCESS ||
                version.executionStatus === pipelineStatus.PIPELINE_BUILD_FAILED
              "
              mat-icon-button
              [matTooltip]="'Deployments.RebuildPipeline' | translate"
              [attr.aria-label]="'Deployments.RebuildPipeline' | translate"
              (click)="pipelineBuildRequest($event, 'true')"
            >
              <mat-icon>rotate_left</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="actions-col col">
          <div class="start-deployment-col">
            <button
              class="add-deployment"
              mat-flat-button
              (click)="startDeploymentClick($event)"
              [disabled]="
                disableDeployment(
                  version.executionStatus,
                  version.executionStatus
                )
              "
            >
              <mat-icon>add</mat-icon>
              {{ "HDK.NewDeployment" | translate }}
            </button>
          </div>
        </div>
        <div class="menu-col col">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [attr.aria-label]="'HDK.MenuTriggerButton' | translate"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="caedge-menu" #menu="matMenu">
            <button
              class="caedge-menu-item"
              mat-menu-item
              [disabled]="versionHasDeployments()"
              (click)="openDeleteVersionDialog()"
            >
              <span>{{
                "Deployments.DeleteVersionDialog.Confirm" | translate
              }}</span>
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container>
    <div
      class="expansion-content"
      *ngIf="onDeployedDevice | async as deviceData"
    >
      <app-content-wrapper
        class="deployment-container"
        [isError]="deviceData.hasError"
        [isLoading]="deviceData.isLoading"
        [errorMessage]="'DeploymentList.DeploymentListError' | translate"
      >
        <div *ngIf="deviceData.data.length > 0; else noDevices">
          <app-list-item
            *ngFor="let hardware of deviceData.data; trackBy: trackByName"
            [device]="hardware"
            [deployments]="deployments"
            [version]="version"
            [appName]="appName"
            class="list-wrapper"
          ></app-list-item>
        </div>
        <ng-template #noDevices>
          <p class="no-devices">
            {{ "Deployments.StartYourDeployment" | translate }}
          </p>
        </ng-template>
      </app-content-wrapper>
    </div>
  </ng-container>
</mat-expansion-panel>
