<div class="dialog-header">
  <h2 mat-dialog-title>{{ "Jobs.Step.Title" | translate }}</h2>
</div>

<div mat-dialog-content class="content">
  <ng-container *ngIf="!loading else loadingBar">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <!-- Index Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#Index</th>
        <td mat-cell *matCellDef="let element">{{childJobIndex}}</td>
      </ng-container>

      <!-- Step Column -->
      <ng-container matColumnDef="step">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Step</th>
        <td mat-cell *matCellDef="let element">{{ element.Step}}</td>
      </ng-container>

      <!-- Exit Code Column -->
      <ng-container matColumnDef="exitCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>App exit code</th>
        <td mat-cell *matCellDef="let element">
          {{ element.AppExitCode ? element.AppExitCode : '-' }}
        </td>
      </ng-container>

      <!-- Sil Factor Column -->
      <ng-container matColumnDef="silFactor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>App SIL factor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.AppSILFactor ? element.AppSILFactor : '-' }}
        </td>
      </ng-container>

      <!-- Run Time Ratio Column -->
      <ng-container matColumnDef="runTimeRatio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>App run time ratio</th>
        <td mat-cell *matCellDef="let element">{{ element.AppRunTimeRatio ? element.AppRunTimeRatio : '-' }}
        </td>
      </ng-container>

      <!-- Slim Exit Code Column -->
      <ng-container matColumnDef="slimExitCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Slim exit code</th>
        <td mat-cell
            *matCellDef="let element">{{ element.SimulationExitCode ? element.SimulationExitCode : '-' }}</td>
      </ng-container>

      <!-- Export Step Logs Column -->
      <ng-container matColumnDef="export">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!isRequestLoading else requestSpinner">
            <mat-icon class="export-btn" (click)="handleExportClick(element)">file_download</mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No step found</td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>

  </ng-container>

  <ng-template #loadingBar>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>

  <ng-template #requestSpinner>
    <mat-progress-spinner mode="indeterminate" [diameter]="20"></mat-progress-spinner>
  </ng-template>
</div>

<div mat-dialog-actions [align]="'end'" class="actions">
  <button
    class="close-btn"
    mat-flat-button
    [mat-dialog-close]="true"
  >
    {{ "General.Close" | translate }}
  </button>

</div>

