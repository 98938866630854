<div class="root">
  <div class="sticky-header">
    <app-header-template
      [showBackButton]="false"
      itemName="Versions and deployments"
      ><div class="buttons">
        <button
          data-cy="add-version-button"
          mat-flat-button
          class="add-version-btn"
          aria-label="Add version"
          (click)="openAddVersionDialog()"
        >
          {{ "Versions.AddNew" | translate }}
        </button>
        <button
          data-cy="add-deployment-button"
          mat-flat-button
          class="add-version-btn"
          aria-label="Start deployment"
          (click)="eventEmitter.emit()"
        >
          {{ "HDK.StartDeployment" | translate }}
        </button>
      </div></app-header-template
    >
    <app-function-bar
      class="search-bar"
      [searchPlaceholder]="'Deployments.Search'"
      [searchFilterControl]="searchFilterControl"
    >
    </app-function-bar>
  </div>
  <ng-container *ngIf="deployment$ | async as deployment">
    <app-content-wrapper
      data-cy="deployment-list-container"
      [isError]="deployment.hasError"
      [isLoading]="deployment.isLoading"
      [errorMessage]="'DeviceList.DeviceListError' | translate"
    >
      <app-deployment-list-content
        *ngIf="appName$ | async as appName"
        [appName]="appName"
        [versions]="deployment.data"
      ></app-deployment-list-content>
    </app-content-wrapper>
  </ng-container>
</div>
