<div
  class="chip"
  [ngClass]="{
    success: variant === 'success',
    error: variant === 'error',
    warn: variant === 'warn',
    info: variant === 'info',
    neutralWhite: variant === 'neutral',
    neutralGrey: variant === 'neutral-grey',
    disabled: variant === 'disabled',
    fwError: variant === 'fwError',
    icon: icon !== ''
  }"
>


<span>{{ label }}</span>
  <mat-icon *ngIf="icon !== ''" iconPositionEnd>
    {{icon}}
  </mat-icon>
</div>
