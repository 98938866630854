<div>
  <h2 mat-dialog-title>{{ data.title }}</h2>
</div>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
  <p class="secondary-text" *ngIf="data.secondaryMessage">
    {{ data.secondaryMessage }}
  </p>
</div>
<div mat-dialog-actions [align]="data.alignButtons || 'end'">
  <button mat-flat-button [mat-dialog-close]="false">
    {{ data.closeText || ("General.Cancel" | translate) }}
  </button>
  <button
    data-cy="dialog-confirm-button"
    class="dialog-confirm-btn"
    *ngIf="data.type === DialogType.CONFIRM"
    mat-flat-button
    [mat-dialog-close]="true"
    (click)="data.onConfirm ? data.onConfirm() : undefined"
  >
    {{ data.confirmText || ("General.Confirm" | translate) }}
  </button>
</div>
