export interface IState<T> {
  data: T;
  isLoading: boolean;
  hasError: boolean;
}

export class State<T> {
  data!: T;
  isLoading!: boolean;
  hasError!: boolean;

  public static Factory<T>(data: T) {
    const state = new State<T>();
    state.data = data;
    state.hasError = false;
    state.isLoading = false;
    return state;
  }
}
