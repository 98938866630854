import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { DefaultFilters } from 'src/app/core/services/default/defaultFilters';
import { Option } from 'src/app/core/models/interfaces/option';
import {
  DEVICE_CONNECTION_KEYS,
  DEVICE_ENVIRONMENT_KEYS,
  DEVICE_SORT_KEYS,
  DEVICE_TYPE_KEYS,
  DeviceConnectionKey,
  DeviceEnvironmentKey,
  DeviceSortKey,
  DeviceTypeKey,
  filterKeyToDevicePropertyMap,
} from '../../utils/selectionKeys';
import { Device } from 'src/app/shared/stores/devices/models/device/device';

type DeviceListSelectionData = {
  deviceType: Option<DeviceTypeKey>;
  deviceEnv: Option<DeviceEnvironmentKey>;
  deviceConnectionStatus: Option<DeviceConnectionKey>;
  sortBy: Option<DeviceSortKey>;
};

@Injectable({
  providedIn: 'root',
})
export class DeviceListService implements DefaultFilters {
  constructor(private translate: TranslateService) {}

  defaultFilterValues(): DeviceListSelectionData {
    return {
      deviceType: {
        id: DEVICE_TYPE_KEYS[0],
        label: this.translate.instant(DEVICE_TYPE_KEYS[0]),
      },
      deviceEnv: {
        id: DEVICE_ENVIRONMENT_KEYS[0],
        label: this.translate.instant(DEVICE_ENVIRONMENT_KEYS[0]),
      },
      deviceConnectionStatus: {
        id: DEVICE_CONNECTION_KEYS[0],
        label: this.translate.instant(DEVICE_CONNECTION_KEYS[0]),
      },
      sortBy: {
        id: DEVICE_SORT_KEYS[0],
        label: this.translate.instant(DEVICE_SORT_KEYS[0]),
      },
    };
  }

  deviceMap = ([
    devices,
    searchFilter,
    typeFilter,
    envFilter,
    connectionFilter,
    sortByOption,
  ]: [
    Device[],
    string,
    Option<DeviceTypeKey>,
    Option<DeviceEnvironmentKey>,
    Option<DeviceConnectionKey>,
    Option<DeviceSortKey>
  ]) => {
    return devices
      .filter((device) => {
        return (
          device.hwName.toLowerCase().includes(searchFilter.toLowerCase()) &&
          (typeFilter.id === 'DeviceList.FilterKeys.Type.All' ||
            device.instanceType ===
              filterKeyToDevicePropertyMap[typeFilter.id]) &&
          (envFilter.id === 'DeviceList.FilterKeys.Environment.All' ||
            device.environments.includes(
              filterKeyToDevicePropertyMap[envFilter.id]
            )) &&
          (connectionFilter.id ===
            'DeviceList.FilterKeys.ConnectionStatus.All' ||
            device.instanceConnectionStatus ===
              filterKeyToDevicePropertyMap[connectionFilter.id])
        );
      })
      .sort(this.deviceSort(sortByOption.id));
  };

  deviceSort = (sortBy: DeviceSortKey): ((a: Device, b: Device) => number) => {
    switch (sortBy) {
      case 'SortKeys.Alphabetical':
        return (a: Device, b: Device) => a.hwName.localeCompare(b.hwName, 'en');
      case 'SortKeys.Date':
        return (a: Device, b: Device) => {
          const aDate = new Date(a.creationDate).getTime();
          const bDate = new Date(b.creationDate).getTime();
          return aDate - bDate;
        };
    }
  };

  getOptions<T extends string>(
    keys: readonly T[],
    optionKey: keyof DeviceListSelectionData,
    setSelectedOption: (option: Option<T>) => void
  ): Observable<Option<T>[]> {
    return this.translate.stream([...keys]).pipe(
      map((translations: Record<T, string>) =>
        Object.entries(translations as Record<string, string>).map(
          ([sortKey, translation]: [string, string]): Option<T> => ({
            id: sortKey as T,
            label: translation,
          })
        )
      ),
      tap((options) => {
        const selectedOption = this.selectMenusSource.value[optionKey];
        setSelectedOption(
          options.find((option) => option.id === selectedOption.id)!
        );
      })
    );
  }

  get deviceTypeOptions$(): Observable<Option<DeviceTypeKey>[]> {
    return this.getOptions(
      DEVICE_TYPE_KEYS,
      'deviceType',
      this.selectedDeviceType
    );
  }

  get deviceEnvironmentOptions$(): Observable<Option<DeviceEnvironmentKey>[]> {
    return this.getOptions(
      DEVICE_ENVIRONMENT_KEYS,
      'deviceEnv',
      this.setSelectedDeviceEnvironment
    );
  }

  get deviceConnectionStatusOptions$(): Observable<
    Option<DeviceConnectionKey>[]
  > {
    return this.getOptions(
      DEVICE_CONNECTION_KEYS,
      'deviceConnectionStatus',
      this.setSelectedDeviceConnectionStatus
    );
  }

  get sortByOptions$(): Observable<Option<DeviceSortKey>[]> {
    return this.getOptions(DEVICE_SORT_KEYS, 'sortBy', this.setSelectedSortBy);
  }

  private selectMenusSource: BehaviorSubject<DeviceListSelectionData> =
    new BehaviorSubject<DeviceListSelectionData>(this.defaultFilterValues());

  get selectedDeviceType$(): Observable<Option<DeviceTypeKey>> {
    return this.selectMenusSource.pipe(map((value) => value.deviceType));
  }
  selectedDeviceType = (deviceType: Option<DeviceTypeKey>) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      deviceType,
    });
  };

  get selectedDeviceEnvironment$(): Observable<Option<DeviceEnvironmentKey>> {
    return this.selectMenusSource.pipe(map((value) => value.deviceEnv));
  }
  setSelectedDeviceEnvironment = (deviceEnv: Option<DeviceEnvironmentKey>) => {
    this.selectMenusSource.next({ ...this.selectMenusSource.value, deviceEnv });
  };

  get selectedDeviceConnectionStatus$(): Observable<
    Option<DeviceConnectionKey>
  > {
    return this.selectMenusSource.pipe(
      map((value) => value.deviceConnectionStatus)
    );
  }
  setSelectedDeviceConnectionStatus = (
    deviceConnectionStatus: Option<DeviceConnectionKey>
  ) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      deviceConnectionStatus,
    });
  };

  get selectedSortBy$(): Observable<Option<DeviceSortKey>> {
    return this.selectMenusSource.pipe(map((value) => value.sortBy));
  }
  setSelectedSortBy = (sortBy: Option<DeviceSortKey>) => {
    this.selectMenusSource.next({ ...this.selectMenusSource.value, sortBy });
  };

  checkActiveFilters() {
    const currentState = this.selectMenusSource.getValue();
    const defaultState = this.defaultFilterValues();

    return (
      currentState.deviceType.id !== defaultState.deviceType.id ||
      currentState.deviceEnv.id !== defaultState.deviceEnv.id ||
      currentState.deviceConnectionStatus.id !==
        defaultState.deviceConnectionStatus.id ||
      currentState.sortBy.id !== defaultState.sortBy.id
    );
  }

  clearActiveFilters() {
    this.selectMenusSource.next(this.defaultFilterValues());
  }
}
