import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap, of } from 'rxjs';
import { ApplicationsStore } from 'src/app/shared/stores/applications/applications.store';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { ApiService } from 'src/app/core/services/api/api.service';
import {
  Application,
  IDataApplication,
} from 'src/app/shared/stores/deployment/models/application';
import { generatePath } from 'src/app/shared/utils/generatePath';
import { CreateAppRequestBody } from '../../add-application-dialog/models/createAppRequestBody';

@Injectable({
  providedIn: 'root',
})
export class ApplicationListApiService {
  constructor(
    private applicationStore: ApplicationsStore,
    private apiService: ApiService
  ) { }

  createApplication(
    createApplicationApiRecord: ApiRecord,
    requestBody: CreateAppRequestBody
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: createApplicationApiRecord,
      body: requestBody
    });
  }

  getApplications(applicationApiRecord: ApiRecord): Observable<Application[]> {
    return this.apiService
      .request<IDataApplication[]>({ apiRecord: applicationApiRecord })
      .pipe(
        map((applications) => {
          return applications.map((application) => {
            return Application.Factory(application);
          });
        }),
        tap((applications) => {
          this.applicationStore.setState({
            applications,
            isLoading: false,
            hasError: false,
          });
        }),
        catchError(() => {
          this.applicationStore.setState({
            applications: [],
            isLoading: false,
            hasError: true,
          });

          return of([]);
        })
      );
  }

  deleteApplication(
    apiRecord: ApiRecord,
    appName: string
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName }),
      },
    });
  }

  getAppDetails(
    apiRecord: ApiRecord,
    appName: string
  ): Observable<Application> {
    return this.apiService
      .request<IDataApplication>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { appName }),
        },
      })
      .pipe(map((application) => Application.Factory(application)));
  }
}
