<div class="dialog-header">
  <h2 mat-dialog-title>{{ "Versions.AddNew" | translate }}</h2>
  <h2 mat-dialog-title>{{ "Versions.AddNew" | translate }}</h2>
</div>
<div data-cy="add-version-dialog" mat-dialog-content class="content">
  <ng-container *ngIf="addVersionStore.state$ | async as applicationData">
    <app-content-wrapper
      [isError]="applicationData.hasError"
      [isLoading]="applicationData.isLoading"
    >
      <p data-cy="version-type-title">
        {{
          (isConanApplication
            ? "Versions.EnterArtifactory"
            : "Versions.EnterGitHub"
          ) | translate
        }}:
      </p>
      <div class="inputs" [formGroup]="addVersionFormGroup">
        <mat-form-field class="caedge-form-field-fill">
          <mat-label>{{
            (isConanApplication
              ? "Versions.PackageVersion"
              : "Versions.BranchName"
            ) | translate
          }}</mat-label>
          <input
            data-cy="branch-name-version-dialog"
            formControlName="source"
            [errorStateMatcher]="matcher"
            matInput
          />
          <mat-error
            data-cy="version-source-caedge-name-error"
            *ngIf="addVersionFormGroup.get('source')?.hasError('startsWithCaedge')"
          >
            {{ 'General.CaedgeNotPermitted' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="caedge-form-field-fill">
          <mat-label>{{ "Versions.VersionID" | translate }}</mat-label>
          <input
            data-cy="version-id-version-dialog"
            formControlName="versionId"
            matInput
            maxlength="15"
            [errorStateMatcher]="matcher"
          />
          <mat-hint align="start">{{
            "Versions.VersionIDHint" | translate
            }}</mat-hint>
          <mat-hint align="end">{{ versionId.length }}/15</mat-hint>
          <mat-error
            data-cy="version-id-caedge-name-error"
            *ngIf="addVersionFormGroup.get('versionId')?.hasError('startsWithCaedge')"
          >
            {{ 'General.CaedgeNotPermitted' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <p class="build-pipeline-checkbox">
        <mat-checkbox color="primary" [disabled]="true" checked>{{
          "Versions.BuildPipeline" | translate
        }}</mat-checkbox>
      </p>
    </app-content-wrapper>
  </ng-container>
</div>
<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-version-button"
    mat-flat-button
    [mat-dialog-close]="false"
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    data-cy="submit-version-button"
    mat-flat-button
    [mat-dialog-close]="true"
    [disabled]="!canSubmit()"
    (click)="handleSubmitClick()"
  >
    {{ "Versions.SubmitVersion" | translate }}
  </button>
</div>
