import { AbstractControl } from '@angular/forms';

export function forbidCaedgeNaming(control: AbstractControl): { [key: string]: boolean } | null {

  const inputValue = control.value as string;
  const forbiddenValue = 'caedge';

  if(inputValue.startsWith(forbiddenValue)) {
    return { startsWithCaedge: true };
  }
  return null;
}
