import {Component, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderTemplateComponent} from '../../shared/components/header/header-template.component';
import {MatCardModule} from '@angular/material/card';
import {
  ActionCommand,
  Actions,
  CaeCardComponent,
} from '../../shared/components/cae-card/cae-card.component';
import {VdiRequestComponent} from './components/vdi-request/vdi-request.component';
import {FeatureComponent} from '../../core/models/classes/feature.component';
import {VdiRequestService} from './services/vdi-request.service';
import {VdiConfig} from './models/vdi-config';
import {
  actionsDelete,
  actionsRequest,
  actionsConfigs,
} from './models/action-buttons';
import {DisplayDetailComponent} from '../application-dashboard/components/application-details/display-detail.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CaeButtonComponent} from '../../shared/components/cae-button/cae-button.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateModule} from '@ngx-translate/core';
import {FileService} from '../../core/services/file/file.service';
import {interval, Subscription} from 'rxjs';
import {SnackbarService} from '../../core/services/snackbar/snackbar.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatStepperModule} from '@angular/material/stepper';
import {VdiInstance} from './models/vdi-instance';

export type VirtualDevelopmentInfrastructureApi =
  | 'getVDIConfigs'
  | 'downloadMatchboxScript'
  | 'getVDInstances'
  | 'delVDInstance'
  | 'putVDInstance';

@Component({
  selector: 'app-virtual-development-infrastructure',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    MatCardModule,
    CaeCardComponent,
    VdiRequestComponent,
    DisplayDetailComponent,
    MatProgressSpinnerModule,
    MatStepperModule,
    CaeButtonComponent,
    MatSnackBarModule,
    TranslateModule,
  ],
  templateUrl: './virtual-development-infrastructure.component.html',
  styleUrls: ['./virtual-development-infrastructure.component.scss'],
})
export class VirtualDevelopmentInfrastructureComponent extends FeatureComponent<VirtualDevelopmentInfrastructureApi> {
  requestAction: Actions[] = [actionsRequest];
  deleteAction: Actions[] = [actionsDelete];
  actionsConfigs = actionsConfigs;
  protected readonly ActionCommand = ActionCommand;
  loadingInstance = signal<boolean>(false);
  loadingConfig = signal<boolean>(false);
  vdiInstance = signal<VdiInstance | undefined>(undefined);

  vdiConfig = signal<VdiConfig | undefined>(undefined);
  projectName = '';
  private readonly poolingTime = 1500;

  constructor(
    private vdiRequestService: VdiRequestService,
    private clipboard: Clipboard,
    private fileService: FileService,
    private snackbarService: SnackbarService
  ) {
    super();
    const urlPieces = window.location.href.split('/');
    this.projectName = urlPieces[urlPieces.length - 1];
    this.resolveAll();
  }

  private resolveAll() {
    Promise.all([this.getVdiInstances(false), this.getVdiConfigs()]).then();
  }

  handleEvent(command: ActionCommand) {
    switch (command) {
      case ActionCommand.REQUEST:
        this.putVdiInstance();
        break;
      case ActionCommand.DELETE:
        this.deleteVdiInstance();
        break;
      case ActionCommand.DOWNLOAD:
        this.downloadScript();
        break;
      case ActionCommand.REFRESH:
        this.getVdiConfigs();
        break;
      case ActionCommand.COPY:
        this.copyToClipboard();
        break;
    }
  }

  private copyToClipboard() {
    const copy = this.vdiConfig()?.elasticBeanstalkHostedUrl;
    if (!copy) return;
    this.clipboard.copy(copy);
    this.snackbarService.notifyInfo('Copied');
  }

  async getVdiInstances(isWaitingForRequest: boolean): Promise<void> {
    try {
      if (!this.API) return;
      this.loadingInstance.set(true);
      const request = await this.vdiRequestService.getVdiInstance(
        this.API.getVDInstances
      );
      this.vdiInstance.set(request);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 202) {
        this.vdiInstance.set(undefined);
      } else if (error instanceof HttpErrorResponse && error.status !== 202 && error.status !== 203) {
        this.vdiInstance.set(undefined);
        this.snackbarService.notifyError('Get VDI Instances failed!');
      }
    } finally {
      if (!isWaitingForRequest) {
        this.loadingInstance.set(false);
      }
    }
  }

  async getVdiConfigs(): Promise<void> {
    try {
      if (!this.API) return;
      this.loadingConfig.set(true);
      const request = await this.vdiRequestService.getVdiConfig(
        this.API.getVDIConfigs
      );
      this.vdiConfig.set(request);
    } catch (err) {
      this.snackbarService.notifyError('Get VDI configs failed!');
    } finally {
      this.loadingConfig.set(false);
    }
  }

  async deleteVdiInstance() {
    try {
      if (!this.API) return;
      this.loadingInstance.set(true);
      await this.vdiRequestService.deleteVdiInstance(this.API.delVDInstance);
      await this.getVdiInstances(false);
    } catch (err) {
      this.snackbarService.notifyError('Delete VDI instance failed!');
    } finally {
      this.loadingInstance.set(false);
    }
  }

  async putVdiInstance() {
    try {
      if (!this.API) return;
      this.loadingInstance.set(true);
      await this.vdiRequestService.putVdiInstance(this.API.putVDInstance, this.projectName);
      await this.waitForInstance();
    } catch (err: any) {
      this.snackbarService.notifyError('Request VDI instance failed!');
      this.loadingInstance.set(false);
      this.vdiInstance.set(undefined);
    }
  }

  private async waitForInstance() {
    this.loadingInstance.set(true);
    let requestIntervalSubscription: Subscription;
    const source = interval(this.poolingTime);
    requestIntervalSubscription = source.subscribe(async () => {
      await this.getVdiInstances(true);
      if (this.vdiInstance() !== undefined) {
        requestIntervalSubscription.unsubscribe();
        this.loadingInstance.set(false);
      }
    });
  }

  private async downloadScript() {
    try {
      if (!this.API) return;
      const filename = `caedge_vdi_script_${this.projectName}.ps1`;
      const blob = await this.vdiRequestService
        .downloadScript(this.API.downloadMatchboxScript);
      this.fileService.downloadBlob(blob, filename);
      this.snackbarService.notifyInfo('Script downloaded');
    } catch (err) {
      this.snackbarService.notifyError('Download script failed!');
    }
  }
}
