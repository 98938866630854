<mat-form-field class="caedge-form-field-fill">
  <div class="search">
    <mat-icon
      (click)="
        $event.stopPropagation(); dataSource.filter = ''; input.value = ''
      "
      data-cy="clear-search-icon"
      aria-label="search and clear"
    >
      {{ input.value.length > 0 ? "close" : "search" }}
    </mat-icon>
    <input
      matInput
      data-cy="jobs-search-input"
      (keyup)="applyFilter($event)"
      placeholder="Search..."
      #input
    />
  </div>
</mat-form-field>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th class="table-header" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Run ID Column -->
    <ng-container matColumnDef="RunID">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Run Id"
        class="table-header"
      >
        # Run ID
      </th>
      <td data-cy="job-run-id" mat-cell *matCellDef="let element">
        {{ element.RunID }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="WorkflowName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
      >
        Workflow name
      </th>
      <td mat-cell *matCellDef="let element" class="grey-text">
        {{ element.WorkflowName }}
      </td>
    </ng-container>

    <!-- Caller Column -->
    <ng-container matColumnDef="Caller">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
      >
        Caller
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Caller }}
      </td>
    </ng-container>

    <!-- Submission date Column -->
    <ng-container matColumnDef="submissionDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
      >
        Submission date
      </th>
    </ng-container>
    <ng-container matColumnDef="SubmissionDateTime">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
      >
        Submission date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.SubmissionDateTime | customFormatDate }}
      </td>
    </ng-container>

    <!-- Start date Column -->
    <ng-container matColumnDef="StartDateTime">
      <th mat-header-cell *matHeaderCellDef class="table-header">Start date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.StartDateTime | customFormatDate }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
      >
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <span
          data-cy="job-status"
          class="chip {{ element.Status.toLowerCase() }}"
          >{{ element.Status }}</span
        >
      </td>
    </ng-container>

    <!-- Details Button Column -->
    <ng-container matColumnDef="link">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
      ></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon (click)="navigateToDetailsPage(element.RunID)"
          >navigate_next
        </mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" data-cy="jobs-no-data" colspan="4">No data found</td>
    </tr>
  </table>
</div>
<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  aria-label="Select page of periodic elements"
>
</mat-paginator>