import { DeviceConnectionStatus } from './deviceConnectionStatus';
import { DeviceInstanceStatus } from './deviceInstanceStatus';

export const deviceStatusLabelMap = {
  [DeviceConnectionStatus.CONNECTED]: 'Connected',
  [DeviceConnectionStatus.DISCONNECTED]: 'Disconnected',
  [DeviceConnectionStatus.CONNECTING]: 'Connecting',
  [DeviceInstanceStatus.CREATE_REQUESTED]: 'Creating',
  [DeviceInstanceStatus.CREATE_IN_PROGRESS]: 'Creating',
  [DeviceInstanceStatus.CREATION_FAILED]: 'Failed',
  [DeviceInstanceStatus.CREATE_FAILED]: 'Failed',
  [DeviceInstanceStatus.DELETE_IN_PROGRESS]: 'Deleting',
};
