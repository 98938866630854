import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { ApplicationListApiService } from 'src/app/features/project-dashboard/components/application-list/services/application-list-api.service';
import { CommonModule } from '@angular/common';
import { AddVersionStore } from 'src/app/shared/stores/deployment/add-version.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { GenericStateMatcher } from 'src/app/shared/utils/genericStateMatcher';
import { DeploymentService } from 'src/app/core/services/deployment-list/deployment.service';
import { forbidCaedgeNaming } from 'src/app/shared/utils/caedge-name-validator/caedge-name-validator';

export interface AddVersionDialogData {
  appName: string;
  addVersionApiConfig: ApiRecord;
  getAppDetailsApiConfig: ApiRecord;
  unsubscribe$: Subject<void>;
}

@Component({
  selector: 'app-add-version-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    ReactiveFormsModule,
    ContentWrapperComponent,
  ],
  templateUrl: './add-version-dialog.component.html',
  styleUrls: ['./add-version-dialog.component.scss'],
})
export class AddVersionDialogComponent implements OnInit {
  addVersionFormGroup: FormGroup = new FormGroup({
    source: new FormControl('', [
      Validators.required,
      forbidCaedgeNaming,
    ]),
    versionId: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z][a-z0-9]{0,24}$/),
      forbidCaedgeNaming,
    ]),
  });

  isConanApplication: boolean = true;

  matcher = new GenericStateMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddVersionDialogData,
    public addVersionStore: AddVersionStore,
    private deploymentService: DeploymentService,
    private applicationListApi: ApplicationListApiService,
    private snackbarService: SnackbarService
  ) {}

  get source(): string {
    return this.addVersionFormGroup.get('source')?.value;
  }

  get versionId(): string {
    return this.addVersionFormGroup.get('versionId')?.value;
  }

  private isConan(application: Application) {
    this.isConanApplication =
      application.appType === ApplicationType.QNX ||
      application.appType === ApplicationType.CLASSIC_AUTOSAR_IMAGE ||
      application.appType === ApplicationType.ANDROID
    ;
  }

  canSubmit() {
    return this.addVersionFormGroup.valid;
  }

  handleSubmitClick() {
    this.deploymentService
      .addVersion(
        this.data.appName,
        this.versionId,
        this.source,
        this.data.addVersionApiConfig
      )
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: () =>
          this.snackbarService.notifySuccess('Version created successfully!'),
        error: () =>
          this.snackbarService.notifyError('Version creation failed!'),
      });
  }

  fetchAppDetails() {
    this.applicationListApi
      .getAppDetails(this.data.getAppDetailsApiConfig, this.data.appName)
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe((application) => {
        this.addVersionStore.setState({
          data: application,
          isLoading: false,
          hasError: false,
        });
        this.isConan(application);
      });
  }

  ngOnInit(): void {
    this.fetchAppDetails();
  }
}
