import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ListItemComponent } from '../list-item/list-item.component';
import { Version } from '../../../../shared/stores/deployment/models/version';
import { DeploymentService } from '../../../../core/services/deployment-list/deployment.service';
import { PipelineStatus } from '../../../../shared/stores/deployment/models/pipeline';
import {
  ChipComponent,
  ChipVariant,
} from '../../../../shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';
import { DeploymentListApi } from '../../deployment-list.component';
import { DeviceListApiService } from '../../../device-list/services/device-list-api/device-list-api.service';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import { generatePath } from '../../../../shared/utils/generatePath';
import { Deployment } from '../../../../shared/stores/deployment/models/deployment';
import { HdkEnvironment } from '../../../../core/models/interfaces/hdkEnvironment';
import { Method } from '../../../../shared/stores/config/models/method';
import {
  Device,
  IDataDevice,
} from '../../../../shared/stores/devices/models/device/device';
import { ContentWrapperComponent } from '../../../../shared/components/content-wrapper/content-wrapper.component';
import { State } from '../../../../shared/stores/State';
import { DeploymentsStore } from '../../../../shared/stores/deployment/deployments.store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { StartDeploymentService } from 'src/app/core/services/start-deployment/start-deployment.service';

export interface IAllEnvironment<T> {
  dev: T;
  qa: T;
}

@Component({
  selector: 'app-deployment-list-expansion-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    ListItemComponent,
    ChipComponent,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    ContentWrapperComponent,
  ],
  templateUrl: './deployment-list-expansion-panel.component.html',
  styleUrls: ['./deployment-list-expansion-panel.component.scss'],
})
export class DeploymentListExpansionPanelComponent
  extends FeatureComponent<DeploymentListApi>
  implements OnChanges, OnDestroy
{
  @Input() version!: Version;
  @Input() appName: string = '';
  @Input() appType: string = '';

  onDeployedDevice: BehaviorSubject<State<Device[]>>;
  env = HdkEnvironment;
  pipelineStatus = PipelineStatus;

  unsubscribe$: Subject<void>;
  deployments: IAllEnvironment<Deployment[]> = {
    dev: [],
    qa: [],
  };

  constructor(
    private deviceListApiService: DeviceListApiService,
    private deploymentService: DeploymentService,
    private deploymentsStore: DeploymentsStore,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private startDeploymentService: StartDeploymentService,
  ) {
    super();
    this.unsubscribe$ = new Subject<void>();
    this.onDeployedDevice = new BehaviorSubject<State<Device[]>>({
      data: [],
      isLoading: true,
      hasError: false,
    });

  }

  fetchData(version: Version, appName: string) {
    if (version && appName && this.API) {
      this.deploymentService
        .getAllDeployments(
          appName,
          version.versionId,
          this.API.getDeploymentDev,
          this.API.getDeploymentQa
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.deploymentsStore.setState({
            data: response,
            isLoading: false,
            hasError: false,
          });
          this.deployments = response;
          this.loadDeviceData();
        });
    }
  }

  versionHasDeployments() {
    return [...this.deployments.dev, ...this.deployments.qa].some(
      (deployment) => deployment.versionId === this.version.versionId
    );
  }

  getPipelineStatus(status: PipelineStatus): string {
    const translationKey = `Deployments.PipeLineStatus.${status}`;
    const translation = this.translate.instant(translationKey);
    return `Pipeline ${translation}`;
  }

  extractHardwareName(deployments: Deployment[]): string[] {
    return deployments?.map((data) => data.hwTarget);
  }

  loadDeviceData() {
    const devDeviceList: string[] = this.extractHardwareName(
      this.deployments.dev
    );
    const qaDeviceList: string[] = this.extractHardwareName(
      this.deployments.qa
    );

    combineLatest([
      this.getDevices(devDeviceList, HdkEnvironment.DEVELOPMENT),
      this.getDevices(qaDeviceList, HdkEnvironment.QA),
    ])
      .pipe(
        map(([devDevices, qaDevices]) => ({ devDevices, qaDevices })),
        take(1)
      )
      .subscribe((data) => {
        const devices = [...data?.devDevices, ...data?.qaDevices];
        this.onDeployedDevice.next({
          data: devices,
          isLoading: false,
          hasError: false,
        });
      });
  }

  getDevices(deviceNames: string[], env: HdkEnvironment): Observable<Device[]> {
    const apiRecord: ApiRecord = this.getAPIRecord(env);
    if (deviceNames.length === 0) {
      return of([]);
    }

    const devicesData$: Observable<Device>[] = deviceNames.map((deviceName) => {
      const getDevice: ApiRecord = {
        ...apiRecord,
        url: generatePath(apiRecord.url, {
          hwName: deviceName,
        }),
      };
      return this.deviceListApiService.getData<IDataDevice>(getDevice).pipe(
        map((device) => {
          return Device.Factory(device, env);
        })
      );
    });

    return combineLatest(devicesData$).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  pipelineBuildRequest = (
    event: Event,
    buildRequest: 'true' | 'false'
  ): void => {
    event.stopPropagation();
    if (this.API) {
      this.deploymentService
        .pipelineBuildRequest(
          this.appName,
          this.version.versionId,
          buildRequest,
          this.API.pipelineBuildRequest
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo('Operation being processed');
          },
          error: () => {
            this.snackbarService.notifyError('Operation failed');
          },
        });
    }
  };

  deleteVersionClick(): void {
    if (this.API) {
      this.deploymentService
        .deleteVersion(
          this.appName,
          this.version.versionId,
          this.API.deleteVersion
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo('Version deletion initiated');
          },
          error: () => {
            this.snackbarService.notifyError('Version deletion failed');
          },
        });
    }
  }

  openDeleteVersionDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('Deployments.DeleteVersionDialog.Title'),
      message: this.translate.instant('Deployments.DeleteVersionDialog.Text'),
      confirmText: this.translate.instant(
        'Deployments.DeleteVersionDialog.Confirm'
      ),
      width: '400px',
      onConfirm: () => this.deleteVersionClick(),
    });
  }

  startDeploymentClick(event: Event) {
    event.stopPropagation();
    if (this.API) {
      this.startDeploymentService.openStartDeploymentDialog(
        this.appName,
        {
          createDevDeployment: this.API.createDevDeployment,
          createQaDeployment: this.API.createQaDeployment,
          getDevDevices: this.API.getDevDevices,
          getQaDevices: this.API.getQaDevices,
        },
        { versionId: this.version.versionId },
        this.unsubscribe$
      );
    }
  }

  getAPIRecord(env: HdkEnvironment): ApiRecord {
    if (!this.API) {
      return {
        url: '',
        method: Method.GET,
      };
    }

    switch (env) {
      case HdkEnvironment.DEVELOPMENT:
        return this.API.getDevHardware;
      case HdkEnvironment.QA:
        return this.API.getQaHardware;
      case HdkEnvironment.PRODUCTION:
        return this.API.getProdHardware;
    }
  }

  trackByName(index: number, device: Device): string {
    return device.hwName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentVersion = changes['version'].currentValue;
    const previousVersion = changes['version'].previousValue;
    if (currentVersion !== previousVersion) {
      this.fetchData(currentVersion, this.appName);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  disableDeployment(dev: PipelineStatus, qa: PipelineStatus): boolean {
    return !(
      dev === this.pipelineStatus.PIPELINE_BUILD_SUCCESS ||
      qa === this.pipelineStatus.PIPELINE_BUILD_SUCCESS
    );
  }

  expandListItem(dev: Deployment[], qa: Deployment[]): boolean {
    return dev.length > 0 || qa.length > 0;
  }

  getEnvironmentStatus(status: PipelineStatus | string): ChipVariant {
    const pipe = PipelineStatus;
    switch (status) {
      case pipe.PIPELINE_CREATED:
      case pipe.PIPELINE_BUILD_SUCCESS:
        return ChipVariant.SUCCESS;
      case pipe.PIPELINE_BUILDING:
      case pipe.PIPELINE_IN_DELETION:
      case pipe.PIPELINE_IN_CREATION:
        return ChipVariant.WARN;
      case pipe.PIPELINE_BUILD_FAILED:
      case pipe.NOT_FOUND:
        return ChipVariant.ERROR;
      default:
        return ChipVariant.INFO;
    }
  }
}
