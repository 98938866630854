<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Sharp"
  rel="stylesheet"
/>

<div class="container">
  <h2>Profile</h2>
  <div class="user-container">
    <div class="avatar">
      <img src="assets/images/empty-avatar.svg" alt="Avatar" />
   </div>

    <div class="profile-data" *ngIf="profileData$ | async as profileData">
      <h1 class="user-name">{{ profileData.name || "Nadine Cho" }}</h1>
    </div>
    <p class="tenant-name">{{ loggedTenant }}</p>
  </div>

  <mat-divider />

  <div class="item-container">
    <div class="info-item">
      <mat-icon fontSet="material-icons-sharp" class="item-icon"
        >email</mat-icon
      >
      <p
        class="item-text"
        class="profile-data"
        *ngIf="profileData$ | async as profileData"
      >
        <a [href]="'mailto:' + profileData.email" color="primary">{{
          profileData.email
        }}</a>
      </p>
    </div>
    <div class="info-item">
      <mat-icon fontSet="material-icons-sharp" class="item-icon"
        >phone</mat-icon
      >
      <p class="item-text">Not available</p>
    </div>
  </div>
  <button
    type="button"
    class="logout-container"
    data-cy="logout-button"
    mat-raised-button
    color="primary"
    [disabled]="!isLogoutButtonEnabled"
    (click)="logout()"
  >
    {{ "Profile.Logout" | translate }}
  </button>
</div>
