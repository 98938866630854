import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { listAnimation } from 'src/app/core/animations/listAnimation';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { ApplicationListItemComponent } from './components/application-list-item/application-list-item.component';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';
import { ApplicationType } from '../../../../shared/stores/applications/models/applicationType';

@Component({
  selector: 'app-application-list',
  standalone: true,
  imports: [
    CommonModule,
    ScrollingModule,
    EmptySectionComponent,
    TranslateModule,
    ApplicationListItemComponent,
  ],
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
  animations: [listAnimation],
})
export class ApplicationListComponent {
  @Input() grid = false;
  @Input() applications: Application[] = [];

  constructor(private routingStore: RoutingStore) {}

  navigateToApp(applicationName: string, type: ApplicationType) {
    localStorage.setItem('selectedApplicationType', type);
    this.routingStore.navigateToApplication(applicationName);
  }

  trackByApplicationName(index: number, application: Application): string {
    return application.appName;
  }
}
