import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { ApplicationTypeLabels } from '../../../add-application-dialog/models/applicationTypeLabels';
import { ChipComponent, ChipVariant } from 'src/app/shared/components/chip/chip.component';

@Component({
  selector: 'app-application-list-item',
  standalone: true,
  imports: [CommonModule, ChipComponent],
  templateUrl: './application-list-item.component.html',
  styleUrls: ['./application-list-item.component.scss'],
})
export class ApplicationListItemComponent implements OnInit {
  @Input({ required: true }) applicationName = '';
  @Input({ required: true }) applicationType = '';
  @Input({ required: true }) applicationRepo = '';

  ChipVariant = ChipVariant;

  assignTypeLabels(appType: string): void {
    switch (appType) {
      case ApplicationType.SINGLE_SERVICE:
        this.applicationType = ApplicationTypeLabels.LINUX_CONTAINER;
        break;
      case ApplicationType.CUSTOM:
        this.applicationType = ApplicationTypeLabels.ADAPTIVE_AUTOSAR;
        break;
      case ApplicationType.ANDROID:
        this.applicationType = ApplicationTypeLabels.ANDROID;
        break;
      case ApplicationType.QNX:
        this.applicationType = ApplicationTypeLabels.QNX;
        break;
      case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
          this.applicationType = ApplicationTypeLabels.CLASSIC_AUTOSAR;
          break;
      default:
        return;
    }
  }

  ngOnInit(): void {
    this.assignTypeLabels(this.applicationType);
  }
}
