import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';

export const APPLICATION_TYPE_KEYS = [
    'ApplicationList.FilterKeys.Type.All',
    'ApplicationList.FilterKeys.Type.AdaptiveAutosar',
    'ApplicationList.FilterKeys.Type.Android',
    'ApplicationList.FilterKeys.Type.LinuxContainer',
    'ApplicationList.FilterKeys.Type.ClassicAutosarImage',
    'ApplicationList.FilterKeys.Type.QNX',
  ] as const;


export type ApplicationTypeKey = (typeof APPLICATION_TYPE_KEYS)[number];

export const filterKeyToApplicationPropertyMap = {
    'ApplicationList.FilterKeys.Type.Android': ApplicationType.ANDROID,
    'ApplicationList.FilterKeys.Type.QNX': ApplicationType.QNX,
    'ApplicationList.FilterKeys.Type.AdaptiveAutosar': ApplicationType.CUSTOM,
    'ApplicationList.FilterKeys.Type.LinuxContainer': ApplicationType.SINGLE_SERVICE,
    'ApplicationList.FilterKeys.Type.ClassicAutosarImage': ApplicationType.CLASSIC_AUTOSAR_IMAGE,
};
