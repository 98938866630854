import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsStore } from '../../stores/config/breadcrumbs.store';
import { Observable, map, of } from 'rxjs';
import { Tenant } from 'src/app/core/models/enums/Tenant';

@Component({
  standalone: true,
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, MatDividerModule]
})
export class HelpMenuComponent implements OnInit {
  readonly hondaSupport = 'https://support.8675.ccp.continental.exchange/servicedesk/customer/portal/2';
  readonly paccarSupport = 'https://jira.6543.ccp.continental.exchange/plugins/servlet/desk/portal/16';

  tenants = Tenant;
  currentTenant$: Observable<string> = of('');

  private readonly breadcrumbs$ = this.breadcrumbStore.breadcrumbs$;

  constructor(private breadcrumbStore: BreadcrumbsStore) {}

  ngOnInit(): void {
    this.initBreadcrumps();
  }

  private initBreadcrumps() {
    this.currentTenant$ = this.breadcrumbs$.pipe(map((breadcrumbs) => breadcrumbs[0].label));
  }
}
