import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DialogProps } from 'src/app/shared/components/dialog/models/dialogProps';
import { Application } from '../../../shared/stores/deployment/models/application';
import { ApplicationSettingsComponent } from '../../../features/project-dashboard/components/application-settings/application-settings.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: DialogProps): Observable<boolean> {
    return this.dialog
      .open(DialogComponent, {
        data,
        width: data.width,
        minWidth: '400px',
        maxWidth: '800px',
        autoFocus: false,
      })
      .afterClosed();
  }

  openApplicationEditDialog(application: Application): Observable<boolean> {
    return this.dialog
      .open(ApplicationSettingsComponent, { data: application, width: '400px' })
      .afterClosed();
  }
}
