<ng-container class="job-details-container" *ngIf="!loading; else loadingBar">
  <app-header-template
    *ngIf="jobDetails$ | async as jobDetails"
    [itemName]="jobDetails.RunId"
    [showBackButton]="false"
    data-cy="project-details-overview"
  >   
    <button
        class="cancel-button"
        mat-flat-button
        [disabled]="false"       
      >
        {{ 'Jobs.Cancel' | translate }}
      </button>
  </app-header-template>
  <app-details-expansion
    [title]="'Jobs.Details.Title' | translate"
    [description]="'Jobs.Details.Subtitle' | translate"
    [expanded]="true"
    [hideMenu]="true"
  >
    <ng-container *ngIf="jobDetails$ | async as jobDetails">
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.Status" | translate }} </span>
        <app-chip
          class="type-chip"
          [label]="jobDetails.Status"
          [variant]="
            jobDetails.RunData.JobStates.FailedJobs !== 0
              ? ChipVariant.ERROR
              : ChipVariant.SUCCESS
          "
        />
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.Caller" | translate }} </span>
        <span class="value">{{ jobDetails.Caller }}</span>
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.RunId" | translate }}</span>
        <span class="value">{{ jobDetails.RunId }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.JobCount" | translate }}</span>
        <span class="value">{{
          jobDetails.RunData.JobStates.JobCount
            ? jobDetails.RunData.JobStates.JobCount
            : "Not available"
        }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.ChildJobCount" | translate }}</span>
        <span class="value">{{
          jobDetails.RunData.JobStates.ChildJobCount
            ? jobDetails.RunData.JobStates.ChildJobCount
            : "Not available"
        }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{
          "Jobs.Details.FinishedChildTasks" | translate
        }}</span>
        <span class="value">{{
          jobDetails.RunData.JobStates.FinishedChildJobs
            ? jobDetails.RunData.JobStates.FinishedChildJobs
            : "Not available"
        }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.FailedJobs" | translate }}</span>
        <app-chip
          class="type-chip"
          [label]="
            jobDetails.RunData.JobStates.FailedJobs
              ? jobDetails.RunData.JobStates.FailedJobs.toString()
              : 'Not available'
          "
          [variant]="
            jobDetails.RunData.JobStates.FailedJobs !== 0
              ? ChipVariant.ERROR
              : ChipVariant.SUCCESS
          "
        />
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.InputBpl" | translate }}</span>
        <span class="value">{{ jobDetails.RunData.input_location }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.RECIDS" | translate }}</span>
        <div class="value">
          <div class="recids-container">
            <!-- show only the first three RECIDS (the list can be very long)-->
            {{
              jobDetails.RunData.RECIDS.length === 0
                ? "Not available"
                : jobDetails.RunData.RECIDS.slice(0, 3).join(", ")
            }}
            {{ jobDetails.RunData.RECIDS.length > 3 ? "..." : "" }}
            <mat-icon
              *ngIf="jobDetails.RunData.RECIDS.length > 0"
              class="copy-icon"
              (click)="copyToClipboard(jobDetails.RunData.RECIDS)"
              >file_copy
            </mat-icon>
          </div>
        </div>
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.Costs" | translate }}</span>
        <span class="value"
          >{{
            jobDetails.Cost === undefined || jobDetails.Cost === ""
              ? "Not available"
              : (jobDetails.Cost | number : "1.2-2")
          }}
          $
        </span>
      </div>
    </ng-container>
  </app-details-expansion>

  <app-details-expansion
    [title]="'Jobs.Details.WorkflowTitle' | translate"
    [description]="'Jobs.Details.WorkflowSubtitle' | translate"
    [expanded]="true"
    [hideMenu]="true"
  >
    <ng-container *ngIf="jobDetails$ | async as jobDetails">
      <ng-container
        *ngFor="
          let item of Object.keys(jobDetails.RunData.workflow_configuration[0])
        "
      >
        <div class="job-details-fields">
          <span class="key">{{ item }}</span>
          <span class="value">{{
            !item.toLowerCase().includes("date")
              ? jobDetails.RunData.workflow_configuration[0][item]
              : (jobDetails.RunData.workflow_configuration[0][item]
                | customFormatDate)
          }}</span>
        </div>
      </ng-container>
    </ng-container>
  </app-details-expansion>

  <app-child-jobs
    *ngIf="tab === CurrentTab.SIMULATION"
    [runID]="(jobDetails$ | async)?.RunId"
  ></app-child-jobs>
</ng-container>

<ng-template #loadingBar>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>