<form
  data-cy="add-new-application-dialog"
  class="form"
  [formGroup]="inputFormGroup"
  (ngSubmit)="submitForm()"
>
  <div>
    <h2 class="header" mat-dialog-title>
      {{ "Application.AddApplicationDialog.Title" | translate }}
    </h2>
  </div>
  <div class="content" mat-dialog-content>
    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationName" | translate
      }}</mat-label>
      <input
        data-cy="application-name-input"
        formControlName="applicationNameControl"
        matInput
        maxlength="15"
        [errorStateMatcher]="matcher"
      />
      <mat-hint align="end"
        >{{ applicationNameControl.value.length }}/15</mat-hint
      >
      <mat-error
        data-cy="application-name-error-msg"
        *ngIf="applicationNameControl?.invalid"
      >
        {{ getErrorMessage(applicationNameControl) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationType" | translate
      }}</mat-label>
      <mat-select
        data-cy="select-application-type"
        class="caedge-form-field interior"
        [formControl]="applicationTypeControl"
      >
        <mat-option
          data-cy="application-type-option"
          *ngFor="let appType of appTypes"
          [value]="appType.id"
        >
          {{ appType.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="applicationTypeControl.invalid">
        {{ getErrorMessage(applicationTypeControl) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{ sourceLinkPlaceholder }}</mat-label>
      <input
        data-cy="source-link-input"
        formControlName="sourceLinkControl"
        matInput
        maxlength="100"
      />
      <mat-hint align="end">{{ sourceLinkControl.value.length }}/100</mat-hint>
      <mat-error *ngIf="sourceLinkControl.errors?.['pattern']">
        {{
          "Application.AddApplicationDialog.ErrorMessage.NoSpaces" | translate
        }}
      </mat-error>
      <mat-error *ngIf="sourceLinkControl.errors?.['required']">
        {{
          "Application.AddApplicationDialog.ErrorMessage.Required" | translate
        }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationSource" | translate
      }}</mat-label>
      <mat-select data-cy="select-source-type" class="caedge-form-field" [formControl]="sourceTypeControl">
        <mat-option
          *ngFor="let sourceType of sourceTypes"
          [value]="sourceType.id"
        >
          {{ sourceType.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="sourceTypeControl.invalid">
        {{ getErrorMessage(sourceTypeControl) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field data-cy="select-package-type" class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationPackage" | translate
      }}</mat-label>
      <mat-select
       class="caedge-form-field" [formControl]="packageTypeControl">
        <mat-option
          *ngFor="let packageType of packageTypes"
          [value]="packageType.id"
        >
          {{ packageType.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="packageTypeControl.invalid">
        {{ getErrorMessage(packageTypeControl) }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="actions" mat-dialog-actions align="end">
    <button
      data-cy="cancel-add-new-application"
      class="cancel"
      mat-flat-button
      mat-dialog-close
    >
      {{ "General.Cancel" | translate }}
    </button>
    <button
      data-cy="add-new-application-btn"
      class="add-application-btn"
      mat-flat-button
      type="submit"
      [disabled]="inputFormGroup.invalid"
      mat-dialog-close
    >
      {{ "Application.AddApplicationDialog.AddApplication" | translate }}
    </button>
  </div>
</form>
