import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-cae-button',
  standalone: true,
    imports: [CommonModule, MatButtonModule, TranslateModule, MatIconModule],
  templateUrl: './cae-button.component.html',
  styleUrls: ['./cae-button.component.scss'],
})
export class CaeButtonComponent {
  @Input() color: string = 'secondary';
  @Input() buttonText: string = '';
  @Input() disable: boolean = false;
  @Input() icon: string = '';
}
