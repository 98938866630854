<app-header-template
  itemName="Dashboard"
  [showBackButton]="false"
  data-cy="project-details-overview"
>
</app-header-template>
<ng-container *ngIf="project$ | async as project">
  <app-details-expansion
    title="{{ 'ProjectDetails.Title' | translate }}"
    description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
    [hideToggle]="true"
    [menuItems]="menuItems"
  >
    <app-display-details
      data-cy="project-details"
      translationKey="Project"
      [data]="project"
    ></app-display-details>
  </app-details-expansion>
  <app-details-expansion
    title="{{ 'ProjectDetails.WorkbenchTitle' | translate }}"
    description="{{ 'ProjectDetails.WorkbenchDescription' | translate }}"
    [hideDivider]="true"
    [hideToggle]="true"
    [menuItems]="menuItems"
  >
    <app-display-details translationKey="Workbench" [data]="workbench">
    </app-display-details>
  </app-details-expansion>
</ng-container>
