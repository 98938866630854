import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { Contact } from '../../models/contact';
import { Observable, take } from 'rxjs';
import { ProjectContactsService } from '../services/project-contacts.service';

/**
 * `StepComponent` implementation for the contact information step of the project creation process.
 *
 * @export
 * @class ContactInformationComponent
 */
@Component({
  selector: 'app-contact-information',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit, StepComponent {
  contactInfoForm: FormGroup;
  ownerContacts$: Observable<Contact[]>;
  coOwnerContacts$: Observable<Contact[]>;
  securityCentralContacts$: Observable<Contact[]>;
  securityProjectOwnerContacts$: Observable<Contact[]>;
  technicalContacts$: Observable<Contact[]>;

  private allOwners: Contact[] = [];
  private allCoOwners: Contact[] = [];
  private allSecCentralContacts: Contact[] = [];
  private allSecOwners: Contact[] = [];
  private allTechnicalContacts: Contact[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private contactsService: ProjectContactsService
  ) {
    this.contactInfoForm = this.fb.group({
      projectOwner: ['', [Validators.email, Validators.required]],
      securityCentral: ['', [Validators.email, Validators.required]],
      projectCoOwner: [''],
      securityProjectOwner: ['', [Validators.email, Validators.required]],
      technicalContact: ['', [Validators.email, Validators.required]]
    });

    this.ownerContacts$ = contactsService.getProjectOwnerContacts();
    this.coOwnerContacts$ = contactsService.getProjectCoOwnerContacts();
    this.securityCentralContacts$ = contactsService.getSecurityCentralContacts();
    this.securityProjectOwnerContacts$ = contactsService.getSecurityProjectOwnersContacts();
    this.technicalContacts$ = contactsService.getTechnicalContacts();
  }

  get projectOwnerControl() {
    return this.contactInfoForm.get('projectOwner') as FormControl;
  }

  get securityCentralControl() {
    return this.contactInfoForm.get('securityCentral') as FormControl;
  }

  get projectCoOwnerControl() {
    return this.contactInfoForm.get('projectCoOwner') as FormControl;
  }

  get securityProjectOwnerControl() {
    return this.contactInfoForm.get('securityProjectOwner') as FormControl;
  }

  get technicalContactControl() {
    return this.contactInfoForm.get('technicalContact') as FormControl;
  }

  get stepValid(): boolean {
    return this.contactInfoForm.valid;
  }

  get dataToUpdate() {
    const formValue = this.contactInfoForm.getRawValue();
    return {
      Contacts: {
        ProjectOwner: this.getContact(this.allOwners, formValue.projectOwner),
        ProjectCoOwner: this.getCoOwnerContacts(this.allCoOwners, formValue.projectCoOwner),
        SecurityCentralOps: this.getContact(this.allSecCentralContacts, formValue.securityCentral),
        SecurityProjectOwner: this.getContact(this.allSecOwners, formValue.securityProjectOwner),
        Technical: this.getContact(this.allTechnicalContacts, formValue.technicalContact)
      }
    };
  }

  ngOnInit(): void {
    this.fetchAllContacts();
  }

  private fetchAllContacts() {
    this.ownerContacts$.pipe(take(1)).subscribe((contacts) => {
      this.allOwners = contacts;
    });
    this.coOwnerContacts$.pipe(take(1)).subscribe((contacts) => {
      this.allCoOwners = contacts;
    });

    this.securityCentralContacts$.pipe(take(1)).subscribe((contacts) => {
      this.allSecCentralContacts = contacts;
    });
    this.securityProjectOwnerContacts$.pipe(take(1)).subscribe((contacts) => {
      this.allSecOwners = contacts;
    });
    this.technicalContacts$.pipe(take(1)).subscribe((contacts) => {
      this.allTechnicalContacts = contacts;
    });
  }

  private getContact(allContacts: Contact[], formValue: string): Contact {
    const newContact: Contact = {
      ContiUid: '-',
      Email: formValue,
      Name: '-',
      Phone: '-'
    };

    const filteredContacts: Contact[] = allContacts.filter((contact) => contact.Email === formValue);

    return filteredContacts.length === 0 ? newContact : filteredContacts[0];
  }

  private getCoOwnerContacts(allContacts: Contact[], formValues: string[]): Contact[] {
    if (formValues.length === 0) {
      return [
        {
          ContiUid: '-',
          Email: '-',
          Name: '-',
          Phone: '-'
        }
      ];
    }
    const contacts: Contact[] = [];
    formValues.forEach((value) => {
      const newContact: Contact = {
        ContiUid: '-',
        Email: '-',
        Name: value,
        Phone: '-'
      };

      const filteredContacts: Contact[] = allContacts.filter((contact) => contact.Name === value);

      contacts.push(filteredContacts.length === 0 ? newContact : filteredContacts[0]);
    });
    return contacts;
  }

  /**
   * Checks if the given FormControl is invalid and returns the corresponding error message.
   *
   * @param formControl the FormControl to validate
   * @return the corresponding error message or an empty string in case there is no error
   */
  getErrorMessage(formControl: FormControl | null): string {
    if (formControl) {
      if (formControl.hasError('required')) {
        return this.translate.instant('Project.AddProjectDialog.ErrorMessage.Required');
      }
      if (formControl.hasError('email')) {
        return this.translate.instant('Project.AddProjectDialog.ErrorMessage.Email');
      }
    }
    return '';
  }

  /**
   * Removes already selected co-owner.
   *
   * @param contact - the co owner to be removed
   */
  removeCoOwner(contact: string): void {
    const currentContacts: string[] = this.projectCoOwnerControl.value;
    const index = currentContacts.indexOf(contact);
    if (index >= 0) {
      currentContacts.splice(index, 1);
      this.projectCoOwnerControl.setValue(currentContacts);
    }
    this.contactInfoForm.updateValueAndValidity();
  }
}
