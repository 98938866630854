<mat-sidenav-container class="sidenav-page" data-cy="side-menu">
  <mat-sidenav
    mode="side"
    opened
    [ngClass]="{ collapsed: (isSidebarExpanded$ | async) === false }"
  >
    <mat-nav-list>
      <mat-list-item
        id="caedge-logo"
        data-cy="side-menu-opt-home"
        lines="1"
        routerLink="home"
      >
        <mat-icon
          matListItemIcon
          class="material-icons-sharp"
          data-cy="side-menu-logo"
          svgIcon="CAEdge_icon"
        ></mat-icon>
        <a *ngIf="isSidebarExpanded$ | async">
          {{ "General.CAEdge" | translate }}
        </a>
      </mat-list-item>
      <div
        *ngIf="routePaths$ | async as routePath"
        [@fadeInLeftStagger]="routePath"
      >
        <ng-container *ngIf="routePath[0]?.label !== 'Projects'">
          <mat-list-item
            [ngClass]="
              (isSidebarExpanded$ | async) ? 'back-btn' : 'caedge-feature'
            "
            [routerLink]="'home'"
          >
            <mat-icon
              class="material-icons-sharp"
              data-cy="side-menu-opt-icon"
              matListItemIcon
            >
              {{ "arrow_back" }}
            </mat-icon>
            <a
              matListItemTitle
              data-cy="side-menu-opt-label"
              *ngIf="isSidebarExpanded$ | async"
            >
              Back to Projects
            </a>
          </mat-list-item>
        </ng-container>
        <div *ngFor="let route of routePaths$ | async; trackBy: onPathChange">
          <mat-list-item
            class="caedge-feature"
            data-cy="side-menu-opt-item"
            [ngClass]="{'active-child': currentUrl.includes(route.label.slice(0,-1).toLowerCase())}"
            *ngIf="!route.nonNavigatable && route.type !== type.DIVIDER"
            lines="1"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="active"
            [routerLink]="route.fullPath"
          >
            <mat-icon
              class="material-icons-sharp"
              data-cy="side-menu-opt-icon"
              matListItemIcon
            >
              {{ route.icon || "" }}
            </mat-icon>
            <a
              matListItemTitle
              data-cy="side-menu-opt-label"
              *ngIf="isSidebarExpanded$ | async"
            >
              {{ route.label }}
            </a>
          </mat-list-item>
          <div
            class="feature-section"
            *ngIf="route.nonNavigatable"
            mat-subheader
          >
            <div *ngIf="isSidebarExpanded$ | async; else notExpanded">
              {{ route.label }}
            </div>
            <ng-template #notExpanded>
              <p class="not-expanded"><></p>
            </ng-template>
          </div>
          <div class="caedge-divider" *ngIf="route.type === type.DIVIDER">
            <mat-divider class="divider" />
          </div>
        </div>
      </div>
      <div class="bottom-sidemenu">
        <mat-list-item (click)="toggleIsSidebarExpanded()">
          <div
            style="display: flex"
            [ngStyle]="{
              'justify-content':
                (isSidebarExpanded$ | async) ? 'flex-end' : 'center'
            }"
          >
            <mat-icon
              class="chevron-button material-icons-sharp"
              data-cy="side-menu-chevron"
              [ngClass]="{ rotated: isSidebarExpanded$ | async }"
              >chevron_right
            </mat-icon>
          </div>
        </mat-list-item>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{ 'expanded-content': (isSidebarExpanded$ | async) === false }"
  >
    <div class="root">
      <div class="sticky">
        <app-header id="caedge-header"></app-header>
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <div class="app-content">
        <main>
          <ng-content></ng-content>
        </main>
        <app-footer />
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
