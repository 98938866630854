<h2 mat-dialog-title>{{ titleKey | translate }}</h2>
<div data-cy="create-new-device-dialog" mat-dialog-content class="content">
  <p data-cy="create-simulated-dialog-title" *ngIf="this.data.deviceType === DeviceType.SIMULATED">
    {{ "DeviceList.CreateDialog.YouCanAdd" | translate }}
  </p>
  <p data-cy="create-vhpc-dialog-title" *ngIf="data.deviceType === DeviceType.VHPC">
    {{ 'DeviceList.CreateDialog.YouCanAddVHPC' | translate }}
  </p>
  <div *ngIf="data.deviceType === DeviceType.REAL">
    <p data-cy="request-real-device-title">
      {{ "DeviceList.CreateDialog.ContactHwFarm" | translate }}
    </p>
    <span class="support-email">support.hardware@caedge.com</span>
    <p>
      {{ "DeviceList.CreateDialog.WillGetInTouch" | translate }} <br />
      {{ "DeviceList.CreateDialog.PleaseCreate" | translate }}
    </p>
  </div>
  <div class="inputs" [formGroup]="createDeviceFormGroup">
    <mat-form-field class="caedge-form-field-outline">
      <mat-label>{{
        "DeviceList.CreateDialog.DeviceName" | translate
      }}</mat-label>
      <input
        data-cy="device-name-input"
        formControlName="deviceName"
        [errorStateMatcher]="errorMatcher"
        matInput
        maxlength="15"
      />
      <mat-hint data-cy="create-device-dialog-name-hint" align="start">{{
        "DeviceList.CreateDialog.NameRequirements" | translate
      }}</mat-hint>
      <mat-hint align="end">{{ deviceName.length }}/15</mat-hint>
      <mat-error
        data-cy="device-name-caedge-name-error"
        *ngIf="createDeviceFormGroup.get('deviceName')?.hasError('startsWithCaedge')"
      >
        {{ 'General.CaedgeNotPermitted' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="this.data.deviceType === DeviceType.SIMULATED || this.data.deviceType === DeviceType.VHPC"
      class="caedge-form-field-outline"
    >
      <mat-label>{{
        "DeviceList.FilterOptions.Environment" | translate
      }}</mat-label>
      <mat-select formControlName="selectedEnv">
        <mat-option value="Development">{{
          "DeviceList.FilterKeys.Environment.Development" | translate
        }}</mat-option>
        <mat-option value="QA">{{
          "DeviceList.FilterKeys.Environment.QA" | translate
        }}</mat-option>
        <!-- Production will be included when there is production API available-->
        <!-- <mat-option value="Production">Production</mat-option> -->
      </mat-select>
      <mat-hint align="start" *ngIf="data.deviceType === DeviceType.VHPC">
        {{ 'DeviceList.VHPCAvailableOnlyForDevelopment' | translate }}
      </mat-hint>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-device-button"
    mat-flat-button
    [mat-dialog-close]="false"
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    data-cy="create-device-button"
    mat-flat-button
    [mat-dialog-close]="true"
    [disabled]="!canSubmit()"
    (click)="handleSubmitClick()"
  >
    {{
      isSimulatedDevice() || data.deviceType === DeviceType.VHPC
        ? ("DeviceList.CreateDialog.CreateDevice" | translate)
        : "Okay"
    }}
  </button>
</div>
