<div class="fw-updates-actions" *ngIf="fwUpdatesData$ | async as fwUpdatesData">
  <button
    data-cy="fw-update-button"
    class="update-fw-btn"
    *ngIf="device.fwUpdate && !hasUpdateFailed"
    [disabled]="isUpdating"
    mat-flat-button
    [attr.aria-label]="'General.Update' | translate"
    [matMenuTriggerFor]="fwUpdatesMenu"
    (click)="fetchFwVersions()"
  >
    <span data-cy="fw-updating-chip">{{
      (isUpdating ? "DeviceList.Updating" : "General.Update") | translate
    }}</span>
    <mat-icon iconPositionEnd>{{
      isUpdating ? "update" : "arrow_drop_down"
    }}</mat-icon>
  </button>
  <button
    data-cy="fw-update-try-again-button"
    class="try-again-fw-btn"
    *ngIf="hasUpdateFailed"
    mat-flat-button
    [attr.aria-label]="'General.TryAgain' | translate"
    [matMenuTriggerFor]="fwUpdatesMenu"
    (click)="fetchFwVersions()"
  >
    <span>{{ "General.TryAgain" | translate }}</span>
    <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
  </button>
  <mat-menu
    data-cy="fw-select-menu"
    class="caedge-secondary-menu"
    #fwUpdatesMenu="matMenu"
    (closed)="onMenuClose()"
  >
    <button
      *ngIf="hasUpdateFailed"
      mat-menu-item
      class="caedge-secondary-menu-item"
      [attr.aria-label]="'DeviceList.FwUpdates.ShowErrorCode' | translate"
    >
      <span data-cy="device-fw-error-code-message">{{ "DeviceList.FwUpdates.ShowErrorCode" | translate }}</span>
    </button>
    <button
      mat-menu-item
      class="caedge-secondary-menu-item"
      [attr.aria-label]="'DeviceList.FwUpdates.OpenReleaseManual' | translate"
    >
      <span>{{ "DeviceList.FwUpdates.OpenReleaseManual" | translate }}</span>
    </button>
    <mat-divider />
    <button
      mat-menu-item
      class="caedge-secondary-menu-active-item"
      [attr.aria-label]="device.fwVersion"
    >
      <span>{{ device.fwVersion }}</span>
      <img src="assets/images/check.svg" alt="check" />
    </button>
    <app-content-wrapper
      [loadingType]="LoadingTypes.SPINNER"
      [isError]="fwUpdatesData.hasError"
      [isLoading]="fwUpdatesData.isLoading"
      ><button
        data-cy="fw-version-item"
        *ngFor="let version of fwUpdatesData.nonCurrentVersions"
        mat-menu-item
        class="caedge-secondary-menu-item"
        [matMenuTriggerFor]="updateTo"
        [attr.aria-label]="version.fwVersion"
        (mouseover)="setSelectedVersion(version)"
      >
        <span>{{ version.fwVersion }}</span>
      </button>
    </app-content-wrapper>
  </mat-menu>
  <mat-menu class="caedge-secondary-menu" #updateTo="matMenu">
    <button
      data-cy="fw-update-to-version"
      mat-menu-item
      class="caedge-secondary-menu-item"
      (click)="openUpdateDeviceDialog()"
      [attr.aria-label]="
        ('DeviceList.FwUpdates.UpdateTo' | translate) + selectedVersion
      "
    >
      {{ ("DeviceList.FwUpdates.UpdateTo" | translate) + selectedVersion }}
    </button>
  </mat-menu>
</div>
