import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../../models/contact';

/**
 * Service to get all possible contacts from json files (dummy values for the current state).
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectContactsService {
  constructor(private http: HttpClient) {}

  getBillingContacts() {
    const configUrl = 'assets/dummyData/billing-contacts.json';
    return this.http.get(configUrl) as Observable<Contact[]>;
  }

  getControllingContacts() {
    const configUrl = 'assets/dummyData/controlling-contacts.json';
    return this.http.get(configUrl) as Observable<string[]>;
  }

  getCostCenterOwnerContacts() {
    const configUrl = 'assets/dummyData/cost-center-owners.json';
    return this.http.get(configUrl) as Observable<string[]>;
  }

  getProjectCoOwnerContacts() {
    const configUrl = 'assets/dummyData/project-co-owners.json';
    return this.http.get(configUrl) as Observable<Contact[]>;
  }

  getProjectOwnerContacts() {
    const configUrl = 'assets/dummyData/project-owners.json';
    return this.http.get(configUrl) as Observable<Contact[]>;
  }

  getSecurityCentralContacts() {
    const configUrl = 'assets/dummyData/security-central-owners.json';
    return this.http.get(configUrl) as Observable<Contact[]>;
  }

  getSecurityProjectOwnersContacts() {
    const configUrl = 'assets/dummyData/security-project-owners.json';
    return this.http.get(configUrl) as Observable<Contact[]>;
  }

  getTechnicalContacts() {
    const configUrl = 'assets/dummyData/technical-contacts.json';
    return this.http.get(configUrl) as Observable<Contact[]>;
  }

  getWhitelistMails() {
    const configUrl = 'assets/dummyData/create-project-whitelist.json';
    return this.http.get(configUrl) as Observable<string[]>;
  }
}
