export interface IBranchOrTag {
  elementName: string;
  elementType: string;
}

export interface IElement {
  element_name: string;
  element_type: string;
}

export class Branch implements IBranchOrTag {
  elementName!: string;
  elementType!: string;

  public static Factory(data: IElement) {
    const element = new Branch();
    element.elementName = data.element_name;
    element.elementType = data.element_type;

    return element;
  }
}
