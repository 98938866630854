import { Injectable } from '@angular/core';
import { Notify } from 'notiflix';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor() {
    Notify.init({
      timeout: 5000,
      useIcon: false,
      fontFamily: 'Continental Stag Sans',
      borderRadius: '2px',
      pauseOnHover: true,
      clickToClose: true,
      fontSize: '14px',
      cssAnimationStyle: 'from-right',
      success: {
        background: '#44cf90',
        textColor: '#fff',
      },
      failure: {
        background: '#ff2d37',
        textColor: '#fff',
      },
      warning: {
        background: '#ffd700',
        textColor: '#000',
      },
      info: {
        background: '#0012b3',
        textColor: '#fff',
      },
    });
  }

  notifySuccess(
    message: string,
    callbackOrOptions?: Notiflix.INotifyOptions | (() => void) | undefined,
    options?: Notiflix.INotifyOptions | undefined
  ): void {
    Notify.success(message, callbackOrOptions, options);
  }

  notifyError(
    message: string,
    callbackOrOptions?: Notiflix.INotifyOptions | (() => void) | undefined,
    options?: Notiflix.INotifyOptions | undefined
  ): void {
    Notify.failure(message, callbackOrOptions, options);
  }

  notifyInfo(
    message: string,
    callbackOrOptions?: Notiflix.INotifyOptions | (() => void) | undefined,
    options?: Notiflix.INotifyOptions | undefined
  ): void {
    Notify.info(message, callbackOrOptions, options);
  }

  notifyWarn(
    message: string,
    callbackOrOptions?: Notiflix.INotifyOptions | (() => void) | undefined,
    options?: Notiflix.INotifyOptions | undefined
  ): void {
    Notify.warning(message, callbackOrOptions, options);
  }
}
