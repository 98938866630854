import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { Tenant } from 'src/app/core/models/enums/Tenant';
import { AuthState, AuthUser } from 'src/app/core/models/interfaces/authState';

export const initialState: AuthState = {
  user: null,
  tokens: null,
  tenant: Tenant.NO_TENANT,
};
@Injectable({
  providedIn: 'root',
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(initialState);
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.state$.pipe(
      map((auth) => auth.tokens !== null && auth.user !== null)
    );
  }

  get user$(): Observable<AuthUser> {
    return this.state$.pipe(map((auth) => auth.user!));
  }
}
