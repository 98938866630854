import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { DevicesState } from './models/devicesState';
import { Device } from './models/device/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceStore extends Store<DevicesState> {
  constructor() {
    super({ devices: [], isLoading: true, hasError: false });
  }

  get devices$(): Observable<Device[]> {
    return this.state$.pipe(map((state) => state.devices));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
