/**
 * Contains the id of the different tenants.
 */
export const TenantId = {
  Conti: 'T001',
  Honda: 'T002',
  Ageda: 'T003',
  Paccar: 'T004',
  Tata: 'T005',
  MB: 'T006',
  DT: 'T007',
};
