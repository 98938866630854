export enum DeploymentStatus {
  DEPLOYMENT_NOT_POSSIBLE = 'DEPLOYMENT_NOT_POSSIBLE',
  DEPLOYMENT_IN_CREATION = 'DEPLOYMENT_IN_CREATION',
  DEPLOYMENT_EXECUTION_SUCCEEDED = 'DEPLOYMENT_EXECUTION_SUCCEEDED',
  DEPLOYMENT_EXECUTION_FAILED = 'DEPLOYMENT_EXECUTION_FAILED',
  DEPLOYMENT_EXECUTION_REJECTED = 'DEPLOYMENT_EXECUTION_REJECTED',
  DEPLOYMENT_EXECUTION_CANCELED = 'DEPLOYMENT_EXECUTION_CANCELED',
  DEPLOYMENT_EXECUTION_REMOVED = 'DEPLOYMENT_EXECUTION_REMOVED',
  DEPLOYMENT_EXECUTION_TIMED_OUT = 'DEPLOYMENT_EXECUTION_TIMED_OUT',
  DELETION_NOT_POSSIBLE = 'DELETION_NOT_POSSIBLE',
  DELETION_IN_PROGRESS = 'DELETION_IN_PROGRESS',
  DELETION_FAILED = 'DELETION_FAILED',
  NO_STATUS = 'NO_STATUS',
  UNKNOWN = 'UNKNOWN',
}

export const DeploymentStatusMap = {
  [DeploymentStatus.DEPLOYMENT_NOT_POSSIBLE]: 'not possible',
  [DeploymentStatus.DEPLOYMENT_IN_CREATION]: 'in creation',
  [DeploymentStatus.DEPLOYMENT_EXECUTION_SUCCEEDED]: 'succeeded',
  [DeploymentStatus.DEPLOYMENT_EXECUTION_FAILED]: 'failed',
  [DeploymentStatus.DEPLOYMENT_EXECUTION_REJECTED]: 'rejected',
  [DeploymentStatus.DEPLOYMENT_EXECUTION_CANCELED]: 'canceled',
  [DeploymentStatus.DEPLOYMENT_EXECUTION_REMOVED]: 'removed',
  [DeploymentStatus.DEPLOYMENT_EXECUTION_TIMED_OUT]: 'timed out',
  [DeploymentStatus.DELETION_NOT_POSSIBLE]: 'deletion not possible',
  [DeploymentStatus.DELETION_IN_PROGRESS]: 'deleting',
  [DeploymentStatus.DELETION_FAILED]: 'deletion failed',
  [DeploymentStatus.NO_STATUS]: 'no status',
  [DeploymentStatus.UNKNOWN]: 'unknown status',
};

export interface IDataDeployment {
  hw_target: string;
  deployment_status: DeploymentStatus;
  deployment_detail: string;
  commit_id: string;
}

export interface IDeployment {
  hwTarget: string;
  deploymentStatus: DeploymentStatus;
  deploymentDetail: string;
  commitId: string;
}

export class Deployment implements IDeployment {
  hwTarget!: string;
  deploymentStatus!: DeploymentStatus;
  deploymentDetail!: string;
  commitId!: string;
  versionId!: string;

  public static Factory(data: IDataDeployment, versionId: string): Deployment {
    const deployment = new Deployment();
    deployment.hwTarget = data.hw_target;
    deployment.deploymentStatus = data.deployment_status;
    deployment.deploymentDetail = data.deployment_detail;
    deployment.commitId = data.commit_id;
    deployment.versionId = versionId;

    return deployment;
  }
}
