<div class="start-col">
  <h4>{{ device.hwName }}</h4>
  <p>{{ getStatusChipLabel(device) | translate }}</p>
</div>
<div class="mid-items">
  <app-chip
    class="type-chip"
    [label]="getDeviceTypeLabel(device)"
    [variant]="ChipVariant.NEUTRAL_WHITE"
    [matTooltip]="deviceTypeTooltipSignal() | translate"
    />
  <app-chip
    class="deployment-chip"
    data-cy="deployment-chip"
    [label]="getNumberRunningDeployments(device)"
    [variant]="ChipVariant.NEUTRAL_WHITE"
    [icon]="'subscriptions'"
    [matTooltip]="
      getNumberRunningDeployments(device) +
      ' ' +
      getRunningDeploymentsLabel(device)
    "
  />
  <div class="env-container-chip chip-container">
    <app-chip
      [ngClass]="{
        'env-chip': device.instanceType === 'REAL',
        'env-chip-no-fw': device.instanceType !== 'REAL'
      }"
      *ngIf="device.connectedEnvironments.length"
      [matTooltip]="'HDK.EnvTooltip.' + device.environments[0] | translate"
      [label]="getDeviceEnvLabel(device) | translate"
    />
  </div>
  <div class="fw-chip-container chip-container">
    <app-chip
      class="fw-chip"
      data-cy="device-fw-version"
      *ngIf="device.instanceType === 'REAL'"
      [label]="device.fwVersion!"
      [variant]="
        device.instanceConnectionStatus === 'CONNECTED'
          ? getFwChipVariant()
          : ChipVariant.DISABLED
      "
      [matTooltip]="
        (device.instanceConnectionStatus === 'CONNECTED'
          ? 'DeviceList.FwUpdates.FwVersion'
          : 'DeviceList.FwUpdates.LastReportedVersion'
        ) | translate
      "
    />
  </div>
  <div class="fw-updates">
    <app-fw-updates-actions
      *ngIf="device.instanceType === 'REAL'"
      [device]="device"
      [getVersionsApiRecordsMap]="getVersionsApiRecordsMap"
      [updateFwVersionsApiRecordsMap]="updateFwVersionsApiRecordsMap"
    />
  </div>
</div>
<div class="end-items">
  <div class="control-btns" *ngIf="deviceActionApiConfigMap">
    <app-simulated-action-buttons
      [device]="device"
      [isConnecting]="isConnecting"
      [isBeingCreated]="isBeingCreated"
      [isStopping]="isStopping"
      [deviceActionApiConfigMap]="deviceActionApiConfigMap"
    />
  </div>
  <div class="start-deployment-col">
    <button
      *ngIf="!isBeingCreated && !isInactive"
      mat-flat-button
      class="add-deployment-btn"
      aria-label="Start deployment"
      [disabled]="device.instanceConnectionStatus !== 'CONNECTED' || isStopping"
      (click)="startDeploymentClick()"
    >
      <mat-icon>add</mat-icon>
      {{ "HDK.Deployment" | translate }}
    </button>
    <button
      *ngIf="isInactive"
      mat-flat-button
      class="register-device-btn"
      color="primary"
      [attr.aria-label]="'DeviceList.RegisterDevice' | translate"
      (click)="openChangeEnvironmentDialog(true)"
    >
      {{ "DeviceList.RegisterDevice" | translate }}
    </button>
  </div>
  <div class="menu-col">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Menu trigger"
      data-cy="device-menu-btn"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu class="caedge-menu" #menu="matMenu" data-cy="device-menu-box">
      <button
        mat-menu-item
        class="caedge-menu-item"
        *ngIf="device.instanceType === 'REAL'"
        (click)="openChangeEnvironmentDialog(false)"
      >
        <span>{{ "DeviceList.ChangeEnvironment" | translate }}</span>
      </button>
      <button
        mat-menu-item
        class="caedge-menu-item"
        (click)="openDeleteDeviceDialog()"
        [disabled]="isBeingCreated || isBeingDeleted"
      >
        <span>{{ "DeviceList.DeleteDialog.Confirm" | translate }}</span>
      </button>
      <button
        data-cy="download-script-btn"
        mat-menu-item
        *ngIf="device.instanceType === 'SIMULATED'"
        class="caedge-menu-item"
        (click)="openDownloadScriptDialog()"
      >
        <span>{{ "DeviceList.DownloadScript" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
