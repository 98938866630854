import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { Job } from '../models/job';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { SYSDAQChildJob, SYSDAQChildJobs, SYSDAQJobDetails } from '../models/jobDetails';
import { ApiService } from '../../../core/services/api/api.service';
import { generatePath } from '../../../shared/utils/generatePath';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChildJobStepsComponent } from '../components/child-job-steps/child-job-steps.component';
import { stepDownloadRequestPayload, stepDownloadResponse } from '../models/stepDownloadReuest';
@Injectable({
  providedIn: 'root'
})
export class SimulationJobMonitoringService {
  constructor(private apiService: ApiService, public dialog: MatDialog) {}

  getJobs(apiRecord: ApiRecord) {
    const response = this.apiService.request<Job>({ apiRecord }).pipe(map((result) => Job.Factory(result)));
    return lastValueFrom(response);
  }

  getJobDetails(apiRecord: ApiRecord, dagRunId: string) {
    return this.apiService
      .request<SYSDAQJobDetails>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { dagRunId })
        }
      })
      .pipe(map((details) => SYSDAQJobDetails.Factory(details)));
  }

  getAllChildJobs(apiRecord: ApiRecord, dagRunId: string) {
    return this.apiService
      .request<SYSDAQChildJobs>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { dagRunId })
        }
      })
      .pipe(map((childs) => SYSDAQChildJobs.Factory(childs)));
  }

  getFailedChildJobs(apiRecord: ApiRecord, dagRunId: string) {
    const filterParam = new HttpParams().set('filter', 'Failed');
    return this.apiService
      .request<SYSDAQChildJobs>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, {
            dagRunId
          })
        },
        params: filterParam
      })
      .pipe(map((child) => SYSDAQChildJobs.Factory(child)));
  }

  getChildJobSteps(apiRecord: ApiRecord, dagRunId: string, childJobId: string) {
    return this.apiService
      .request<SYSDAQChildJob>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, {
            dagRunId,
            childJobId
          })
        }
      })
      .pipe(map((child) => SYSDAQChildJob.Factory(child)));
  }

  openStepsDialog(
    runID: string | undefined,
    childJobIndex: string,
    apiRecord: ApiRecord | undefined,
    stepDownload: ApiRecord | undefined
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '980px';
    dialogConfig.maxHeight = '600px';
    dialogConfig.data = {
      runId: runID,
      jobIndex: childJobIndex,
      apiRecord: apiRecord,
      requestStepDownload: stepDownload
    };
    this.dialog.open(ChildJobStepsComponent, dialogConfig);
  }

  requestStepDownloadLink(apiRecord: ApiRecord, payload: stepDownloadRequestPayload) {
    return this.apiService.request<stepDownloadResponse>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, {})
      },
      body: payload
    });
  }
}
