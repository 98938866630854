import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardTest } from '../../cypress/utils/core/guards/authTest.guard';
import { PublicGuard } from './core/guards/public/public.guard';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { environment } from '../environments/environment';
import { DashboardComponent } from './features/dashboard/dashboard.component';

const guards: any = environment.enableGuard ? [AuthGuard] : [AuthGuardTest];

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/landing-page/landing-page-routing.module').then(
        (mod) => mod.routes
      ),
  },
  {
    path: 'dashboard',
    canActivate: guards,
    component: DashboardComponent,
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./features/home/home.component').then(
            (mod) => mod.HomeComponent
          ),
      },
      {
        path: 'collaboration',
        loadComponent: () =>
          import('./features/home/home.component').then(
            (mod) => mod.HomeComponent
          ),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./features/auth/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
  },
  {
    path: 'login-page',
    loadComponent: () =>
      import('./features/login-page/login-page.component').then(
        (mod) => mod.LoginPageComponent
      ),
  },
  {
    path: 'logout',
    canActivate: [PublicGuard],
    loadComponent: () =>
      import('./features/auth/logout/logout.component').then(
        (mod) => mod.LogoutComponent
      ),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
