import { Injectable } from '@angular/core';
import { AuthStore, initialState } from 'src/app/shared/stores/auth/auth.store';
import { Observable, catchError, from, map, of } from 'rxjs';
import { Tenant } from '../../../../src/app/core/models/enums/Tenant';
import { environment } from 'src/environments/environment.e2e';
import { AuthState } from '../../../../src/app/core/models/interfaces/authState';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceTest {
  constructor(private authStore: AuthStore) {}

  async authenticateUser(): Promise<any> {
    try {
      await this.createUserData();
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  async createUserData(): Promise<AuthState> {
    let email = environment.TEST_EMAIL;
    let userName = environment.TEST_USER_NAME;
    const authData = {
      tokens: {
        idToken: 'idToken',
        refreshToken: 'refreshToken',
        accessToken: 'accessToken',
      },
      user: {
        email: email,
        username: userName,
        firstName: 'Test',
        lastName: 'TestUser',
      },
      tenant: Tenant.CAEDGE,
    };
    this.authStore.setState(authData);
    return authData;
  }

  get retrieveUserData$(): Observable<AuthState> {
    return from(this.createUserData()).pipe(
      catchError((error) => {
        console.error(error);
        return of(initialState);
      })
    );
  }

  get userIsAuthenticated$(): Observable<boolean> {
    return from(this.authenticateUser()).pipe(
      map(() => true),
      catchError((error) => {
        console.error(error);
        return of(false);
      })
    );
  }
}
