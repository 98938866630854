<div>
  <h2 class="header" mat-dialog-title>
    {{ 'Project.AddProjectDialog.Title' | translate }}
  </h2>
  <h2 class="sub-title" mat-dialog-title>
    {{ steps[currentStepIndex].title }}
  </h2>
</div>
<app-general-information class="project-information"
  [hidden]="currentStepIndex !== 0"
  [numCharsTenant]="numCharsTenant"
  #stepComponent
></app-general-information>
<app-contact-information class="project-information" [hidden]="currentStepIndex !== 1" #stepComponent></app-contact-information>
<app-billing-information class="project-information" [hidden]="currentStepIndex !== 2" #stepComponent></app-billing-information>

<div class="footer">
  <div class="pagination">{{ currentStepIndex + 1 }}/{{ steps.length }}</div>
  <div class="actions" mat-dialog-actions align="end">
    <button class="cancel" mat-flat-button mat-dialog-close>
      {{ 'General.Cancel' | translate }}
    </button>

    <button class="pagination-button"
    *ngIf="!isFirstStep()" (click)="prevStep()" mat-flat-button>
      {{ 'General.Back' | translate }}
    </button>

    <button class="pagination-button"
    *ngIf="!isLastStep()" (click)="nextStep()" mat-flat-button>
      {{ 'General.Next' | translate }}
    </button>

    <button
      class="add-project-btn"
      mat-flat-button
      type="submit"
      (click)="submitForm()"
      [disabled]="!isValid()"
      mat-dialog-close
    >
      {{ 'Project.AddProjectDialog.AddProject' | translate }}
    </button>
  </div>
</div>
