<mat-expansion-panel
  class="details"
  [expanded]="expanded"
  [hideToggle]="hideToggle"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="title-col">
        <span class="title">{{ title }}</span>
        <span class="description"> {{ description }}</span>
      </div>
    </mat-panel-title>
    <mat-panel-description *ngIf="!hideMenu">
      <mat-icon [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        more_vert
      </mat-icon>
      <ng-container> </ng-container>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of menuItems"
          (click)="eventEmitter.emit(item.command)"
          [disabled]="item.disabled"
          mat-menu-item
        >
          {{ item.translationKey | translate }}
        </button>
      </mat-menu>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="application-versions">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
<mat-divider class="divider" *ngIf="!hideDivider"></mat-divider>
