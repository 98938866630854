import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { ProjectsStore } from 'src/app/shared/stores/projects/projects.store';
import { ConfigStore } from 'src/app/shared/stores/config/config.store';
import { AppConfig } from 'src/app/shared/stores/config/models/appConfig';
import { Tenant } from '../../models/enums/Tenant';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStore,
    private projectsStore: ProjectsStore
  ) {}

  /**
   * This function is used to fetch the JSON config structure
   * required to build the routes and sidebar items present
   * in the defined projects. Config files are tenant specific,
   *  and the projectId and applicationId need to be passed down
   *  as arguments to be able to build the sidebar item urls.
   *
   *  This function is being used in the APP_INITIALIZER initialization,
   *  before the router navigation is enabled. This allows us
   *  to fetch and build all required routes before allowing navigation
   *  in the application.
   *
   *  If the tenant argument is NO_TENANT, then it means we are not
   *  authenticated and we don't build any dynamic routes
   *
   * @param {Tenant} tenant - [The tenant to which the config structure belongs]
   * @param {string} projectId - [Id of the current project taken from the url ('' by default)]
   * @param {string} applicationId - [Id of the current application('' by default)]
   * @returns {Observable<AppConfig>} [Observable containing the raw AppConfig structure]
   */
  getConfigJSON(
    tenant: Tenant,
    projectId: string,
    applicationId: string
  ): Observable<AppConfig> {
    if (tenant === Tenant.NO_TENANT) {
      this.projectsStore.setState({
        projects: [],
        isLoading: false,
        hasError: false,
      });
      return of({ projects: [] });
    }
    const tenantName = tenant.toLowerCase();
    const url = `assets/static/config-${tenantName}-${environment.AWS_STAGE}.json`;
    return this.http.get<AppConfig>(url).pipe(
      tap((config) => {
        this.configStore.initState(config, projectId, applicationId);

        this.projectsStore.setState({
          projects: config.projects.map((project) => ({
            id: project.id,
            abbreviation: project.abbreviation,
            label: project.label,
            description: project.description,
            createdDate: project.createdDate,
          })),
          isLoading: false,
          hasError: false,
        });
      }),
      catchError((err) => {
        this.projectsStore.setState({
          projects: [],
          isLoading: false,
          hasError: true,
        });
        console.error(err);
        return of({ projects: [] });
      })
    );
  }
}
