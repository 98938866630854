import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  from,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { AuthTokens } from '../../models/interfaces/authState';
import { AuthService } from '../../services/auth/auth.service';
import { Auth } from '@aws-amplify/auth';

enum Constants {
  MILLISECONDS_IN_ONE_SECOND = 1000,
  HEXADECIMAL_RADIX = 16,
  LAST_TWO_CHARACTERS = -2,
  CHAR_PREFIX = '00',
}

export type Session = Omit<
  Awaited<ReturnType<typeof Auth.currentSession>>,
  'isValid'
>;
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authStore: AuthStore, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.userIsAuthenticated$.pipe(
      // Auth.currentSession() automatically gets new tokens if the current ones have expired and a valid refreshToken is present
      withLatestFrom(this.getCurrentSession()),
      switchMap(([isAuthenticated, session]) => {
        if (isAuthenticated && session) {
          return this.handleTokens(request, next, session);
        }
        return next.handle(request);
      })
    );
  }

  getCurrentSession(): Observable<Session | null> {
    return from(Auth.currentSession()).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

  handleTokens(
    request: HttpRequest<any>,
    next: HttpHandler,
    session: Session
  ): Observable<HttpEvent<any>> {
    const tokens: AuthTokens = {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
    };

    const originalRequestAuth = request.headers.get('Authorization');

    if (originalRequestAuth) {
      const currentToken = this.decodeJwt(originalRequestAuth);
      const tokensExpired =
        currentToken.exp <
        Math.floor(Date.now() / Constants.MILLISECONDS_IN_ONE_SECOND);
      if (tokensExpired) {
        this.authStore.setState({ ...this.authStore.state, tokens });
      }
    }

    request = request.clone({
      setHeaders: { Authorization: tokens.idToken },
    });

    return next.handle(request);
  }

  decodeJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((character) => {
          return (
            '%' +
            (
              Constants.CHAR_PREFIX +
              character.charCodeAt(0).toString(Constants.HEXADECIMAL_RADIX)
            ).slice(Constants.LAST_TWO_CHARACTERS)
          );
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };
}
