import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SYSDAQChildJobs, SYSDAQFlatChildJob } from '../../models/jobDetails';
import { MatSort } from '@angular/material/sort';
import { forkJoin, map, Observable } from 'rxjs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { take } from 'rxjs/operators';
import { ChipComponent, ChipVariant } from '../../../../shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { SimulationJobMonitoringService } from '../../services/simulation-job-monitoring.service';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';

export type ChildJobApi = 'getChildJobsSimulation' | 'getChildJobSteps' | 'requestStepDownload';

@Component({
  selector: 'app-child-jobs',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    ChipComponent,
    MatIconModule
  ],
  templateUrl: './child-jobs.component.html',
  styleUrls: ['./child-jobs.component.scss']
})
export class ChildJobsComponent extends FeatureComponent<ChildJobApi> implements OnChanges {
  protected readonly ChipVariant = ChipVariant;
  @Input() runID: string | undefined = undefined;

  @ViewChild(MatSort)
  set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  allChildJobs: SYSDAQChildJobs | null = {
    Count: 0,
    Items: []
  };

  displayedColumns: string[] = [
    'index',
    'recId',
    'InputFileName',
    'SectionStart',
    'SectionEnd',
    'JobExitCode',
    'Steps',
    'Status',
    'link'
  ];
  dataSource = new MatTableDataSource<SYSDAQFlatChildJob>([]);
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;

  constructor(private simulationJobMonitoringService: SimulationJobMonitoringService) {
    super();
    this.fetchChildJobs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['runID'] && changes['runID'].currentValue) {
      this.fetchChildJobs();
    }
  }

  private fetchChildJobs() {
    if (!this.runID) return;
    if (!this.API) return;

    const allChildJobs$: Observable<SYSDAQChildJobs> = this.simulationJobMonitoringService.getAllChildJobs(
      this.API.getChildJobsSimulation,
      this.runID
    );

    const failedChildJobs$: Observable<SYSDAQChildJobs> = this.simulationJobMonitoringService.getFailedChildJobs(
      this.API.getChildJobsSimulation,
      this.runID
    );

    forkJoin([allChildJobs$, failedChildJobs$])
      .pipe(
        take(1),
        map((response) => {
          response[0].Items.forEach((childJob) => {
            childJob.ChildJobData.isFailed = response[1].Items.some(
              (job) => job.ChildJobData.InputFileName === childJob.ChildJobData.InputFileName
            );
          });
          return response[0];
        })
      )
      .subscribe((jobs) => {
        this.setDataSource(jobs);
      });
  }

  private setDataSource(jobs: SYSDAQChildJobs) {
    this.allChildJobs = jobs;
    const flatChildJobs: SYSDAQFlatChildJob[] = [];
    this.allChildJobs?.Items.forEach((item) => {
      flatChildJobs.push({
        RunId: item.RunId,
        ChildJobIndex: item.ChildJobIndex,
        ...item.ChildJobData
      });
    });
    this.dataSource.data = flatChildJobs;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable() {
    // TODO: export to CSV
  }

  openStepsDialog(childJobIndex: string) {
    this.simulationJobMonitoringService.openStepsDialog(
      this.runID,
      childJobIndex,
      this.API?.getChildJobSteps,
      this.API?.requestStepDownload
    );
  }
}
