<div>
  <h2 mat-dialog-title>{{ title | translate }}</h2>
</div>
<div mat-dialog-content class="content">
  <p *ngIf="!this.data.isNewlyCreated">
    {{ "DeviceList.ChangeEnvDialog.DeploymentsWillStay" | translate }}
  </p>
  <p>
    {{ "DeviceList.ChangeEnvDialog.DownloadTheFile" | translate }}
  </p>
  <div>
    <p *ngIf="this.data.isNewlyCreated">
      {{ "DeviceList.ChangeEnvDialog.ThisWillConnect" | translate }}
    </p>
    <p>{{ "DeviceList.ChangeEnvDialog.InternetConnection" | translate }}</p>
  </div>
  <mat-form-field class="caedge-form-field-outline">
    <mat-label>{{
      "DeviceList.FilterOptions.Environment" | translate
    }}</mat-label>
    <mat-select [formControl]="selectedEnvFormControl">
      <mat-option value="Development">{{
        "DeviceList.FilterKeys.Environment.Development" | translate
      }}</mat-option>
      <mat-option value="QA">{{
        "DeviceList.FilterKeys.Environment.QA" | translate
      }}</mat-option>
      <!-- Production will be included when there is production API available-->
      <!-- <mat-option value="Production">Production</mat-option> -->
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-flat-button [mat-dialog-close]="false">
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    mat-flat-button
    [mat-dialog-close]="true"
    (click)="downloadZipClick()"
  >
    {{ "DeviceList.ChangeEnvDialog.DownloadZIP" | translate }}
  </button>
</div>
