import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { Method } from 'src/app/shared/stores/config/models/method';
import { GeneralInformationComponent } from './steps/general-information/general-information.component';
import { ContactInformationComponent } from './steps/contact-information/contact-information.component';
import { BillingInformationComponent } from './steps/billing-information/billing-information.component';
import { StepComponent } from './models/stepComponent';
import { CreateProjectRequestBody } from './models/createProjectRequestBody';
import { ProjectApiService } from '../../services/projects/project-api.service';
import { BreadcrumbsStore } from 'src/app/shared/stores/config/breadcrumbs.store';
import { TenantId } from '../../../../core/models/enums/TenantId';

export interface AddProjectData {
  createProjectApiRecord: ApiRecord;
  unsubscribe$: Observable<void>;
}

/**
 * Component handling the project creation dialog.
 *
 * @export
 * @class AddProjectDialogComponent
 */
@Component({
  selector: 'app-add-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    GeneralInformationComponent,
    ContactInformationComponent,
    BillingInformationComponent
  ],
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss']
})
export class AddProjectDialogComponent implements OnInit, OnDestroy {
  @ViewChildren('stepComponent') stepComponents!: QueryList<StepComponent>;

  private readonly unsubscribe$: Subject<void> = new Subject();
  private data: AddProjectData;
  private tenant = '';
  private readonly breadcrumbs$ = this.breadcrumbStore.breadcrumbs$;

  public currentStepIndex = 0;
  numCharsTenant = 0;
  public readonly steps = [
    {
      title: this.translate.instant('Project.AddProjectDialog.GeneralInformation')
    },
    {
      title: this.translate.instant('Project.AddProjectDialog.ContactInformation')
    },
    {
      title: this.translate.instant('Project.AddProjectDialog.BillingInformation')
    }
  ];

  constructor(
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private projectApiService: ProjectApiService,
    private translate: TranslateService,
    private breadcrumbStore: BreadcrumbsStore
  ) {
    //temp solution as long it is not defined how to set up the config
    this.data = {
      createProjectApiRecord: { url: 'dummy/projects', method: Method.POST },
      unsubscribe$: this.unsubscribe$
    };
  }

  ngOnInit(): void {
    this.breadcrumbs$.pipe(take(1)).subscribe((breadcrumbs) => {
      this.tenant = breadcrumbs[0].label;
      this.numCharsTenant = this.tenant.length;
    });
  }

  // Sets the current step to the next step, in case it is possible
  nextStep() {
    if (this.isLastStep()) {
      return;
    }
    this.currentStepIndex++;
  }

  // Sets the current step to the previous step, in case it is possible
  prevStep() {
    if (this.isFirstStep()) {
      return;
    }
    this.currentStepIndex--;
  }

  /**
   * Checks if the first step is active.
   *
   * @return true if the first step is active, otherwise false.
   */
  isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  /**
   * Checks if the last step is active.
   *
   * @return true if the last step is active, otherwise false.
   */
  isLastStep(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }

  /**
   * Checks if the every form entry is valid.
   *
   * @return true if the form is valid, otherwise false.
   */
  isValid(): boolean {
    if (this.stepComponents) {
      return this.stepComponents.toArray().every((step) => step.stepValid);
    } else {
      return false;
    }
  }

  submitForm(): void {
    const dataToUpdate: any = this.stepComponents.toArray().reduce((result, step) => {
      if (!(step instanceof ContactInformationComponent)) {
        return Object.assign(result, step.dataToUpdate);
      } else {
        return result;
      }
    }, {});

    const contactComponent: any = this.stepComponents.filter(
      (element) => element instanceof ContactInformationComponent
    );
    dataToUpdate['Contacts'] = {
      ...dataToUpdate['Contacts'],
      ...contactComponent[0].dataToUpdate['Contacts']
    };

    const requestBody: CreateProjectRequestBody = {
      TenantId: TenantId[this.tenant as keyof typeof TenantId],
      Description: '',
      Name: '',
      Billing: {
        LegalEntity: '',
        ControllingContact: '',
        WBSElement: '',
        OrgMapping: {
          CostCenter: '',
          CostCenterOwner: ''
        }
      },
      Contacts: {
        Billing: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: ''
        },
        ProjectOwner: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: ''
        },
        ProjectCoOwner: [],
        SecurityCentralOps: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: ''
        },
        SecurityProjectOwner: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: ''
        },
        Technical: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: ''
        }
      }
    };

    Object.assign(requestBody, dataToUpdate);

    this.projectApiService
      .createProject(this.data.createProjectApiRecord, requestBody)
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo('Creating a new project...');
        },
        error: () => {
          this.snackbarService.notifyError("Couldn't create the project");
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
