import { ChipVariant } from 'src/app/shared/components/chip/chip.component';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { DeviceFwStatus } from 'src/app/shared/stores/devices/models/deviceFwStatus';
import { DeviceInstanceState } from 'src/app/shared/stores/devices/models/deviceInstanceState';
import { DeviceInstanceStatus } from 'src/app/shared/stores/devices/models/deviceInstanceStatus';
import { deviceStatusLabelMap } from 'src/app/shared/stores/devices/models/deviceStatusLabelMap';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';

export const inactiveDisconnectReason = 'NEWLY_CREATED';

export const deviceIsInactive = (device: Device) => {
  return (
    device.disconnectedReason === inactiveDisconnectReason &&
    device.instanceType === DeviceType.REAL
  );
};

export const getDeviceChipLabel = (device: Device) => {
  if (device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTING) {
    return 'DeviceList.Connecting';
  }
  if (device.instanceState === DeviceInstanceState.STOPPING) {
    return 'DeviceList.Stopping';
  }
  if (!device.instanceConnectionStatus) {
    return 'DeviceList.InstanceStatus.Creating';
  }
  if (deviceIsInactive(device)) {
    return 'DeviceList.Inactive';
  }
  return device.instanceStatus === DeviceInstanceStatus.CREATION_SUCCESSFUL
    ? 'DeviceList.FilterKeys.ConnectionStatus.' +
        deviceStatusLabelMap[device.instanceConnectionStatus]
    : 'DeviceList.InstanceStatus.' +
        deviceStatusLabelMap[device.instanceStatus];
};

export const getDeviceChipType = (device: Device): ChipVariant => {
  if (
    device.instanceStatus === DeviceInstanceStatus.CREATE_REQUESTED ||
    device.instanceStatus === DeviceInstanceStatus.CREATE_IN_PROGRESS ||
    device.instanceStatus === DeviceInstanceStatus.DELETE_IN_PROGRESS ||
    !device.instanceConnectionStatus ||
    device.instanceState === DeviceInstanceState.STOPPING ||
    device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTING
  ) {
    return ChipVariant.WARN;
  } else if (
    device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED
  ) {
    return ChipVariant.SUCCESS;
  } else if (deviceIsInactive(device)) {
    return ChipVariant.DISABLED;
  } else {
    return ChipVariant.ERROR;
  }
};

export const getDeviceTypeTooltipKey = (device: Device) => {
  switch(device.instanceType) {
    case DeviceType.REAL: return 'DeviceList.RealDevice';
    case DeviceType.SIMULATED: return 'DeviceList.SimulatedDevice';
    case DeviceType.VHPC: return 'DeviceList.VHPCDevice';
  }
};

export const hasDeviceFwUpdateFailed = (device: Device) => {
  return device.lastFwUpdate?.status === DeviceFwStatus.FAILED;
};
