import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { GenericStateMatcher } from 'src/app/shared/utils/genericStateMatcher';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { DeviceListApiService } from '../../services/device-list-api/device-list-api.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { forbidCaedgeNaming } from 'src/app/shared/utils/caedge-name-validator/caedge-name-validator';

export interface CreateDeviceDialogData {
  deviceType: DeviceType;
  apiRecords: Record<HdkEnvironment | 'toolchain' | 'vHPC', ApiRecord>;
  unsubscribe$: Subject<void>;
}

@Component({
  selector: 'app-create-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './create-device-dialog.component.html',
  styleUrls: ['./create-device-dialog.component.scss'],
})
export class CreateDeviceDialogComponent {
  DeviceType = DeviceType;
  createDeviceFormGroup: FormGroup = new FormGroup({
    deviceName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z0-9-]+$/),
      forbidCaedgeNaming,
    ]),
    selectedEnv: new FormControl({
        value: HdkEnvironment.DEVELOPMENT,
        disabled: this.isVHPCDevice()
      }, 
      [
        Validators.required,
      ]
    ),
  });

  errorMatcher = new GenericStateMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateDeviceDialogData,
    private deviceApiService: DeviceListApiService,
    private snackbarService: SnackbarService
  ) {}

  get deviceName(): string {
    return this.createDeviceFormGroup.get('deviceName')?.value;
  }

  get selectedEnv(): HdkEnvironment {
    return this.createDeviceFormGroup.get('selectedEnv')?.value;
  }

  get titleKey() {

    let dialogTitle = 'DeviceList.CreateDialog.';

    switch(this.data.deviceType) {
      case DeviceType.REAL:
        dialogTitle += 'TitleReal';
        break;
      case DeviceType.SIMULATED:
        dialogTitle += 'TitleSim';
        break;
      case DeviceType.VHPC:
        dialogTitle += 'TitleVHPC';
        break;
    }

    return dialogTitle;
  }

  isSimulatedDevice(): boolean {
    return this.data.deviceType === DeviceType.SIMULATED;
  }

  private isVHPCDevice(): boolean {
    return this.data.deviceType === DeviceType.VHPC;
  }

  canSubmit() {
    return this.createDeviceFormGroup.valid;
  }

  private apiRecordSelector(deviceType: DeviceType): ApiRecord {
    switch(deviceType) {
      case DeviceType.REAL: 
        return this.data.apiRecords.toolchain;
      case DeviceType.SIMULATED:
        return this.data.apiRecords[this.selectedEnv];
      case DeviceType.VHPC:
        return this.data.apiRecords.vHPC;
    }
  }

  handleSubmitClick() {
    this.deviceApiService
      .createDevice(
        this.deviceName,
        this.data.deviceType,
        this.apiRecordSelector(this.data.deviceType)
      )
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: () =>
          this.snackbarService.notifyInfo('Device creation initiated'),
        error: () =>
          this.snackbarService.notifyError('Device creation failed!'),
      });
  }
}
