import { DeviceType } from '../deviceType';
import { DeviceInstanceStatus } from '../deviceInstanceStatus';
import { DeviceConnectionStatus } from '../deviceConnectionStatus';
import { HdkEnvironment } from '../../../../../core/models/interfaces/hdkEnvironment';
import { DeviceFwStatus } from '../deviceFwStatus';

interface ILastFwUpdate {
  status: DeviceFwStatus;
  targetVersion: string;
  errorMessage: string;
  lastStatusUpdatedAt: string;
}

export interface IDataDevice {
  hwName: string;
  instancetype: DeviceType;
  creationdate: string;
  'disconnect-reason': string;
  'instance-status': DeviceInstanceStatus;
  'instance-state': string;
  'instance-conn-status': DeviceConnectionStatus;
  fwVersion?: string;
  fwUpdate?: boolean;
  lastFwUpdate?: ILastFwUpdate;
  number_of_deployments: string | undefined;
}

export interface IDevice {
  environments: HdkEnvironment[];
  connectedEnvironments: HdkEnvironment[];
  hwName: string;
  instanceType: DeviceType;
  creationDate: string;
  instanceStatus: DeviceInstanceStatus;
  instanceConnectionStatus: DeviceConnectionStatus;
  fwVersion?: string;
  fwUpdate?: boolean;
  lastFwUpdate?: ILastFwUpdate;
  numOfDeployments: string;
}

export class Device implements IDevice {
  environments!: HdkEnvironment[];
  connectedEnvironments!: HdkEnvironment[];
  hwName!: string;
  instanceType!: DeviceType;
  creationDate!: string;
  instanceStatus!: DeviceInstanceStatus;
  disconnectedReason!: string;
  instanceConnectionStatus!: DeviceConnectionStatus;
  instanceState?: string;
  fwVersion?: string;
  fwUpdate?: boolean;
  lastFwUpdate?: ILastFwUpdate | undefined;
  numOfDeployments!: string;
  public static Factory(device: IDataDevice, env: HdkEnvironment): Device {
    const newDevice = new Device();
    newDevice.environments = [];
    newDevice.connectedEnvironments = [];
    newDevice.hwName = device.hwName;
    newDevice.creationDate = device.creationdate;
    newDevice.disconnectedReason = device['disconnect-reason'];
    newDevice.instanceConnectionStatus = device['instance-conn-status'];
    newDevice.instanceState = device['instance-state'];
    newDevice.instanceStatus = device['instance-status'];
    newDevice.instanceType = device.instancetype;
    newDevice.numOfDeployments = device.number_of_deployments ? device.number_of_deployments : 'N/A';

    if (newDevice.instanceType === DeviceType.REAL) {
      newDevice.fwUpdate = device.fwUpdate;
      newDevice.fwVersion = device.fwVersion;
      newDevice.lastFwUpdate = device.lastFwUpdate;
    }

    if (
      (
        newDevice.instanceType === DeviceType.SIMULATED ||
        newDevice.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED
      ) && !newDevice.environments.includes(env)
    ) {
      newDevice.environments.push(env);
    }

    if (newDevice.instanceType === DeviceType.REAL && !newDevice.environments.includes(env)) {
      newDevice.environments.push(env);
    }

    if(newDevice.instanceConnectionStatus === 'CONNECTED' && !newDevice.connectedEnvironments.includes(env)) {
      newDevice.connectedEnvironments.push(env);
    }

    return newDevice;
  }
}
