import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, switchMap } from 'rxjs';
import { Project } from '../../shared/stores/projects/models/project';
import { ProjectsStore } from '../../shared/stores/projects/projects.store';
import { ActivatedRoute } from '@angular/router';
import { DisplayDetailComponent } from '../application-dashboard/components/application-details/display-detail.component';
import {
  DetailsExpansionComponent,
  MenuCommand,
  MenuItem,
} from '../../shared/components/details-expansion/details-expansion.component';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-project-dashboard-details',
  standalone: true,
  imports: [
    CommonModule,
    DisplayDetailComponent,
    DetailsExpansionComponent,
    HeaderTemplateComponent,
    TranslateModule,
  ],
  templateUrl: './project-dashboard-details.component.html',
  styleUrls: ['./project-dashboard-details.component.scss'],
})
export class ProjectDashboardDetailsComponent {
  workbench = {
    Admin: 'Continental',
    Date: new Date().toString(),
  };
  menuItems: MenuItem[] = [
    {
      command: MenuCommand.EDIT,
      disabled: true,
      translationKey: 'General.Edit',
    },
  ];
  constructor(
    private projectsStore: ProjectsStore,
    private activatedRoute: ActivatedRoute
  ) {}

  project$: Observable<Project | null> = this.activatedRoute.params.pipe(
    switchMap((params) => {
      return this.projectsStore.getProjectById(params['projectId']);
    })
  );
}
