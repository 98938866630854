import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddApplicationDialogComponent } from '../components/add-application-dialog/add-application-dialog.component';
import { AddApplicationData } from '../components/add-application-dialog/add-application-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectDashboardService {
  constructor(public dialog: MatDialog) {}

  openDialog(data: AddApplicationData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.data = data;
    this.dialog.open(AddApplicationDialogComponent, dialogConfig);
  }
}
