import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import { SYSDAQJobDetails } from '../../models/jobDetails';
import { DetailsExpansionComponent } from '../../../../shared/components/details-expansion/details-expansion.component';
import { DisplayDetailComponent } from '../../../application-dashboard/components/application-details/display-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFormatDatePipe } from '../../../../core/pipes/custom-format-date.pipe';
import { ChildJobsComponent } from '../child-jobs/child-jobs.component';
import { catchError, combineLatest, Observable, of, Subject, takeUntil } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { CaeButtonComponent } from '../../../../shared/components/cae-button/cae-button.component';
import { MatIconModule } from '@angular/material/icon';
import { ChipComponent, ChipVariant } from '../../../../shared/components/chip/chip.component';
import { CurrentTab } from '../../simulation-job-monitoring.component';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';
import { SimulationJobMonitoringService } from '../../services/simulation-job-monitoring.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';

export type JobDetailsApi = 'getJobExecutionDetailsReporting' | 'getJobExecutionDetailsSimulation';

@Component({
  selector: 'app-job-details',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    DetailsExpansionComponent,
    DisplayDetailComponent,
    TranslateModule,
    CustomFormatDatePipe,
    ChildJobsComponent,
    CaeButtonComponent,
    MatIconModule,
    ChipComponent,
    MatProgressBarModule,
    MatButtonModule
  ],
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent extends FeatureComponent<JobDetailsApi> implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();

  tab = 0;
  loading = false;

  protected readonly Object = Object;
  protected readonly ChipVariant = ChipVariant;

  private childJobId = '';

  jobDetails$: Observable<SYSDAQJobDetails | null> = of(null);

  constructor(
    private simulationJobMonitoringService: SimulationJobMonitoringService,
    private clipboard: Clipboard,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initRouteListener();
  }

  private initRouteListener() {
    combineLatest([this.route.params, this.route.queryParams], (params, qparams) => ({ params, qparams }))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ap) => {
        this.childJobId = ap.params['jobId'];
        this.tab = parseInt(ap.qparams['tab']);
        this.fetchChildJobData();
      });
  }

  private fetchChildJobData() {
    if (!this.API) return;
    this.loading = true;
    const childJobApi =
      this.tab === CurrentTab.SIMULATION
        ? this.API.getJobExecutionDetailsSimulation
        : this.API.getJobExecutionDetailsReporting;
    this.jobDetails$ = this.simulationJobMonitoringService.getJobDetails(childJobApi, this.childJobId).pipe(
      catchError((err) => {
        this.loading = false;
        return of(err);
      })
    );
    this.loading = false;
  }

  copyToClipboard(data: string[]) {
    const copy = data.toString();
    this.clipboard.copy(copy);
    this.snackbarService.notifyInfo('Copied');
  }

  protected readonly CurrentTab = CurrentTab;

  openDeleteDialog() {
    //ToDo implementation
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
