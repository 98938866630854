import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { StartDeploymentApi } from 'src/app/core/services/start-deployment/start-deployment.service';
import { DeviceListApiService } from '../../../device-list/services/device-list-api/device-list-api.service';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { StartDeploymentStore } from 'src/app/shared/stores/start-deployment/start-deployment.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeploymentService } from 'src/app/core/services/deployment-list/deployment.service';
import { Method } from 'src/app/shared/stores/config/models/method';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { PipelineStatus } from 'src/app/shared/stores/deployment/models/pipeline';
import { ApplicationListApiService } from 'src/app/features/project-dashboard/components/application-list/services/application-list-api.service';

export interface StartDeploymentDialogData {
  appName: string;
  apiRecords: StartDeploymentApi;
  predefinedValues: {
    versionId?: string;
    deviceName?: string;
    environment?: HdkEnvironment;
  };
  unsubscribe$: Subject<void>;
}

@Component({
  selector: 'app-new-deployment',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    ContentWrapperComponent,
  ],
  templateUrl: './new-deployment.component.html',
  styleUrls: ['./new-deployment.component.scss'],
})
export class NewDeploymentComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  readonly selectedEnv$ = new BehaviorSubject(HdkEnvironment.DEVELOPMENT);

  startDeploymentFormGroup: FormGroup = this.formBuilder.group({
    selectedApp: [
      this.data.appName,
      [Validators.required, Validators.nullValidator],
    ],
    selectedVersion: [
      {
        value: this.predefinedVersion || '',
        disabled: Boolean(this.predefinedVersion),
      },
      [Validators.required, Validators.nullValidator],
    ],
    selectedEnv: [
      {
        value: this.predefinedEnvironment || HdkEnvironment.DEVELOPMENT,
        disabled: Boolean(this.predefinedEnvironment),
      },
      [Validators.required],
    ],
    selectedDevice: [
      {
        value: this.predefinedDevice || '',
        disabled: Boolean(this.predefinedDevice),
      },
      [Validators.required, Validators.nullValidator],
    ],
  });

  createDeploymentApiRecordsMap: Record<HdkEnvironment, ApiRecord> = {
    [HdkEnvironment.DEVELOPMENT]: this.data.apiRecords.createDevDeployment,
    [HdkEnvironment.QA]: this.data.apiRecords.createQaDeployment,
    [HdkEnvironment.PRODUCTION]: {
      url: '',
      method: Method.POST,
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StartDeploymentDialogData,
    private formBuilder: FormBuilder,
    private deploymentsService: DeploymentService,
    private devicesApiService: DeviceListApiService,
    private startDeploymentStore: StartDeploymentStore,
    private snackbarService: SnackbarService,
    private applicationsService: ApplicationListApiService
  ) {}

  startDeploymentData$ = combineLatest([
    this.startDeploymentStore.devices$,
    this.startDeploymentStore.applications$,
    this.startDeploymentStore.versions$,
    this.startDeploymentStore.isLoading$,
    this.startDeploymentStore.hasError$,
    this.selectedEnv$,
  ]).pipe(
    map(([devices, applications, versions, isLoading, hasError, env]) => {
      const selectedVersionControl =
        this.startDeploymentFormGroup.get('selectedVersion');
      const selectedDeviceControl =
        this.startDeploymentFormGroup.get('selectedDevice');
      const filteredDevices = devices.filter((device) =>
        device.environments.includes(env)
      );

      if (versions.length === 0 && !this.predefinedVersion) {
        selectedVersionControl?.disable();
        selectedVersionControl?.setValue('');
      } else if (!this.predefinedVersion) {
        selectedVersionControl?.enable();
      }

      if (filteredDevices.length === 0 && !this.predefinedDevice) {
        selectedDeviceControl?.disable();
        selectedDeviceControl?.setValue('');
      } else if (!this.predefinedDevice) {
        selectedDeviceControl?.enable();
      }

      return { filteredDevices, applications, versions, isLoading, hasError };
    })
  );

  get predefinedVersion() {
    return this.data.predefinedValues.versionId;
  }

  get predefinedDevice() {
    return this.data.predefinedValues.deviceName;
  }

  get predefinedEnvironment() {
    return this.data.predefinedValues.environment;
  }

  get selectedApp(): HdkEnvironment {
    return this.startDeploymentFormGroup.get('selectedApp')?.value;
  }

  get selectedEnv(): HdkEnvironment {
    return this.startDeploymentFormGroup.get('selectedEnv')?.value;
  }

  get selectedVersion(): string {
    return this.startDeploymentFormGroup.get('selectedVersion')?.value;
  }

  get selectedDevice(): string {
    return this.startDeploymentFormGroup.get('selectedDevice')?.value;
  }

  get canSubmit() {
    return Boolean(
      this.startDeploymentFormGroup.valid &&
        this.selectedDevice &&
        this.selectedVersion
    );
  }

  startDeploymentClick() {
    this.deploymentsService
      .deleteOrStartDeployment(
        this.selectedApp,
        this.selectedVersion,
        this.selectedDevice,
        this.createDeploymentApiRecordsMap[this.selectedEnv]
      )
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo('Deployment initiated');
        },
        error: () => {
          this.snackbarService.notifyError('Deployment failed');
        },
      });
  }

  fetchDevices() {
    if (
      !(this.data.apiRecords.getDevDevices && this.data.apiRecords.getQaDevices)
    ) {
      return;
    }
    this.devicesApiService
      .getDevices(
        this.data.apiRecords.getDevDevices,
        this.data.apiRecords.getQaDevices
      )
      .pipe(
        map((devices) =>
          devices.filter(
            (device) =>
              device.instanceConnectionStatus ===
              DeviceConnectionStatus.CONNECTED
          )
        ),
        takeUntil(this.data.unsubscribe$)
      )
      .subscribe({
        next: (devices) =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            devices,
            areDevicesLoading: false,
          }),
        error: () =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            areDevicesLoading: false,
            hasError: true,
          }),
      });
  }

  fetchApplications() {
    if (!this.data.apiRecords.getApplications || this.selectedApp) {
      return;
    }
    this.applicationsService
      .getApplications(this.data.apiRecords.getApplications)
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: (applications) =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            applications,
            areApplicationsLoading: false,
            areVersionsLoading: false,
          }),
        error: () =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            areApplicationsLoading: false,
            areVersionsLoading: false,
            hasError: true,
          }),
      });
  }

  fetchVersions() {
    if (!this.data.apiRecords.getVersions || !this.selectedApp) {
      return;
    }
    this.deploymentsService
      .getAppVersions(this.selectedApp, this.data.apiRecords.getVersions)
      .pipe(
        map((versions) =>
          versions.filter(
            (version) =>
              version.executionStatus === PipelineStatus.PIPELINE_BUILD_SUCCESS
          )
        ),
        takeUntil(this.data.unsubscribe$)
      )
      .subscribe({
        next: (versions) =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            versions,
            areVersionsLoading: false,
          }),
        error: () =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            areVersionsLoading: false,
            hasError: true,
          }),
      });
  }

  checkPredifinedValues() {
    if (this.data.appName) {
      this.startDeploymentStore.setState({
        ...this.startDeploymentStore.state,
        areApplicationsLoading: false,
      });
    }

    if (this.predefinedDevice) {
      this.startDeploymentStore.setState({
        ...this.startDeploymentStore.state,
        areDevicesLoading: false,
      });
    }

    if (this.predefinedVersion) {
      this.startDeploymentStore.setState({
        ...this.startDeploymentStore.state,
        areVersionsLoading: false,
      });
    }
  }

  initData() {
    this.checkPredifinedValues();
    this.fetchDevices();
    this.fetchApplications();
    this.fetchVersions();

    this.startDeploymentFormGroup
      .get('selectedEnv')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((env) => {
        this.selectedEnv$.next(env);
      });

    this.startDeploymentFormGroup
      .get('selectedApp')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.fetchVersions();
      });
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
