export const generatePath = (
  routePath: string,
  params: Record<string, string | number>
): string => {
  let replacedPath = routePath;

  for (const key in params) {
    const value = params[key];
    replacedPath = replacedPath.replace(`:${key}`, String(value));
  }

  return replacedPath;
};
