export interface SYSDAQJobDetails {
  RunId: string;
  SubmissionDateTime: string;
  StartDateTime: string;
  EndDateTime: string;
  WorkflowName: string;
  Status: string;
  Caller: string;
  Cost: string;
  RunData: {
    RECIDS: string[];
    output_location: string;
    input_location: string;
    JobStates: {
      ChildJobCount?: number;
      FailedJobs?: number;
      FinishedChildJobs?: number;
      JobCount?: number;
    };
    workflow_configuration: Record<string, string>[];
  };
}

export interface SYSDAQChildJobs {
  Count: number;
  Items: SYSDAQChildJob[];
}

export interface SYSDAQChildJob {
  RunId: string;
  ChildJobIndex: string;
  ChildJobData: ChildJobData;
}

export interface SYSDAQFlatChildJob {
  RunId: string;
  ChildJobIndex: string;
  isFailed?: boolean;
  RECID: string;
  InputFileName: string;
  SectionEnd: string;
  SectionStart: string;
  JobExitCode?: string;
  StepNames: string[];
  [key: string]: any;
}

export interface ChildJobData {
  isFailed?: boolean;
  RECID: string;
  InputFileName: string;
  SectionEnd: string;
  SectionStart: string;
  JobExitCode?: string;
  StepNames: string[];
  [key: string]: any;
}

export class SYSDAQJobDetails {
  public static Factory(job: SYSDAQJobDetails) {
    const newJob = new SYSDAQJobDetails();
    Object.assign(newJob, job);
    return newJob;
  }
}

export class SYSDAQChildJobs {
  public static Factory(job: SYSDAQChildJobs) {
    const newJob = new SYSDAQChildJobs();
    Object.assign(newJob, job);
    return newJob;
  }
}

export class SYSDAQChildJob {
  public static Factory(step: SYSDAQChildJob) {
    const newStep = new SYSDAQChildJob();
    Object.assign(newStep, step);
    return newStep;
  }
}
