import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

const errorMap = new Map(
  Object.entries({
    400: 'Http.Errors.BadRequest',
    401: 'Http.Errors.Unauthorized',
    403: 'Http.Errors.Forbidden',
    404: 'Http.Errors.NotFound',
    500: 'Http.Errors.InternalServerError',
  }).map(([key, value]) => [Number(key), value])
);

@Injectable()
export class GlobalHttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        return throwError(() => err);
      })
    );
  }

  getErrorMessage(errorStatus: number) {
    const translationKey = errorMap.get(errorStatus) ?? 'Http.Errors.Unknown';
    return this.translate.instant(translationKey);
  }
}
