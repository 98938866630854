import { Component, Input, OnChanges, OnDestroy, OnInit, signal, SimpleChanges } from '@angular/core';
import {
  getDeviceChipLabel,
  getDeviceChipType,
  getDeviceTypeTooltipKey,
  hasDeviceFwUpdateFailed,
} from '../../utils/helpers';
import { MatButtonModule } from '@angular/material/button';
import {
  ChipComponent,
  ChipVariant,
} from 'src/app/shared/components/chip/chip.component';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { DeviceListApiService } from '../../services/device-list-api/device-list-api.service';
import { Subject, map, take, takeUntil } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DeviceInstanceStatus } from 'src/app/shared/stores/devices/models/deviceInstanceStatus';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Method } from 'src/app/shared/stores/config/models/method';
import { DeviceInstanceState } from 'src/app/shared/stores/devices/models/deviceInstanceState';
import { MatDialog } from '@angular/material/dialog';
import { ChangeEnvDialogComponent } from './components/change-env-dialog/change-env-dialog.component';
import { StartDeploymentService } from 'src/app/core/services/start-deployment/start-deployment.service';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { DeviceListApi } from '../../device-list.component';
import { StartDeploymentStore } from 'src/app/shared/stores/start-deployment/start-deployment.store';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { SimulatedActionButtonsComponent } from './components/simulated-action-buttons/simulated-action-buttons.component';
import { FwUpdatesActionsComponent } from './components/fw-updates-actions/fw-updates-actions.component';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';
import { toInteger } from 'lodash';
import { FileService } from 'src/app/core/services/file/file.service';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';

@Component({
  standalone: true,
  selector: 'app-device-list-item',
  templateUrl: './device-list-item.component.html',
  styleUrls: ['./device-list-item.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ChipComponent,
    TranslateModule,
    MatTooltipModule,
    CommonModule,
    SimulatedActionButtonsComponent,
    FwUpdatesActionsComponent,
  ],
})
export class DeviceListItemComponent
  extends FeatureComponent<DeviceListApi>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() device!: Device;
  @Input() isInactive: boolean = false;
  
  ChipVariant = ChipVariant;
  private readonly unsubscribe$: Subject<void> = new Subject();

  appName = '';
  envToolTip = '';
  deviceTypeTooltipSignal = signal<string>('');

  deviceActionApiConfigMap: Record<HdkEnvironment, ApiRecord> | undefined;
  deleteDeviceApiConfigMap: Record<HdkEnvironment | 'Toolchain' | 'Vhpc', ApiRecord> | undefined;
  getConnectionPackageApiConfigMap: Record<HdkEnvironment, ApiRecord> | undefined;
  getVersionsApiRecordsMap: Record<HdkEnvironment, ApiRecord> | undefined;
  updateFwVersionsApiRecordsMap: Record<HdkEnvironment, ApiRecord> | undefined;
  downloadScriptsApiRecordsMap: Record<HdkEnvironment, ApiRecord> | undefined;

  constructor(
    private dialogService: DialogService,
    private dialog: MatDialog,
    private deviceListApiService: DeviceListApiService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private startDeploymentService: StartDeploymentService,
    private startDeploymentStore: StartDeploymentStore,
    private routingStore: RoutingStore,
    private fileService: FileService
  ) {
    super();
    if (this.API) {
      this.deviceActionApiConfigMap = {
        [HdkEnvironment.DEVELOPMENT]: this.API.devDeviceActionRequest,
        [HdkEnvironment.QA]: this.API.qaDeviceActionRequest,
        // The prod will be added when prod API is available
        [HdkEnvironment.PRODUCTION]: { url: 'prodUrl', method: Method.PUT },
      };

      this.deleteDeviceApiConfigMap = {
        Toolchain: this.API.deleteRealDevice,
        Vhpc: this.API.deleteVhpcDevice,
        [HdkEnvironment.DEVELOPMENT]: this.API.deleteSimulatedDeviceDev,
        [HdkEnvironment.QA]: this.API.deleteSimulatedDeviceQa,
        // The prod will be added when prod API is available
        [HdkEnvironment.PRODUCTION]: { url: 'prodUrl', method: Method.DELETE },
      };

      this.getConnectionPackageApiConfigMap = {
        [HdkEnvironment.DEVELOPMENT]: this.API.getDevConnectionPackage,
        [HdkEnvironment.QA]: this.API.getQaConnectionPackage,
        // The prod will be added when prod API is available
        [HdkEnvironment.PRODUCTION]: { url: 'prodUrl', method: Method.GET },
      };

      this.getVersionsApiRecordsMap = {
        [HdkEnvironment.DEVELOPMENT]: this.API.getDevFwUpdates,
        [HdkEnvironment.QA]: this.API.getQaFwUpdates,
        // The prod will be added when prod API is available
        [HdkEnvironment.PRODUCTION]: { url: 'prodUrl', method: Method.GET },
      };

      this.updateFwVersionsApiRecordsMap = {
        [HdkEnvironment.DEVELOPMENT]: this.API.updateFwVersionDev,
        [HdkEnvironment.QA]: this.API.updateFwVersionsQa,
        // The prod will be added when prod API is available
        [HdkEnvironment.PRODUCTION]: { url: 'prodUrl', method: Method.POST },
      };

      this.downloadScriptsApiRecordsMap = {
        [HdkEnvironment.DEVELOPMENT]: this.API.downloadDeviceScriptsDev,
        [HdkEnvironment.QA]: this.API.downloadDeviceScriptsQa,
        // The prod will be added when prod API is available
        [HdkEnvironment.PRODUCTION]: { url: 'prodUrl', method: Method.GET },
      };
    }
  }

  getDeviceEnvLabel(device: Device) {
    if (device.connectedEnvironments.length !== 0) {
      return device.connectedEnvironments
        .map((env) => {
          return `DeviceList.FilterKeys.EnvironmentShort.${env}`;
        })
        .join('/');
    }
    return '';
  }

  getNumberRunningDeployments(device: Device): string {
    return device.numOfDeployments;
  }

  getRunningDeploymentsLabel(device: Device): string {
    return toInteger(device.numOfDeployments) > 1
      ? this.translate.instant('DeviceList.RunningDeployments')
      : this.translate.instant('DeviceList.RunningDeployment');
  }

  getStatusChipLabel(device: Device) {
    return getDeviceChipLabel(device);
  }

  getStatusChipVariant(device: Device) {
    return getDeviceChipType(device);
  }

  getDeviceTypeLabel(device: Device) {
    switch(device.instanceType) {
      case DeviceType.REAL: return 'REAL';
      case DeviceType.SIMULATED: return 'SIM';
      case DeviceType.VHPC: return 'vHPC';
    }
  }

  getDeleteDeviceApiRecord(device: Device): ApiRecord {
    if (!this.deleteDeviceApiConfigMap) {
      return {
        url: '',
        method: Method.DELETE
      };
    }
    switch(device.instanceType) {
      case DeviceType.REAL: return this.deleteDeviceApiConfigMap?.Toolchain;
      case DeviceType.SIMULATED: return this.deleteDeviceApiConfigMap?.[device.environments[0]];
      case DeviceType.VHPC: return this.deleteDeviceApiConfigMap?.Vhpc;
    }
  }

  deleteDeviceClick(device: Device) {
    this.deviceListApiService
      .deleteDevice(
        device.hwName,
        this.getDeleteDeviceApiRecord(device)
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo('Device deletion initiated');
        },
        error: () => {
          this.snackbarService.notifyError('Device deletion failed');
        },
      });
  }

  openChangeEnvironmentDialog(isNewlyCreated: boolean) {
    this.dialog.open(ChangeEnvDialogComponent, {
      data: {
        isNewlyCreated,
        device: this.device,
        getConnectionPackageApiConfigMap: this.getConnectionPackageApiConfigMap,
        unsubscribe$: this.unsubscribe$,
      },
      width: isNewlyCreated ? '448px' : '400px',
      autoFocus: false,
      disableClose: true,
    });
  }

  openDeleteDeviceDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('DeviceList.DeleteDialog.Title'),
      message: this.translate.instant('DeviceList.DeleteDialog.Text'),
      confirmText: this.translate.instant('DeviceList.DeleteDialog.Confirm'),
      width: '400px',
      onConfirm: () => this.deleteDeviceClick(this.device),
    });
  }

  openDownloadScriptDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('DeviceList.DownloadScriptDialog.Title'),
      message: this.translate.instant('DeviceList.DownloadScriptDialog.Text'),
      confirmText: this.translate.instant(
        'DeviceList.DownloadScriptDialog.Confirm'
      ),
      width: '400px',
      onConfirm: () => this.downloadDeviceZipFile(),
    });
  }

  downloadDeviceZipFile(): void {
    const fileNameOffSet = 1;

    if (!this.API) {
      return;
    }

    this.snackbarService.notifyInfo('Downloading...');
    this.deviceListApiService
      .getDeviceScripts(
        this.device.hwName,
        this.downloadScriptsApiRecordsMap![this.device.environments[0]]
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          const fileName: string = data.headers['Content-Disposition'];
          this.fileService.downloadBlob(
            this.fileService.b64toBlob(data.body, data.headers['Content-Type']),
            fileName.substring(fileName.indexOf(' ') + fileNameOffSet)
          );
          this.snackbarService.notifySuccess('Download successfull');
        },
        error: () => {
          this.snackbarService.notifyError('Download failed');
        },
      });
  }

  startDeploymentClick() {
    if (!this.API) {
      return;
    }
    this.startDeploymentService.openStartDeploymentDialog(
      this.appName,
      {
        createDevDeployment: this.API.createDevDeployment,
        createQaDeployment: this.API.createQaDeployment,
        getApplications: this.API.getApplications,
        getVersions: this.API.getAppVersions,
      },
      {
        deviceName: this.device.hwName,
        environment: this.device.environments[0],
      },
      this.unsubscribe$
    );
  }

  getFwChipVariant(): ChipVariant {
    if (this.hasUpdateFailed) {
      return ChipVariant.FW_ERROR;
    } else return ChipVariant.NEUTRAL_WHITE;
  }

  get isConnecting() {
    return this.device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTING;
  }

  get isStopping() {
    return this.device.instanceState === DeviceInstanceState.STOPPING;
  }

  get isBeingCreated() {
    return (
      this.device.instanceStatus === DeviceInstanceStatus.CREATE_IN_PROGRESS ||
      this.device.instanceStatus === DeviceInstanceStatus.CREATE_REQUESTED ||
      !this.device.instanceConnectionStatus
    );
  }

  get isBeingDeleted() {
    return (
      this.device.instanceStatus === DeviceInstanceStatus.DELETE_IN_PROGRESS
    );
  }

  get hasUpdateFailed() {
    return hasDeviceFwUpdateFailed(this.device);
  }

  ngOnInit(): void {
    getDeviceTypeTooltipKey(this.device);
    this.envToolTip = this.device.connectedEnvironments.join('/');
    this.routingStore.state$
      .pipe(
        take(1),
        map((state) => state.applicationId)
      )
      .subscribe((appName) => (this.appName = appName));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentDevice = changes['device'].currentValue;
    const previousDevice = changes['device'].previousValue;
    if (currentDevice !== previousDevice) {
      this.deviceTypeTooltipSignal.set(getDeviceTypeTooltipKey(this.device));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.startDeploymentStore.setState({
      devices: [],
      versions: [],
      applications: [],
      areApplicationsLoading: true,
      areDevicesLoading: true,
      areVersionsLoading: true,
      hasError: false,
    });
  }
}
