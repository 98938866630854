<h2 mat-dialog-title>{{ "HDK.StartNewDeployment" | translate }}</h2>
<ng-container *ngIf="startDeploymentData$ | async as startDeploymentData">
  <app-content-wrapper
    [isError]="startDeploymentData.hasError"
    [isLoading]="startDeploymentData.isLoading"
    [errorMessage]="'DeviceList.DeviceListError' | translate"
  >
    <div
      data-cy="start-deployment-dialog"
      mat-dialog-content
      class="content"
      [formGroup]="startDeploymentFormGroup"
    >
      <mat-form-field *ngIf="!data.appName" class="caedge-form-field-outline">
        <mat-label>{{
          "Application.AddApplicationDialog.ApplicationName" | translate
        }}</mat-label>
        <mat-select formControlName="selectedApp">
          <mat-option
            *ngFor="let application of startDeploymentData.applications"
            [value]="application.appName"
            >{{ application.appName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "Deployments.AddNewDeploymentDialog.VersionId" | translate
        }}</mat-label>
        <mat-select
          data-cy="select-version-deployment-dialog"
          formControlName="selectedVersion"
        >
          <mat-option
            *ngIf="predefinedVersion; else versionOptions"
            [value]="predefinedVersion"
            >{{ predefinedVersion }}</mat-option
          >
          <ng-template #versionOptions>
            <mat-option
              *ngFor="let version of startDeploymentData.versions"
              [value]="version.versionId"
              >{{ version.versionId }}</mat-option
            >
          </ng-template>
        </mat-select>
        <mat-hint
          align="start"
          *ngIf="
            startDeploymentData.versions.length === 0 && !predefinedVersion
          "
        >
          {{ "HDK.NoVersionsWithSucceededPipelines" | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "DeviceList.FilterOptions.Environment" | translate
        }}</mat-label>
        <mat-select
          data-cy="select-environment-deployment-dialog"
          formControlName="selectedEnv"
        >
          <mat-option value="Development">{{
            "DeviceList.FilterKeys.Environment.Development" | translate
          }}</mat-option>
          <mat-option value="QA">{{
            "DeviceList.FilterKeys.Environment.QA" | translate
          }}</mat-option>
          <!-- Production will be included when there is production API available-->
          <!-- <mat-option value="Production">Production</mat-option> -->
        </mat-select>
      </mat-form-field>
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "Deployments.AddNewDeploymentDialog.Device" | translate
        }}</mat-label>
        <mat-select
          data-cy="select-device-deployment-dialog"
          formControlName="selectedDevice"
        >
          <mat-option
            *ngIf="predefinedDevice; else deviceOptions"
            [value]="predefinedDevice"
            >{{ predefinedDevice }}</mat-option
          >
          <ng-template #deviceOptions>
            <mat-option
              *ngFor="let device of startDeploymentData.filteredDevices"
              [value]="device.hwName"
              >{{ device.hwName }}</mat-option
            >
          </ng-template>
        </mat-select>
        <mat-hint
          align="start"
          *ngIf="
            startDeploymentData.filteredDevices.length === 0 &&
            !predefinedDevice
          "
        >
          {{
            ("HDK.NoConnectedDevices" | translate) + (selectedEnv | translate)
          }}
        </mat-hint>
      </mat-form-field>
    </div>
    <div mat-dialog-actions [align]="'end'">
      <button
        data-cy="cancel-deployment-button"
        [attr.aria-label]="'General.Cancel' | translate"
        mat-flat-button
        [mat-dialog-close]="false"
      >
        {{ "General.Cancel" | translate }}
      </button>
      <button
        class="confirm-btn"
        data-cy="start-deployment-button"
        [attr.aria-label]="'HDK.StartDeployment' | translate"
        mat-flat-button
        [mat-dialog-close]="true"
        (click)="startDeploymentClick()"
        [disabled]="!canSubmit"
      >
        {{ "HDK.StartDeployment" | translate }}
      </button>
    </div>
  </app-content-wrapper>
</ng-container>
