import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { ContentWrapperComponent } from '../../../../shared/components/content-wrapper/content-wrapper.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { SimulationJobMonitoringService } from '../../services/simulation-job-monitoring.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ChipComponent, ChipVariant } from '../../../../shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';
import { SYSDAQChildJobStep } from '../../models/childJobStep';
import { SYSDAQChildJob } from '../../models/jobDetails';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { stepDownloadRequestPayload } from '../../models/stepDownloadReuest';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { FileService } from '../../../../core/services/file/file.service';
import { take } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface ChildJobStepData {
  runId: string;
  jobIndex: string;
  apiRecord: ApiRecord | undefined;
  requestStepDownload: ApiRecord | undefined;
}

@Component({
  selector: 'app-child-job-steps',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    ContentWrapperComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    ChipComponent,
    MatIconModule,
    MatTableModule,
    MatProgressBarModule,
    MatSortModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './child-job-steps.component.html',
  styleUrls: ['./child-job-steps.component.scss']
})
export class ChildJobStepsComponent implements OnDestroy {
  protected readonly ChipVariant = ChipVariant;
  unsubscribe$: Subject<void> = new Subject<void>();
  loading = false;
  isRequestLoading = false;

  steps: any | null = null;
  dataSource = new MatTableDataSource<SYSDAQChildJobStep>([]);
  pageSizeOptions = [5, 10];
  pageSize = 5;
  childJobIndex = '0';

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  displayedColumns: string[] = ['index', 'step', 'exitCode', 'silFactor', 'runTimeRatio', 'slimExitCode', 'export'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChildJobStepData,
    private simulationJobMonitoringService: SimulationJobMonitoringService,
    private snackbarService: SnackbarService,
    private fileService: FileService
  ) {
    this.fetchSteps();
  }

  private fetchSteps() {
    if (!this.data.apiRecord) return;
    this.loading = true;

    this.simulationJobMonitoringService
      .getChildJobSteps(this.data.apiRecord, this.data.runId, this.data.jobIndex)
      .pipe(take(1))
      .subscribe({
        next: (steps) => {
          this.childJobIndex = steps.ChildJobIndex;
          this.steps = this.getChildJobStepsInformation(steps);
          this.dataSource.data = this.steps;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          console.log(error);
        }
      });
  }

  private getChildJobStepsInformation(steps: SYSDAQChildJob): SYSDAQChildJobStep[] {
    const childJobSteps: SYSDAQChildJobStep[] = [];
    steps.ChildJobData.StepNames.forEach((step) => {
      childJobSteps.push({
        Step: step,
        ...steps.ChildJobData[step]
      });
    });
    return childJobSteps;
  }

  handleExportClick(step: SYSDAQChildJobStep) {
    if (!this.data.requestStepDownload) return;
    this.isRequestLoading = true;

    const payload: stepDownloadRequestPayload = {
      run_id: this.data.runId,
      child_index: this.childJobIndex,
      step: step.Step
    };

    this.simulationJobMonitoringService
      .requestStepDownloadLink(this.data.requestStepDownload, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response.message) {
            this.snackbarService.notifyError(response.message);
          } else if (response.url) {
            this.fileService.downloadFile(response.url, 'StepLog');
            this.snackbarService.notifyInfo('Log downloaded');
          }
          this.isRequestLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isRequestLoading = false;
          this.snackbarService.notifyError(error);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
