import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { Option } from 'src/app/core/models/interfaces/option';
import { LegalEntity } from '../../models/legalEntity';
import { Observable, take } from 'rxjs';
import { Contact } from '../../models/contact';
import { ProjectContactsService } from '../services/project-contacts.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

/**
 * `StepComponent` implementation for the billing information step of the project creation process.
 *
 * @export
 * @class BillingInformationComponent
 */
@Component({
  selector: 'app-billing-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss']
})
export class BillingInformationComponent implements OnInit, StepComponent {
  billingInfoForm: FormGroup;

  controllingContact$: Observable<string[]>;
  billingContacts$: Observable<Contact[]>;
  costCenterOwners$: Observable<string[]>;

  legalEntities: Option<string>[] = [
    {
      id: LegalEntity.DEFAULT,
      label: LegalEntity.DEFAULT
    },
    {
      id: LegalEntity.CONTI_AUTO_TECH,
      label: LegalEntity.CONTI_AUTO_TECH
    },
    {
      id: LegalEntity.SCHWALBACH,
      label: LegalEntity.SCHWALBACH
    }
  ];

  private allBillingContacts: Contact[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private contactsService: ProjectContactsService
  ) {
    this.billingInfoForm = this.fb.group({
      controllingContact: ['', [Validators.email, Validators.required]],
      costCenter: ['', [Validators.required]],
      billingContact: ['', [Validators.required]],
      costCenterOwner: ['', [Validators.email, Validators.required]],
      legalEntity: ['', [Validators.required]],
      wbsElement: ['']
    });

    this.billingContacts$ = contactsService.getBillingContacts();
    this.controllingContact$ = contactsService.getControllingContacts();
    this.costCenterOwners$ = contactsService.getCostCenterOwnerContacts();
  }

  get controllingContactControl() {
    return this.billingInfoForm.get('controllingContact') as FormControl;
  }

  get costCenterControl() {
    return this.billingInfoForm.get('costCenter') as FormControl;
  }

  get billingContactControl() {
    return this.billingInfoForm.get('billingContact') as FormControl;
  }

  get costCenterOwnerControl() {
    return this.billingInfoForm.get('costCenterOwner') as FormControl;
  }

  get legalEntityControl() {
    return this.billingInfoForm.get('legalEntity') as FormControl;
  }

  get wbsElementControl() {
    return this.billingInfoForm.get('wbsElement') as FormControl;
  }

  get dataToUpdate() {
    const formValue = this.billingInfoForm.getRawValue();
    let billingContact: Contact = {
      ContiUid: '-',
      Email: formValue.billingContact,
      Name: '-',
      Phone: '-'
    };

    const filteredContact = this.allBillingContacts.filter((contact) => contact.Email === billingContact.Email);

    billingContact = filteredContact.length === 0 ? billingContact : filteredContact[0];

    return {
      Billing: {
        LegalEntity: formValue.legalEntity,
        ControllingContact: formValue.controllingContact,
        WBSElement: formValue.wbsElement,
        OrgMapping: {
          CostCenter: formValue.costCenter,
          CostCenterOwner: formValue.costCenterOwner
        }
      },
      Contacts: {
        Billing: billingContact
      }
    };
  }

  get stepValid(): boolean {
    return this.billingInfoForm.valid;
  }

  ngOnInit(): void {
    this.fetchAllBillingContacts();
  }

  private fetchAllBillingContacts() {
    this.billingContacts$.pipe(take(1)).subscribe((contacts) => {
      this.allBillingContacts = contacts;
    });
  }

  /**
   * Checks if the given FormControl is invalid and returns the corresponding error message.
   *
   * @param formControl the FormControl to validate
   * @return the corresponding error message or an empty string in case there is no error
   */
  getErrorMessage(formControl: FormControl | null): string {
    if (formControl) {
      if (formControl.hasError('required')) {
        return this.translate.instant('Application.AddApplicationDialog.ErrorMessage.Required');
      }
      if (formControl.hasError('email')) {
        return this.translate.instant('Project.AddProjectDialog.ErrorMessage.Email');
      }
    }
    return '';
  }
}
