<div class="function-bar">
  <div class="search-bar">
    <mat-icon
      (click)="clearSearch()"
      data-cy="clear-search-icon"
      aria-label="search and clear"
      [matTooltip]="checkSearchKeywords() ? ('General.Search' | translate) : ('General.ClearSearch' | translate)"
    >
      {{ icon }}
    </mat-icon>
    <input
      data-cy="search-input"
      type="text"
      [maxlength]="maxInputLength"
      [formControl]="searchFilterControl"
      [placeholder]="searchPlaceholder | translate"
    />
  </div>
  <div class="actions">
    <ng-content select="[filters]"></ng-content>
    <div class="reset-filters-container" *ngIf="showFiltersBtn">
      <button
        mat-icon-button
        color="accent"
        class="clear-filters-btn"
        aria-label="Clear filters"
        (click)="resetActiveFilters()"
        [matTooltip]="'Reset Filters'"
      >
        <mat-icon class="btn-icon">filter_alt_off</mat-icon>
      </button>
    </div>
    <ng-content select="[others]"></ng-content>
  </div>
</div>
