<ng-container *ngIf="entries">
  <ng-container *ngFor="let item of entries">
    <div class="fields">
      <span class="key">{{ translationKey + "." + item.key | translate }}</span>
      <span class="value">{{
        !item.key.toLowerCase().includes("date")
          ? item.value
          : (item.value | customFormatDate)
      }}</span>
    </div>
  </ng-container>
</ng-container>
