<div class="header">
  <div class="title-col" *ngIf="breadcrumbs$ | async as breadcrumbs">
    <mat-icon
      *ngIf="showBackButton"
      [matTooltip]="'Back'"
      (click)="goBack(breadcrumbs[breadcrumbs.length - 2].url)"
    >
      arrow_back
    </mat-icon>
    <p
      data-cy="header-text"
      class="header-text"
      *ngIf="translationKey; else name"
    >
      {{ translationKey | translate }}
    </p>
    <ng-template #name>
      <p data-cy="header-text" class="header-text"
         [ngClass]="{'aligned-header': itemName === 'Versions and deployments'}" *ngIf="itemName">
        {{ itemName }}
      </p>
    </ng-template>
  </div>
  <div class="actions-col">
    <ng-content></ng-content>
  </div>
</div>
