<form class="form" [formGroup]="contactInfoForm">
  <div class="content" mat-dialog-content>
    <table class="contact-info-table">
      <tr>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>manage_accounts</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.ProjectOwner" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.ProjectOwner' | translate
              }}"
              matInput
              formControlName="projectOwner"
              [matAutocomplete]="projectOwnerAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #projectOwnerAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of ownerContacts$ | async"
                [value]="option.Email"
              >
                {{ option.Email }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="projectOwnerControl?.invalid">
              {{ getErrorMessage(projectOwnerControl) }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>admin_panel_settings</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.SecurityCentralOPS" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.SecurityCentralOPS' | translate
              }}"
              formControlName="securityCentral"
              matInput
              [matAutocomplete]="securityCentralAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #securityCentralAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of securityCentralContacts$ | async"
                [value]="option.Email"
              >
                {{ option.Email }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="securityCentralControl?.invalid">
              {{ getErrorMessage(securityCentralControl) }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>manage_accounts</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.ProjectCoOwner" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'Project.AddProjectDialog.ProjectCoOwner' | translate
              }}"
              formControlName="projectCoOwner"
              multiple
            >
              <mat-select-trigger>
                {{ "Project.AddProjectDialog.ProjectCoOwner" | translate }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let option of coOwnerContacts$ | async"
                [value]="option.Name"
                >{{ option.Email }}</mat-option
              >
            </mat-select>

            <mat-error *ngIf="projectCoOwnerControl?.invalid">
              {{ getErrorMessage(projectCoOwnerControl) }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>admin_panel_settings</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.SecurityProjectOwner" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.SecurityProjectOwner' | translate
              }}"
              formControlName="securityProjectOwner"
              matInput
              [matAutocomplete]="securityOwnerAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #securityOwnerAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of securityProjectOwnerContacts$ | async"
                [value]="option.Email"
              >
                {{ option.Email }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="securityProjectOwnerControl?.invalid">
              {{ getErrorMessage(securityProjectOwnerControl) }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="contact-info coowner-column" rowspan="2">
          <div class="coowner-container">
            <mat-chip-row
              class="contact-chip"
              *ngFor="let contact of projectCoOwnerControl.value"
              (removed)="removeCoOwner(contact)"
              [editable]="false"
            >
              {{ contact }}
              <button matChipRemove [attr.aria-label]="'remove ' + contact">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </div>
        </td>
        <td class="contact-info technical-column">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>engineering</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.TechnicalContact" | translate
            }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.TechnicalContact' | translate
              }}"
              formControlName="technicalContact"
              matInput
              [matAutocomplete]="technicalAuto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #technicalAuto="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of technicalContacts$ | async"
                [value]="option.Email"
              >
                {{ option.Email }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="technicalContactControl?.invalid">
              {{ getErrorMessage(technicalContactControl) }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</form>
