import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { FileService } from 'src/app/core/services/file/file.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DeviceListApiService } from 'src/app/features/device-list/services/device-list-api/device-list-api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Device } from 'src/app/shared/stores/devices/models/device/device';

export interface ChangeEnvDialogData {
  isNewlyCreated: boolean;
  device: Device;
  getConnectionPackageApiConfigMap: Record<HdkEnvironment, ApiRecord>;
  unsubscribe$: Subject<void>;
}

@Component({
  standalone: true,
  selector: 'app-change-env-dialog',
  templateUrl: './change-env-dialog.component.html',
  styleUrls: ['./change-env-dialog.component.scss'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class ChangeEnvDialogComponent {
  selectedEnvFormControl: FormControl<HdkEnvironment> = new FormControl(
    HdkEnvironment.DEVELOPMENT,
    { nonNullable: true }
  );
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangeEnvDialogData,
    private deviceListApiService: DeviceListApiService,
    private snackbarService: SnackbarService,
    private fileService: FileService
  ) {}

  get title() {
    return this.data.isNewlyCreated
      ? 'DeviceList.ChangeEnvDialog.RegisterYourNewDevice'
      : 'DeviceList.ChangeEnvironment';
  }

  downloadZipClick() {
    this.snackbarService.notifyInfo('Downloading...');
    this.deviceListApiService
      .getConnectionPackage(
        this.data.device.hwName,
        this.data.getConnectionPackageApiConfigMap[
          this.selectedEnvFormControl.value
        ]
      )
      .pipe(takeUntil(this.data.unsubscribe$))
      .subscribe({
        next: (data) => {
          this.fileService.downloadBlob(
            this.fileService.b64toBlob(data.body, data.headers['Content-Type']),
            data.headers['Content-Disposition'].split('filename=')[1]
          );
          this.snackbarService.notifySuccess('Download successful');
        },
        error: () => {
          this.snackbarService.notifyError('Download failed');
        },
      });
  }
}
